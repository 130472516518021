import {
  LinkedAccountOwnershipStatus,
  LinkedAccountOwnershipValidationError,
  LinkedAccountOwnershipValidationStatus,
  LinkedAccountOwnershipValidationType,
  LinkedAccountType,
  Maybe,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'

export const isAccountValidForPrimary = (
  accountType: LinkedAccountType,
  achNumbersAvailable: boolean,
): boolean => {
  return accountType === LinkedAccountType.Checking && achNumbersAvailable === true
}

export const hasValidOwnershipStatus = (account?: BankAccountInfo): boolean => {
  return (
    account?.ownershipSummary?.ownershipStatus === LinkedAccountOwnershipStatus.Valid ||
    account?.ownershipSummary?.ownershipStatus === LinkedAccountOwnershipStatus.Pending
  )
}

export const typeOfValidationError = (
  errors: Maybe<Pick<LinkedAccountOwnershipValidationError, 'validationType'>[]> | undefined,
  type: LinkedAccountOwnershipValidationType,
): boolean => {
  if (!errors) return false
  return errors.some((item) => item.validationType === type)
}

export const accountsNeedsReview = (
  errors: Maybe<Pick<LinkedAccountOwnershipValidationError, 'validationStatus'>[]> | undefined,
): boolean => {
  if (!errors) return false
  return errors.some(
    (item) => item.validationStatus === LinkedAccountOwnershipValidationStatus.Review,
  )
}

export const accountHasError = (account: BankAccountInfo): boolean => {
  if (account.wasAddedWithoutAggregator) {
    // if an account was added as an additional payment method without an aggregator like plaid we ignore ownership status
    return false
  }
  return (
    account.isRelinkRequired ||
    !account.isRoutingAndAccountNumbersAvailable ||
    !hasValidOwnershipStatus(account)
  )
}
