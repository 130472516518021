/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {FC} from 'react'
import {AsYouTypeFormatter, PhoneNumberUtil} from 'google-libphonenumber'
import {useTranslation} from 'react-i18next'

import Log from 'src/lib/loggingUtil'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFTextInput, {PFTextInputProps} from 'src/designSystem/components/atoms/PFTextInput/index'
import FlagUSA from 'src/assets/icons/flagUSA.svg'
import {darkGrayText} from 'src/designSystem/semanticColors'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import Box from 'src/designSystem/components/atoms/Box/Box'

export type PFPhoneNumberInputProps = Omit<PFTextInputProps, 'label'> & {
  label?: string
  value: string
  hideCountryFlag?: boolean
  onGetFormattedPhoneNumber: (phoneNumberText: string) => void
  onPhoneNumberIsValid: (phoneIsvalid: boolean) => void
  icon?: SvgIconProps
  isEditable?: boolean
}

const phoneUtil = PhoneNumberUtil.getInstance()
const formatter = new AsYouTypeFormatter('US')
const flagProps = {height: 21, width: 28}

const PFPhoneNumberInput: FC<PFPhoneNumberInputProps> = (props) => {
  const {t} = useTranslation('PhoneNumberInput')
  const {
    onGetFormattedPhoneNumber,
    onPhoneNumberIsValid,
    label,
    hideCountryFlag,
    icon,
    isEditable,
  } = props

  const handleOnFormatPhoneNumber = (text): void => {
    formatter.clear()
    let formattedNewEntry = ''
    let isPhoneValid = false

    ;[...text].forEach((c) => {
      if (Number.isInteger(parseInt(c))) {
        formattedNewEntry = formatter.inputDigit(c)
      }
    })

    try {
      const phoneNumberParsed = phoneUtil.parse(formattedNewEntry, 'US')
      isPhoneValid = phoneUtil.isValidNumber(phoneNumberParsed)
    } catch (e) {
      Log.log(`Error parsing phone number ${formattedNewEntry}`)
    }

    onGetFormattedPhoneNumber(formattedNewEntry)
    onPhoneNumberIsValid(isPhoneValid)
  }

  return (
    <Box direction="row" align="center">
      {hideCountryFlag ? null : (
        <Box direction="row" paddingHorizontal={4} testID={'Country_Code_Group'}>
          <Box paddingRight={6}>
            <PFSvg id={'us-flag'} SvgUri={FlagUSA} svgProps={flagProps} />
          </Box>
          <PFText color={darkGrayText} variant={'p_semibold'}>
            {t('PhoneCodeUS')}
          </PFText>
        </Box>
      )}
      <Box flex={1} testID={'Phone-Number-Input-Field'}>
        <PFTextInput
          {...props}
          label={label ?? t('PhoneNumber')}
          maxLength={14}
          returnKeyType="done"
          keyboardType="phone-pad"
          onChangeText={handleOnFormatPhoneNumber}
          textContentType="telephoneNumber"
          icon={icon}
          editable={isEditable}
          editableStyle={isEditable}
          testID={'Phone-Number-Input'}
        />
      </Box>
    </Box>
  )
}

export {PFPhoneNumberInput}
