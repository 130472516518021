import React, {FC} from 'react'

import {CardAccountOfferAvailabilityStatus} from 'src/cassandra'
import {CardWaitListTile, CardWaitListTileProps} from 'src/products/card/LoanDash/CardWaitListTile'
import {ProductsQueryAllIneligibleOffersType} from 'src/workflows/types'

type Props = Omit<CardWaitListTileProps, 'variant'> & {
  ineligibleOffers: ProductsQueryAllIneligibleOffersType
}
export const CardWaitListTileGQLContainer: FC<Props> = ({ineligibleOffers, ...props}) => {
  const isQuotaReached = (ineligibleOffers || []).some(
    (offer) =>
      offer.__typename === 'CardAccountOfferInfo' &&
      offer?.cardOfferAvailabilityStatus === CardAccountOfferAvailabilityStatus.QuotaReached,
  )
  return <CardWaitListTile {...props} variant={isQuotaReached ? 'quota' : 'location'} />
}
