import {createStackNavigator, StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {wfLog} from 'src/workflows/logging'
import {
  GetCurrentRouteName,
  GetSignupWorkflowNavigationOptions,
  OffersMinPreReqsNotMetSelector,
  useSetupRouteHistory,
} from 'src/workflows/navigation'
import {
  SignupWorkflowStackParams,
  WorkflowsErrorType,
  WorkflowsLoadingType,
  WorkflowsPreReqFullfilledFunction,
  WorkflowsReadyType,
  WorkflowsStackParams,
} from 'src/workflows/types'
import {GetNextRouteFromPreReqs, GetSortedPreReqs} from 'src/workflows/utils'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'

import {AddressWorkflowContainer} from 'src/products/MCU/Address/AddressWorkflowContainer'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {PhoneNumberInputVerificationContainer} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationContainer'
import {ApplyInOneClickContainer} from 'src/products/general/ApplyInOneClick/ApplyInOneClickContainer'
import {LoanAmountSelectionWorkflowContainer} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionWorkflowContainer'
import {LoanAmountSelectionV2Container} from 'src/products/loans/LoanAmountSelectionV2/LoanAmountSelectionV2Container'
import {useWorkflowsSort} from 'src/workflows/experimentation'
import {SignupWorkflowSortSelector} from 'src/products/general/SignupWorkflow/SignupWorkflowSorting'
import {NotificationsPermissionsContainer} from 'src/products/general/NotificationsPermissions/NotificationsPermissionsContainer'
import {VerifyYourIdentityWorkflowContainer} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityWorkflowContainer'
import {ApplyInFewStepsContainer} from 'src/products/general/ApplyInFewSteps/ApplyInFewStepsContainer'
import {useMoveToNextPreReqRoute} from 'src/workflows/hooks/useMoveToNextPreReqRoute'
import {PlaidLayersProvider} from 'src/products/MCU/PlaidLayers/PlaidLayersContext'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import PhoneConfirmationContainer from 'src/products/loans/PhoneConfirmation/PhoneConfirmationWorkflowContainer'
import PhoneVerificationContainer from 'src/products/loans/PhoneConfirmation/PhoneVerificationWorkflowContainer'

export const SignUpStackNav = createStackNavigator<SignupWorkflowStackParams>()

const SignupLoading: React.FC<
  StackScreenProps<WorkflowsStackParams, 'Loading'> & {
    onNavigationLoaded: (navigation: StackNavigationProp<WorkflowsStackParams>) => void
  }
> = (props) => {
  const {navigation, onNavigationLoaded} = props

  useEffect(() => {
    onNavigationLoaded(navigation)
  }, [navigation, onNavigationLoaded])

  return (
    <Box fill justify="center" align="center">
      <Loading size="large" type="loader0" />
    </Box>
  )
}

const SignupWorkflowReady: React.FC<
  StackScreenProps<MainStackParamList, 'SignupWorkflow'> & WorkflowsReadyType
> = (props) => {
  const {unmetMinPreReqs, metMinPreReqs, refetch, navigation} = props

  const isCardUser = useCanAccessCards().canAccessCards

  const hasLoanAmountSelectionV2 = useIsFeatureFlagEnabled('loan-amount-selection-v2')

  const [signUpNavigation, setSignUpNavigation] =
    useState<StackNavigationProp<WorkflowsStackParams>>()

  wfLog('Met min pre-reqs: ', metMinPreReqs)
  useSetupRouteHistory({
    navigation: signUpNavigation,
    unmetPreReqs: unmetMinPreReqs,
    metPreReqs: metMinPreReqs,
  })

  useWorkflowsSort({
    selectedOffer: null,
    unmetPreReqs: unmetMinPreReqs,
    preReqSortSelector: SignupWorkflowSortSelector,
    passthrough: {
      isLoanOnboarding: !isCardUser,
    },
  })

  const moveToNextPreReqRoute = useMoveToNextPreReqRoute({
    met: metMinPreReqs,
    unmet: unmetMinPreReqs,
  })

  const currentRouteName = useMemo(
    () => GetNextRouteFromPreReqs(unmetMinPreReqs),
    [unmetMinPreReqs],
  )
  wfLog('Current route name: ', currentRouteName)

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.signup_workflow_entered, AppEvents.Category.Workflows, {
      route: currentRouteName,
    })
    return () => {
      TrackAppEvent(AppEvents.Name.signup_workflow_exited, AppEvents.Category.Workflows)
    }
    // we don't want to track this on every render; just on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnPreReqFulfilled = useCallback(async () => {
    await moveToNextPreReqRoute({
      navigation,
      refetch,
      preReqSelector: OffersMinPreReqsNotMetSelector,
    })
  }, [moveToNextPreReqRoute, navigation, refetch])

  wfLog(`\nNavigation State: ${JSON.stringify(navigation.getState())}`)

  const handleNavigationLoaded = useCallback(
    (signUpNav: StackNavigationProp<WorkflowsStackParams>) => {
      setSignUpNavigation(signUpNav)
    },
    [],
  )

  const handlePhoneFulfilled: WorkflowsPreReqFullfilledFunction<'PhoneVerification'> = useCallback(
    async (params) => {
      signUpNavigation?.navigate('PhoneVerification', params)
      return Promise.resolve()
    },
    [signUpNavigation],
  )

  return (
    <PlaidLayersProvider onPreReqFulfilled={handleOnPreReqFulfilled} navigation={signUpNavigation}>
      <SignUpStackNav.Navigator
        initialRouteName="Loading"
        screenOptions={{
          gestureEnabled: true,
          headerTransparent: true,
          headerShadowVisible: false,
        }}
      >
        <SignUpStackNav.Screen name="Loading" options={GetSignupWorkflowNavigationOptions}>
          {(screenProps): React.ReactNode => (
            <SignupLoading {...screenProps} onNavigationLoaded={handleNavigationLoaded} />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen
          name="LoanAmountSelection"
          options={GetSignupWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => {
            if (hasLoanAmountSelectionV2) {
              return (
                <LoanAmountSelectionV2Container
                  {...screenProps}
                  onPreReqFulfilled={handleOnPreReqFulfilled}
                />
              )
            }
            return (
              <LoanAmountSelectionWorkflowContainer
                {...screenProps}
                onPreReqFulfilled={handleOnPreReqFulfilled}
              />
            )
          }}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen
          name="PhoneConfirmation"
          options={GetSignupWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode =>
            !isCardUser ? (
              <PhoneNumberInputVerificationContainer
                {...screenProps}
                onPreReqFulfilled={handleOnPreReqFulfilled}
              />
            ) : (
              <PhoneConfirmationContainer
                {...screenProps}
                onShouldMoveToVerification={handlePhoneFulfilled}
                onPreReqFulfilled={handleOnPreReqFulfilled}
              />
            )
          }
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen
          name="PhoneVerification"
          options={GetSignupWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <PhoneVerificationContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen
          name="PersonalInformation"
          options={GetSignupWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <VerifyYourIdentityWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen name="AddressHome" options={GetSignupWorkflowNavigationOptions}>
          {(screenProps): React.ReactNode => (
            <AddressWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen name="ApplyInOneClick" options={GetSignupWorkflowNavigationOptions}>
          {(screenProps): React.ReactNode => (
            <ApplyInOneClickContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen
          name="NotificationsPermissions"
          options={GetSignupWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <NotificationsPermissionsContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
        <SignUpStackNav.Screen name="ApplyInFewSteps" options={GetSignupWorkflowNavigationOptions}>
          {(screenProps): React.ReactNode => (
            <ApplyInFewStepsContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </SignUpStackNav.Screen>
      </SignUpStackNav.Navigator>
      <ReadyDebug {...props} />
    </PlaidLayersProvider>
  )
}

const SignupWorkflowLoading: React.FC<WorkflowsLoadingType> = () => {
  return (
    <Box fill align="center" justify="center">
      <Loading size="large" type="loader0" />
    </Box>
  )
}

const SignupWorkflowError: React.FC<WorkflowsErrorType> = (props) => {
  const {error, refetch: handleRefetch} = props

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.signup_workflow_error, AppEvents.Category.Workflows, {
      error: error.message,
    })
  }, [error.message])

  return <ErrorTemplate onRetry={handleRefetch} />
}

const SignupWorkflow: React.FC<StackScreenProps<MainStackParamList, 'SignupWorkflow'>> = (
  props,
) => {
  const workflows = useWorkflows()

  switch (workflows.status) {
    case 'READY':
      return <SignupWorkflowReady {...workflows} {...props} />
    case 'LOADING':
      return <SignupWorkflowLoading {...workflows} />
    case 'ERROR':
      return <SignupWorkflowError {...workflows} />
    default:
      return (
        <Box fill>
          <Loading type="loader0" size="large" />
        </Box>
      )
  }
}

export {SignupWorkflow}

const ReadyDebug: React.FC<
  StackScreenProps<MainStackParamList, 'SignupWorkflow'> & WorkflowsReadyType
> = (props) => {
  const {unmetMinPreReqs, navigation} = props

  const [show, setShow] = useState(__DEV__)

  const nextRoute = useMemo(() => GetNextRouteFromPreReqs(unmetMinPreReqs), [unmetMinPreReqs])
  const sortedPreReqs = useMemo(() => GetSortedPreReqs(unmetMinPreReqs), [unmetMinPreReqs])

  const navState = navigation.getState()

  const details = useMemo(() => {
    if (!show) {
      return null
    }

    return (
      <>
        {sortedPreReqs.length > 0 ? (
          <PFText variant="p_sm" color="inverse">
            Current PreReq: {sortedPreReqs[0]}
          </PFText>
        ) : null}
        <PFText variant="p_sm" color="inverse">
          Current Route: {nextRoute}
        </PFText>
        <PFText variant="p_sm" color="inverse">
          Visible Route: {GetCurrentRouteName(navState)}
        </PFText>
      </>
    )
  }, [navState, nextRoute, show, sortedPreReqs])

  if (!__DEV__) {
    return null
  }

  return (
    <Box
      align="center"
      justify="center"
      gap="little"
      // disabling this warning as this debug component is only used in dev
      // and will be removed in the future
      // eslint-disable-next-line react-native/no-inline-styles
      boxStyle={{position: 'absolute', opacity: show ? 0.8 : 0.3, top: 40, right: 10}}
      background="black"
      radius="small"
      padding="little"
    >
      <PFText variant="label_sm" color="inverse">
        Signup Workflow
      </PFText>
      {details}
      <Button mode="progressive" size="small" onPress={(): void => setShow((prev) => !prev)}>
        {show ? 'Hide' : 'Show More'}
      </Button>
    </Box>
  )
}
