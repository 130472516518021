import {PFDropdownOptionProps} from 'src/designSystem/components/atoms/PFDropdown/PFDropdown'

export const state_abv_to_full = new Map([
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AS', 'American Samoa'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'District Of Columbia'],
  ['FM', 'Federated States Of Micronesia'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['GU', 'Guam'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MH', 'Marshall Islands'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['MP', 'Northern Mariana Islands'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PW', 'Palau'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VI', 'Virgin Islands'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
])

export const state_full_to_abv = new Map([
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Federated States Of Micronesia', 'FM'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'MP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Palau', 'PW'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virgin Islands', 'VI'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
])

export const regionCodeToAbv = new Map([
  ['USA-AL', 'AL'],
  ['USA-AK', 'AK'],
  ['USA-AZ', 'AZ'],
  ['USA-AR', 'AR'],
  ['USA-CA', 'CA'],
  ['USA-CO', 'CO'],
  ['USA-CT', 'CT'],
  ['USA-DE', 'DE'],
  ['USA-FL', 'FL'],
  ['USA-GA', 'GA'],
  ['USA-HI', 'HI'],
  ['USA-ID', 'ID'],
  ['USA-IL', 'IL'],
  ['USA-IN', 'IN'],
  ['USA-IA', 'IA'],
  ['USA-KS', 'KS'],
  ['USA-KY', 'KY'],
  ['USA-LA', 'LA'],
  ['USA-ME', 'ME'],
  ['USA-MD', 'MD'],
  ['USA-MA', 'MA'],
  ['USA-MI', 'MI'],
  ['USA-MN', 'MN'],
  ['USA-MO', 'MO'],
  ['USA-MS', 'MS'],
  ['USA-MT', 'MT'],
  ['USA-NE', 'NE'],
  ['USA-NV', 'NV'],
  ['USA-NH', 'NH'],
  ['USA-NJ', 'NJ'],
  ['USA-NM', 'NM'],
  ['USA-NY', 'NY'],
  ['USA-NC', 'NC'],
  ['USA-ND', 'ND'],
  ['USA-OH', 'OH'],
  ['USA-OK', 'OK'],
  ['USA-OR', 'OR'],
  ['USA-PA', 'PA'],
  ['USA-RI', 'RI'],
  ['USA-SC', 'SC'],
  ['USA-SD', 'SD'],
  ['USA-TN', 'TN'],
  ['USA-TX', 'TX'],
  ['USA-UT', 'UT'],
  ['USA-VT', 'VT'],
  ['USA-VA', 'VA'],
  ['USA-WA', 'WA'],
  ['USA-WV', 'WV'],
  ['USA-WI', 'WI'],
  ['USA-WY', 'WY'],
])

export const states_list = Array.from(state_full_to_abv.keys())
export const states_abbr_list = Array.from(state_abv_to_full.keys())

const AllowedStatesAbv = new Set([
  'AL',
  'CA',
  'DE',
  'FL',
  'IA',
  'ID',
  'IN',
  'KS',
  'KY',
  'LA',
  'MI',
  'MO',
  'MS',
  'NV',
  'OH',
  'OK',
  'OR',
  'RI',
  'SC',
  'TN',
  'TX',
  'UT',
  'WA',
])

export const CoastalStatesAbv = new Set([
  'AL',
  'DE',
  'FL',
  'IA',
  'IN',
  'KS',
  'KY',
  'MI',
  'MO',
  'MS',
  'OK',
  'RI',
  'SC',
  'TN',
  'TX',
])

export function GetFullStateFromAbv(stateAbv) {
  if (!stateAbv) {
    return ''
  }
  let stateFull = state_abv_to_full.get(stateAbv)
  if (!stateFull) {
    stateFull = ''
  }
  return stateFull
}

export const allowedFullStatesList = (abEnabledStates: string[]) => {
  const allowed = allowedStates(abEnabledStates)
  let list = ''
  for (const abv of allowed) {
    const fullState = GetFullStateFromAbv(abv)
    if (fullState !== '') {
      if (list !== '') {
        list += ', '
      }
      list += fullState
    }
  }
  return list
}

export const allowedStates = (abEnabledStates: string[] | undefined): string[] => {
  const supportedStates = Array.from(AllowedStatesAbv)
  if (abEnabledStates) {
    supportedStates.push(...abEnabledStates)
  }
  return supportedStates
}

export const getStatesAbvAsDropdownOptions = (): PFDropdownOptionProps[] => {
  const options: PFDropdownOptionProps[] = []
  states_abbr_list.forEach((state) => {
    options.push({label: state, value: state})
  })
  return options
}

export const getStatesFullAsDropdownOptions = (): PFDropdownOptionProps[] => {
  const options: PFDropdownOptionProps[] = []
  state_full_to_abv.forEach((abv, state) => {
    options.push({label: state, value: abv})
  })
  return options
}
