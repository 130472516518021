import {Platform} from 'react-native'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  LoginMethodPropPayload,
  LoginMethodType,
  LoginMethodValues,
} from 'src/api/lib/UserProperties/LoginMethodUserProperty.types'
import {UserPropertiesQuery} from 'src/api/lib/UserProperties/UserProperties.gqls'
import {WorkflowsUserMiscPropPayload} from 'src/workflows/types'
import {Consumer, UserPropertyKey} from 'src/cassandra'
import {GraphQLFormattedError} from 'graphql'
import {ApolloError} from '@apollo/client'

/**
 * Add user property payload types here. This is what will be JSON
 * serialized and stored in the user properties table on the backend
 */

export type CardApplicationGroupMiscPropPayload = {
  possibleCard: boolean
  cohortPhase?: Consumer.types.CohortPhase
  cohortUserType?: string
  hasDisplayedCardRejectedTile?: boolean
  hasDisplayedCardExpiredTile?: boolean
}

export type MarketingChannelSourceMiscPropPayload = {
  source: string
  idx?: number
  version: string
}

export type OnlineBankMiscPropPayload = {
  enabled: boolean
}

export const ReviewValues = ['positive', 'negative'] as const
export type ReviewType = (typeof ReviewValues)[number]

export type RatedMiscPropPaylod = {
  review: ReviewType
  os: typeof Platform.OS
  instanceId: string | undefined
}

export type PurposeForLoanPropPayload = {
  value: string
  other?: string
}

export type CardWaitListMiscPropPayload = {
  registered_for_wait_list: boolean
}

/**
 * This describes our user properties and their expected value types
 * The key should be the user property name and the value should be
 * the expected value/payload type
 * A type guard type will be generated from the value type
 */
type PropertyNameToValueMap = UserPropertyDefinitionsType<{
  /**
   *  Used to store state related to card application group -- possible card, cohort phase, etc
   */
  cardApplicationGroup: CardApplicationGroupMiscPropPayload

  /**
   * Used to store state related to workflows -- selected offer, front end pre-reqs, etc
   */
  workflowState: WorkflowsUserMiscPropPayload

  /**
   * Used to record the user's response to the marketing survey "How did you hear about us"
   */
  mkt_chl_src: MarketingChannelSourceMiscPropPayload

  /**
   * Records users latest login method
   */
  login_method: LoginMethodPropPayload

  /**
   * Used to determine whether or not online banks -- like Chime, Varo, etc -- are enabled for the user
   */
  onlineBank: OnlineBankMiscPropPayload

  /**
   * App Store/Google play store rating
   */
  rated: RatedMiscPropPaylod

  /**
   * Reason survey - Purpose for loan
   */
  purpose_for_loan: PurposeForLoanPropPayload
  [UserPropertyKey.PurposeForLoan]: PurposeForLoanPropPayload

  /**
   * Card wait list
   */
  card_wait_list: CardWaitListMiscPropPayload
}>

/**
 * Typeguards for the user property values
 * One should be created for every user property type
 */
export const UserPropertyValueTypeGuards: ValueTypeGuards<UserPropertyTypes> = {
  cardApplicationGroup: (value): value is CardApplicationGroupMiscPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'possibleCard' in value &&
      typeof value?.possibleCard === 'boolean'
    )
  },
  workflowState: (value): value is WorkflowsUserMiscPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'metOneTimeFrontEndPreReqs' in value &&
      Array.isArray(value?.metOneTimeFrontEndPreReqs)
    )
  },
  mkt_chl_src: (value): value is MarketingChannelSourceMiscPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'source' in value &&
      typeof value.source === 'string' &&
      'version' in value &&
      typeof value.version === 'string'
    )
  },
  login_method: (value): value is LoginMethodPropPayload => {
    // The type casting below silences the tsc but will perform the validation we want during runtime
    const isLegacyType =
      value !== null &&
      typeof value === 'object' &&
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      LoginMethodValues.includes(Object.keys(value)[0] as LoginMethodType)
    const isNewType =
      value !== null &&
      typeof value === 'object' &&
      'method' in value &&
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      LoginMethodValues.includes(value.method as LoginMethodType)
    // Object is the legacy type we're trying to phase out
    return isLegacyType || isNewType
  },
  onlineBank: (value): value is OnlineBankMiscPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'enabled' in value &&
      typeof value.enabled === 'boolean'
    )
  },
  rated: (value): value is RatedMiscPropPaylod => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'review' in value &&
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      ReviewValues.includes(value.review as ReviewType) &&
      'os' in value &&
      typeof value.os === 'string' &&
      'instanceId' in value &&
      typeof value.instanceId === 'string'
    )
  },
  purpose_for_loan: (value): value is PurposeForLoanPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'value' in value &&
      typeof value.value === 'string'
    )
  },
  [UserPropertyKey.PurposeForLoan]: (value): value is PurposeForLoanPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'value' in value &&
      typeof value.value === 'string'
    )
  },
  card_wait_list: (value): value is CardWaitListMiscPropPayload => {
    return (
      value !== null &&
      typeof value === 'object' &&
      'registered_for_wait_list' in value &&
      typeof value?.registered_for_wait_list === 'boolean'
    )
  },
}

/**
 * Everything below is pretty static and should not need to be modified
 */
export type UserProperties = keyof PropertyNameToValueMap
export type UserPropertyTypes = Unionize<PropertyNameToValueMap>

type UserPropertyDefinitionsType<D extends Record<string | UserPropertyKey, UserPropertyPayload>> =
  D
type Unionize<T extends PropertyNameToValueMap> = {
  [k in keyof T]: {property: k; value: T[k]}
}[keyof T]

type ValueTypeGuards<T extends UserPropertyTypes> = {
  [K in T['property']]: (value: unknown) => value is T['value']
}

/**
 * A utility type to infer the user property type from the property name
 */
export type InferredUserMiscProperty<T extends UserPropertyTypes['property']> = {
  property: T
} & UserPropertyTypes

// These describe the expected shape of a user property value/payload
// The payload is a JSON object that can be stored in the user properties table
type UserPropertyPayloadArray = Array<UserPropertyPayloadValue>
type UserPropertyPayloadValue =
  | boolean
  | number
  | string
  | UserPropertyPayloadArray
  | UserPropertyPayload
  | null
  | undefined
type UserPropertyPayload = {
  [key in string | number]: UserPropertyPayloadValue
}

export type GetUserPropertyResult<
  P extends UserProperties,
  V = InferredUserMiscProperty<P>['value'],
> = {
  property: P
  value?: V
  error?: ApolloError | GraphQLFormattedError
}

export type UseUserPropertyResult<
  P extends UserProperties,
  V = InferredUserMiscProperty<P>['value'],
> = {
  value: V | undefined
  isLoading: boolean
  setValue: (value: V) => Promise<void>
  refetch: ReturnType<typeof useCassandraQuery<UserPropertiesQuery>>['refetch']
  error?: Error
}
