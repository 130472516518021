import {useFocusEffect} from '@react-navigation/native'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ImageStyle, StyleProp, ViewStyle} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ImageCapture} from 'src/lib/utils/formData'
import Image from 'src/products/general/Image/Image'
import {
  CameraAnalyticEvents,
  ImageOrientation,
} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import {CalculateCameraImageStyle} from 'src/products/general/components/organisms/CameraPage/CameraPage.utils'

type CameraPageConfirmProps = {
  image: ImageCapture
  orientation: ImageOrientation
  submitButtonText?: string
  onConfirm: () => Promise<void>
  onRetake: () => void
  title: string
  message: string | React.ReactElement
  frameSize: {width: number; height: number}
  parentBusy: boolean
  viewedEvent: CameraAnalyticEvents['confirmPhotoViewedEvent']
}

const CameraPageConfirm: React.FC<CameraPageConfirmProps> = (props) => {
  const {t} = useTranslation(['CameraPage', 'Common'])

  const {
    title,
    message,
    image,
    orientation,
    frameSize,
    submitButtonText,
    onConfirm,
    onRetake,
    parentBusy: isParentBusy,
    viewedEvent,
  } = props

  const viewedEventName = viewedEvent?.name
  const viewedEventCategory = viewedEvent?.category
  useFocusEffect(
    useCallback(() => {
      if (viewedEventName && viewedEventCategory) {
        TrackAppEvent(viewedEventName, viewedEventCategory)
      }
    }, [viewedEventCategory, viewedEventName]),
  )

  const renderedImage = useMemo(() => {
    const style: StyleProp<ViewStyle> = {
      ...CalculateCameraImageStyle(frameSize, orientation),
      ...frameSize,
    }

    const uri = image.dataUri ?? image.uri

    return <Image resizeMode="cover" source={{uri}} style={style as ImageStyle} />
  }, [frameSize, image.dataUri, image.uri, orientation])

  return (
    <Page
      variant="generic"
      smallTopGap
      title={title}
      description={typeof message === 'string' ? message : undefined}
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: submitButtonText ?? t('GenericSubmitButton'),
          onPress: onConfirm,
          disabled: isParentBusy,
          loading: isParentBusy,
          testID: 'CameraPageConfirm',
        },
        secondary: {
          buttonText: t('RetakePhoto'),
          onPress: onRetake,
        },
      }}
    >
      <Box flex>
        {typeof message !== 'string' && message}

        <Box flex paddingHorizontal="small">
          <Box align="center" justify="center" flex>
            {renderedImage}
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default CameraPageConfirm
