import {ViewStyle} from 'react-native'

import {isDeviceAndroidWeb} from 'src/lib/utils/platform'
import {CameraAndFrameStyle} from 'src/products/general/components/organisms/CameraPage/CameraPage.consts'
import {ImageOrientation} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'

export const CalculateCameraImageStyle = (
  size: {width: number; height: number},
  orientation: ImageOrientation,
): ViewStyle => {
  const {width, height} = size

  let style: ViewStyle
  if (isDeviceAndroidWeb()) {
    //The react-camera setup we're using for RNCamera in web requires special styles for android
    style = {
      ...CameraAndFrameStyle.square,
      height,
      width,
    }
  } else {
    if (orientation === 'portrait') {
      style = {...CameraAndFrameStyle.portrait, height}
    } else if (orientation === 'landscape') {
      style = {...CameraAndFrameStyle.landscape, width}
    } else {
      style = {
        ...CameraAndFrameStyle.square,
        width,
      }
    }
  }

  return style ?? {}
}
