import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanChargedOffSettledTileProps = {
  reapplyOnDate: Date
  onApplyForNewLoan: ButtonAction
  isBusy?: boolean
  testID?: string
}
/**
 * A tile to inform the user that their charged off loan has been settled.
 * Includes an action button to apply for a new loan.
 */
const LoanChargedOffSettledTile: React.FC<LoanChargedOffSettledTileProps> = ({
  reapplyOnDate,
  onApplyForNewLoan,
  isBusy,
  testID,
}) => {
  const {t} = useTranslation('DashboardLoanChargedOffSettledTile')
  const isAfterReapplyOn = new Date() >= reapplyOnDate
  return (
    <BasicTile align="start" testID={testID}>
      <Box paddingHorizontal="large" paddingVertical="medium">
        <PFText variant="h3" textAlign="left">
          {t('ThankYouForSettling')}
        </PFText>
        <Box marginTop="medium">
          <PFText variant="p">
            {t('SettledMinimizeImpact')}
            {'\n\n'}
            {isAfterReapplyOn ? t('ApplyAgainNow') : t('ApplyAgainSoon')}
          </PFText>
        </Box>
      </Box>
      {isAfterReapplyOn ? (
        <Box width="100%" paddingHorizontal="medium" paddingBottom="medium" align="center">
          <Button
            mode="primary"
            size="medium"
            width="100%"
            onPress={onApplyForNewLoan}
            testID="LoanChargedOffSettledTile-ApplyForNewLoanBtn"
            disabled={isBusy}
            loading={isBusy}
          >
            {t('ApplyForNewLoan')}
          </Button>
        </Box>
      ) : null}
    </BasicTile>
  )
}

export default LoanChargedOffSettledTile
