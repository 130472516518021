import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase, CardTileBaseProps} from 'src/products/card/LoanDash/CardTileBase'

type Props = Pick<CardTileBaseProps, 'loading'> & {
  emailAddress: string
}

export const CardApplicationRejected = ({loading, emailAddress}: Props): ReactNode => {
  const {t} = useTranslation('CardApplicationRejected')
  return (
    <CardTileBase
      titleText={t('CardRejectedTitle')}
      content={t('CardRejectedBody', {emailAddress})}
      loading={loading}
      tileMarginHorizontal={0}
      testID="CardApplicationRejectedBase"
    />
  )
}
