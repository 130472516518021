import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Pressable, StyleSheet} from 'react-native'
import {useForm} from 'react-hook-form'

import HookForm, {
  DefaultFormGapSizeVariant,
  FIELD_VARIANTS,
  formFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {gapSizeMap} from 'src/designSystem/layout'
import {getHasErrorsOrMissingValues} from 'src/lib/utils/formValidationUtil'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {
  formatCurrency,
  sanitizeCurrencyString,
} from 'src/products/card/Application/CardApplicationFinances/CardApplicationFinances.util'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {BottomSheetOrOverlay} from 'src/designSystem/components/organisms/BottomSheetOrOverlay/BottomSheetOrOverlay'

export type CardApplicationFinancesTemplateProps = {
  onSubmitSelfReportedData: (data: CardApplicationFinanceFormData) => Promise<void>
  isLoading: boolean
}

export type CardApplicationFinanceFormData = {
  annualGrossIncome: string
  monthlyHousingCost: string
}

export const CardApplicationFinancesTemplate: FC<CardApplicationFinancesTemplateProps> = ({
  onSubmitSelfReportedData,
  isLoading,
}) => {
  const [showIncomeInfo, setShowIncomeInfo] = React.useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {control, handleSubmit, errors, watch} = useForm<CardApplicationFinanceFormData>({
    mode: 'all',
    defaultValues: {annualGrossIncome: '', monthlyHousingCost: ''},
  })
  const {t} = useTranslation('CardApplicationFinancesTemplate')

  const formProps: {[x: string]: formFieldProps} = {
    annualGrossIncome: {
      name: 'annualGrossIncome',
      field: FIELD_VARIANTS.TEXT_FIELD,
      rules: {
        required: t('annualGrossIncomeErrorRequired'),
        validate: (value: string) => {
          const numericValue = parseFloat(sanitizeCurrencyString(value))
          if (isNaN(numericValue)) {
            return t('annualGrossIncomeErrorInvalidNumber')
          }
          if (numericValue < 0 || numericValue > 999999) {
            return t('annualGrossIncomeErrorOutOfRange')
          }
          if (value.includes('.')) {
            return t('annualGrossIncomeErrorNoDecimals')
          }
          return true
        },
      },
      viewStyle: styles.fullRow,
    },
    monthlyHousingCost: {
      name: 'monthlyHousingCost',
      field: FIELD_VARIANTS.TEXT_FIELD,
      viewStyle: styles.fullRow,
      rules: {
        required: t('monthlyHousingCostErrorRequired'),
        validate: (value: string) => {
          const numericValue = parseFloat(sanitizeCurrencyString(value))
          if (isNaN(numericValue)) {
            return t('monthlyHousingCostErrorInvalidNumber')
          }
          if (numericValue < 0 || numericValue > 9999) {
            return t('monthlyHousingCostErrorOutOfRange')
          }
          if (value.includes('.')) {
            return t('monthlyHousingCostErrorNoDecimals')
          }
          return true
        },
      },
    },
  }
  const isDisabled = getHasErrorsOrMissingValues(errors, watch, [
    'annualGrossIncome',
    'monthlyHousingCost',
  ])

  return (
    <Page
      smallTopGap
      title={t('title')}
      variant="generic"
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Next'),
          onPress: handleSubmit(onSubmitSelfReportedData),
          disabled: isDisabled || isLoading,
          loading: isLoading,
          testID: 'Application-Finances-Continue-Button',
        },
      }}
    >
      <Box paddingTop={'large'}>
        <HookForm control={control} errors={errors}>
          <Box>
            <Box paddingVertical={'tiny'}>
              <Box direction="row" align="center" gap="little">
                <PFText variant="p_semibold">{t('annualGrossIncomeLabel')}</PFText>
                <Pressable
                  onPress={() => setShowIncomeInfo(true)}
                  testID="Annual-Gross-Income-Info-Icon"
                >
                  <SvgIcon name="info" colorVariant="info" size="small" />
                </Pressable>
              </Box>

              <PFText variant="p" color={NamedColors.SILVER}>
                {t('annualGrossIncomeDescription')}
              </PFText>
            </Box>
            <Box paddingTop={'tiny'}>
              <PFTextInput
                keyboardType="numeric"
                label={t('annualGrossIncome')}
                changeFilter={(text: string): string => formatCurrency(text)}
                formProps={formProps.annualGrossIncome}
                returnKeyType={'next'}
                testID="Annual-Gross-Input-Id"
              />
            </Box>
          </Box>
          <Box paddingVertical={'small'}>
            <Box paddingVertical={'tiny'}>
              <PFText variant="p_semibold">{t('monthlyHousingCostLabel')}</PFText>
            </Box>
            <PFTextInput
              keyboardType="numeric"
              label={t('monthlyHousingCost')}
              formProps={formProps.monthlyHousingCost}
              returnKeyType={'next'}
              testID="Monthly-Housing-Input-Id"
              changeFilter={(text: string): string => formatCurrency(text)}
            />
          </Box>
        </HookForm>
      </Box>
      <BottomSheetOrOverlay
        visible={showIncomeInfo}
        onDismiss={() => setShowIncomeInfo(false)}
        title={t('WhatIsAnnualGrossIncome')}
        titleAlign="left"
        titleVariant="h3"
        showDots={false}
        showCloseButton
        onOkay={() => setShowIncomeInfo(false)}
        okayButtonSize="large"
        okayButtonText={t('Done')}
      >
        <PFText variant="p_lg">{t('WhatIsAnnualGrossIncomeBody1')}</PFText>
        <PFText variant="p_lg">{t('WhatIsAnnualGrossIncomeBody2')}</PFText>
      </BottomSheetOrOverlay>
    </Page>
  )
}

const styles = StyleSheet.create({
  fullRow: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    width: '100%',
  },
})
