import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

export type GetCardCohortPhaseQueryVariables = Types.Exact<{
  channel: Types.ApplicationChannel
}>

export type GetCardCohortPhaseQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    products: {
      __typename?: 'ProductsCollection'
      properties?: {
        __typename?: 'ProductApplicationProperties'
        cardCohortPhase: Types.CohortPhase
      } | null
    }
  }
}

export const GetCardCohortPhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetCardCohortPhase'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'channel'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ApplicationChannel'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'properties'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'cardCohortPhase'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'channel'},
                                  value: {kind: 'Variable', name: {kind: 'Name', value: 'channel'}},
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCardCohortPhaseQuery, GetCardCohortPhaseQueryVariables>
