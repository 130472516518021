import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardActivatedGQLContainer} from 'src/products/card/Activation/CardActivated/CardActivatedGQLContainer/CardActivatedGQLContainer'
import {WorkflowScreenProps} from 'src/workflows/types'

type Props = WorkflowScreenProps &
  Pick<StackScreenProps<MainStackParamList, 'ApplicationActivationWorkflow'>, 'navigation'>

export const CardActivatedWorkflowContainer: FC<Props> = ({
  onScreenComplete: handleOnScreenComplete,
  navigation,
}) => {
  navigation.setOptions({
    headerShown: false,
  })
  return <CardActivatedGQLContainer onComplete={handleOnScreenComplete} />
}
