import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import {PhoneNumberVerificationMethod} from '@possible/generated/APIClient'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {ampli} from 'src/lib/Analytics/ampli'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {TrackAppEvent, VerifyPhoneEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {isValidGraphQLError} from 'src/lib/errors/errors.types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PhoneNumberInputVerificationTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationTemplate'
import {PhoneNumberAlreadyVerifiedTemplate} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberVerifiedTemplate'
import {logPlaidLayerError} from 'src/products/MCU/PlaidLayers/PlaidLayers.utils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {
  PhoneNumberVerifiedDocument,
  ValidatePhoneNumberDocument,
} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerification.gqls'
import {GetDisablePlaidLayer} from 'src/lib/LaunchArgs/DisablePlaidLayer'
import {usePlaidLayersContext} from 'src/products/MCU/PlaidLayers/PlaidLayersContext'
import {PhoneNumberValidationResponseCode} from '@possible/cassandra/src/types/types.mobile.generated'

type PhoneNumberInputContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'PhoneConfirmation'>

const PhoneNumberInputVerificationContainer: FC<PhoneNumberInputContainerProps> = (props) => {
  const {navigation, route} = props
  const hasOptedOutOfPlaidLayer = route.params?.optedOutOfPlaidLayer ?? false

  const {t} = useTranslation(['PhoneConfirmation', 'PhoneNumberEntry', 'Common'])
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false)
  const [isDuplicatePhoneNumber, setIsDuplicatePhoneNumber] = useState(false)
  const [shouldShowVerificationCode, setShouldShowVerificationCode] = useState<boolean>(false)
  const [hasPhoneVerified, setHasPhoneVerified] = useState<boolean | undefined>(false)
  /* 
  we use this state to check if the layer session has been created, 
  if the session is not created the user will keep moving with regular onboarding
  */
  const [hasLayerSessionCreated, setHasLayerSessionCreated] = useState(false)

  const [checkIsPhoneVerified] = useCassandraLazyQuery(PhoneNumberVerifiedDocument, {
    fetchPolicy: 'network-only',
  })

  const [validatePhoneNumber, {loading: isValidatingPhoneNumber}] = useCassandraLazyQuery(
    ValidatePhoneNumberDocument,
    {
      fetchPolicy: 'network-only',
    },
    (data) => data?.validatePhoneNumber?.phoneNumberValidationResponseCode,
  )

  const checkIfPhoneIsVerified = useCallback(async (): Promise<void> => {
    const response = await checkIsPhoneVerified()

    if (response) {
      setHasPhoneVerified(response?.data?.me?.profile?.phone?.verified)
    }
  }, [checkIsPhoneVerified])

  useFocusEffect(
    useCallback(() => {
      void checkIfPhoneIsVerified()
    }, [checkIfPhoneIsVerified]),
  )

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_phone_number_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  const [sendVerificationCode] = Consumer.hooks.usePhoneVerificationCodeSendMutation()
  const [validateCode] = Consumer.hooks.usePhoneVerificationCodeValidationMutation()

  const {isLoadingPlaidData, createLayerSession, checkIfEligibleForPlaidLayers} =
    usePlaidLayersContext()

  useEffect(() => {
    if (hasOptedOutOfPlaidLayer) {
      sendAnalyticEvent(ampli.plaidLayerOptOut.bind(ampli))
    }
  }, [hasOptedOutOfPlaidLayer])

  useEffect(() => {
    const shouldDisablePlaidLayer = GetDisablePlaidLayer()

    if (shouldDisablePlaidLayer) {
      return
    }

    createLayerSession()
      .then(() => setHasLayerSessionCreated(true))
      .catch((e) => {
        logPlaidLayerError(e, 'Failed to create Plaid Layer session')
      })
  }, [createLayerSession])

  const handleOptBackIntoPlaidLayers = async (): Promise<void> => {
    sendAnalyticEvent(ampli.plaidLayerOptBackIn.bind(ampli))

    createLayerSession()
      .then(() => {
        setHasLayerSessionCreated(true)
        navigation.setParams({optedOutOfPlaidLayer: false})
      })
      .catch((e) => {
        logPlaidLayerError(e, 'Failed to create Plaid Layer session')
      })
  }

  const handleVerifiedSuccessfully = useCallback((): void => {
    navigation.navigate('ApplyInFewSteps')
  }, [navigation])

  const handleOnCellPhoneNumberReady = async (phoneNumberFormatted: string): Promise<void> => {
    TrackAppEvent(AppEvents.Name.enter_phone_number_completed, AppEvents.Category.Onboarding)

    /* this is a server error used to check the call status, it's not displayed to the user
     *  most likely this will always be in English*/
    const duplicateNumberError = 'Phone number already exists'

    try {
      const phoneValidationStatus = (
        await validatePhoneNumber({variables: {phoneNumber: phoneNumberFormatted}})
      ).selectedData

      if (phoneValidationStatus === PhoneNumberValidationResponseCode.InUse) {
        setIsDuplicatePhoneNumber(true)
        return
      }
      /*
      we verify here if the layer session has been created first before we can check eligibility api,
      if there is any issue creating the layer session the user will move forward with regular onboarding process
      */
      if (hasLayerSessionCreated) {
        if (!hasOptedOutOfPlaidLayer) {
          const isUserEligibleForOneClickOnboarding =
            await checkIfEligibleForPlaidLayers(phoneNumberFormatted)

          if (isUserEligibleForOneClickOnboarding) {
            navigation.navigate('ApplyInOneClick')
            return
          }
        }
      }

      const response = await sendVerificationCode({
        variables: {authorizationCodeInput: {phoneNumber: phoneNumberFormatted, method: 'SMS'}},
      })
      if (response.errors) {
        throw response.errors[0]
      }
      setShouldShowVerificationCode(true)
      TrackAppEvent(
        AppEvents.Name.enter_verification_code_screen_viewed,
        AppEvents.Category.Onboarding,
      )
    } catch (e) {
      if (isValidGraphQLError(e) && e.message === duplicateNumberError) {
        setIsDuplicatePhoneNumber(true)
      } else {
        ShowException(e)
      }
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleOnSubmitVerificationCode = async (
    code: string,
    phoneNumber: string,
  ): Promise<void> => {
    try {
      TrackAppEvent(AppEvents.Name.enter_verification_code_submitted, AppEvents.Category.Onboarding)

      setIsSendingVerificationCode(true)

      const res = await validateCode({
        variables: {
          phoneNumberVerificationInput: {
            phoneNumber,
            code,
          },
        },
      })
      if (res.errors) {
        throw res.errors[0]
      }

      VerifyPhoneEvent()

      handleVerifiedSuccessfully()
    } catch (e) {
      ShowException(e)
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  const handleOnEditPhoneNumber = (): void => {
    navigation.setParams({optedOutOfPlaidLayer: false})
    setShouldShowVerificationCode(false)
  }

  const handleMoveToNextStep = async (): Promise<void> => {
    setIsSendingVerificationCode(true)
    handleVerifiedSuccessfully()
    setIsSendingVerificationCode(false)
  }

  const handleClearDuplicatePhoneNumber = (): void => {
    setIsDuplicatePhoneNumber(false)
  }

  const handleResendCode = async (
    method: PhoneNumberVerificationMethod,
    phoneNumber: string,
  ): Promise<void> => {
    TrackAppEvent(
      method === 'SMS'
        ? AppEvents.Name.enter_verification_code_resend_selected
        : AppEvents.Name.enter_verification_code_phone_call_selected,
      AppEvents.Category.Checkout,
    )

    try {
      setIsSendingVerificationCode(true)
      const response = await sendVerificationCode({
        variables: {
          authorizationCodeInput: {
            phoneNumber,
            method,
          },
        },
      })

      if (response.errors) {
        throw response.errors[0]
      }
    } catch (e) {
      ShowException(e)
    } finally {
      setIsSendingVerificationCode(false)
    }
  }

  if (hasPhoneVerified) {
    return (
      <PhoneNumberAlreadyVerifiedTemplate
        onPhoneAlreadyVerified={handleMoveToNextStep}
        isActionDisabled={isSendingVerificationCode}
      />
    )
  }

  /*
    there are a couple of scenarios here that we want to keep the button disabled:
    isSendingVerificationCode = general busy for this screen
    isPlaidLayerActive = true if the layer session has been created and the user has not opted out
    isPlaidLayerLoadingOrIsMissingLinkSessionId = if isPlaidLayerActive is true we need to check for isLoadingPlaidData and need to wait for the linkSessionId
  */
  const isPlaidLayerActive = hasLayerSessionCreated && !hasOptedOutOfPlaidLayer

  const isActionDisabled =
    isSendingVerificationCode ||
    (isPlaidLayerActive ? isLoadingPlaidData : false) ||
    isValidatingPhoneNumber

  return (
    <PhoneNumberInputVerificationTemplate
      testID={'PhoneConfirmationPage'}
      onButtonPress={handleOnCellPhoneNumberReady}
      mainTitle={t('PhoneNumberEntry:EnterYourPhoneNumber')}
      mainBody={t('CellPhoneWillBeUsedForAccountVerification')}
      isDuplicateNumber={isDuplicatePhoneNumber}
      onClearDuplicatePhoneNumber={handleClearDuplicatePhoneNumber}
      isActionDisabled={isActionDisabled}
      shouldShowVerificationCode={shouldShowVerificationCode}
      onSubmitVerificationCode={handleOnSubmitVerificationCode}
      onResendMyCodeAction={handleResendCode}
      onEditPhoneNumber={handleOnEditPhoneNumber}
      navigation={navigation}
      route={route}
      hasOptedOutOfPlaidLayer={hasOptedOutOfPlaidLayer}
      onOptBackIntoPlaidLayers={handleOptBackIntoPlaidLayers}
    />
  )
}

export {PhoneNumberInputVerificationContainer}
