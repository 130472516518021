import {LinkedAccountType} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  isAccountValidForPrimary,
  hasValidOwnershipStatus,
} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'

/**
 * Determine if the edit account button should be enabled or not for a bank account.
 */
export const isEditBankAccountButtonEnabled = ({
  account,
  hasActiveLoan,
}: {
  account: BankAccountInfo
  hasActiveLoan: boolean
}): boolean => {
  return (
    account.type === LinkedAccountType.Checking &&
    (isSetAccountAsPrimaryOptionEnabled({account}) ||
      isUpdateBankDetailsOptionEnabled({account}) ||
      isSetAccountAsAutoPayOptionEnabled({account, hasActiveLoan}))
  )
}

/**
 * Determine if the "Set Account As Primary" button is enabled for a bank account.
 */
export const isSetAccountAsPrimaryOptionEnabled = ({
  account,
}: {
  account: BankAccountInfo
}): boolean => {
  return (
    !account.primary &&
    isAccountValidForPrimary(account.type, !!account.isRoutingAndAccountNumbersAvailable)
  )
}

/**
 * Determine if the "Update Bank Details" button is enabled for a bank account.
 */
export const isUpdateBankDetailsOptionEnabled = ({
  account,
}: {
  account: BankAccountInfo
}): boolean => {
  return !account.isRoutingAndAccountNumbersAvailable
}

/**
 * Determine if the "Relink Account" button is enabled for a bank account.
 */
export const isRelinkAccountOptionEnabled = ({account}: {account: BankAccountInfo}): boolean =>
  !!(account.isRelinkRequired || !hasValidOwnershipStatus(account))

/**
 * Determine if the "Set Account As AutoPay" button is enabled for a bank account.
 */
export const isSetAccountAsAutoPayOptionEnabled = ({
  account,
  hasActiveLoan,
}: {
  account: BankAccountInfo
  hasActiveLoan: boolean
}): boolean => {
  return !!(
    // if they don't have an active loan to update the autopay method then this option is not available
    (
      hasActiveLoan &&
      // account must be usable
      account.isRoutingAndAccountNumbersAvailable &&
      // accounts linked with an aggregator (plaid) can be set for autopay
      !account.wasAddedWithoutAggregator &&
      // if its already the loan autopay method we don't show the option
      !account.isDefaultLoanPaymentMethod &&
      // if an account is not primary you can set it as autopay
      (!account.primary ||
        // if account is primary and NOT currently your autopay method you can set it back to autopay
        (account.primary && !account.isDefaultLoanPaymentMethod))
    )
  )
}
