import React, {FC, useCallback, useState} from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ImageCapture} from 'src/lib/utils/formData'
import {
  CameraAnalyticEvents,
  ImageOrientation,
  ImageOrientations,
  PhotoTakenArgs,
  PhotoTakenCallback,
} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import CameraPageCapture from 'src/products/general/components/organisms/CameraPage/CameraPageCapture'
import CameraPageConfirm from 'src/products/general/components/organisms/CameraPage/CameraPageConfirm'

enum CameraPageCapturePhase {
  TakePhoto,
  ConfirmPhoto,
}

export type OnCameraCompleteCallback = (
  image: ImageCapture,
  orientation: ImageOrientation,
) => Promise<void>

export type CameraPageProps = {
  onComplete: OnCameraCompleteCallback
  orientations: ImageOrientations
  position: 'front' | 'back'

  captureTitle: string
  captureMessage: string | React.ReactElement
  turnCameraButtonText?: Partial<{[key in ImageOrientation]: string}>

  confirmTitle: string
  confirmMessage: string | React.ReactElement
  submitButtonText?: string

  events?: CameraAnalyticEvents
}

const CameraPage: FC<CameraPageProps> = (props) => {
  const {
    onComplete,
    orientations = ['portrait'],
    position = 'back',
    captureTitle,
    captureMessage,
    turnCameraButtonText,
    confirmTitle,
    confirmMessage,
    submitButtonText,
    events = {},
  } = props

  const [imageArgs, setImageArgs] = useState<PhotoTakenArgs>()
  const [capturePhase, setCapturePhase] = useState<CameraPageCapturePhase>(
    CameraPageCapturePhase.TakePhoto,
  )

  const [isBusy, setBusy] = useState(false)

  const handlePhotoTaken: PhotoTakenCallback = (args): void => {
    setImageArgs(args)
    setCapturePhase(CameraPageCapturePhase.ConfirmPhoto)

    if (events.photoTakenEvent) {
      TrackAppEvent(events.photoTakenEvent.name, events.photoTakenEvent.category)
    }
  }

  const handleRetakePhoto = (): void => {
    setImageArgs(undefined)
    setCapturePhase(CameraPageCapturePhase.TakePhoto)

    if (events.photoRetakeEvent) {
      TrackAppEvent(events.photoRetakeEvent.name, events.photoRetakeEvent.category)
    }
  }

  const handlePhotoSubmission = async (): Promise<void> => {
    if (imageArgs) {
      setBusy(true)
      await onComplete(imageArgs.image, imageArgs.orientation)
      setBusy(false)

      if (events.photoSubmittedEvent) {
        TrackAppEvent(events.photoSubmittedEvent.name, events.photoSubmittedEvent.category)
      }
    }
  }

  const handleOrientationChanged = useCallback((): void => {
    if (events.orientationChangedEvent) {
      TrackAppEvent(events.orientationChangedEvent.name, events.orientationChangedEvent.category)
    }
  }, [events.orientationChangedEvent])

  if (capturePhase === CameraPageCapturePhase.ConfirmPhoto && imageArgs) {
    return (
      <CameraPageConfirm
        frameSize={imageArgs.frame}
        image={imageArgs.image}
        orientation={imageArgs.orientation}
        onConfirm={handlePhotoSubmission}
        onRetake={handleRetakePhoto}
        title={confirmTitle}
        message={confirmMessage}
        parentBusy={isBusy}
        submitButtonText={submitButtonText}
        viewedEvent={events.confirmPhotoViewedEvent}
      />
    )
  }

  return (
    <CameraPageCapture
      orientations={orientations}
      onOrientationChange={handleOrientationChanged}
      position={position}
      onPhotoTaken={handlePhotoTaken}
      title={captureTitle}
      message={captureMessage}
      turnCameraButtonText={turnCameraButtonText}
      viewedEvent={events.takePhotoViewedEvent}
    />
  )
}

export default CameraPage
