import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type PhoneNumberVerifiedQueryVariables = Types.Exact<{[key: string]: never}>

export type PhoneNumberVerifiedQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    profile?: {
      __typename: 'UserProfile'
      phone?: {__typename: 'Phone'; verified: boolean} | null
    } | null
  }
}

export type ValidatePhoneNumberQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input']
}>

export type ValidatePhoneNumberQuery = {
  __typename?: 'Query'
  validatePhoneNumber: {
    __typename?: 'PhoneNumberValidationResponse'
    phoneNumberValidationResponseCode: Types.PhoneNumberValidationResponseCode
  }
}

export const PhoneNumberVerifiedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'PhoneNumberVerified'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'phone'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhoneNumberVerifiedQuery, PhoneNumberVerifiedQueryVariables>
export const ValidatePhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ValidatePhoneNumber'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'phoneNumber'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'validatePhoneNumber'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'phoneNumber'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'phoneNumber'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'phoneNumberValidationResponseCode'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>
