import React, {FC, ReactNode, useCallback} from 'react'
import {View, TouchableOpacity, StyleSheet} from 'react-native'

import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Checked from 'src/assets/icons/checked.svg'
import CheckedDisabled from 'src/assets/icons/checkedDisabled.svg'
import Unchecked from 'src/assets/icons/unchecked.svg'
import UncheckedDisabled from 'src/assets/icons/uncheckedDisabled.svg'
import {TextVariants} from 'src/designSystem/typography'

export type PFCheckboxProps = React.PropsWithChildren & {
  id?: string
  checked?: boolean
  disabled?: boolean
  size?: PFCheckboxSizes
  onPress?: (newValue: boolean) => void
  testID?: string
}

export type PFCheckboxSizes = 'Small' | 'Medium' // Small should only be used for legal checkboxes

const checkboxSizesMap: {[key in PFCheckboxSizes]: number} = {
  Medium: 24,
  Small: 24,
}

const textVariantMap: {[key in PFCheckboxSizes]: TextVariants} = {
  Medium: 'p',
  Small: 'p_sm',
}

const checkedTextVariantMap: Record<PFCheckboxSizes, TextVariants> = {
  ...textVariantMap,
  Medium: 'p_semibold',
}

const getCheckbox = (
  id: string | undefined,
  checked: boolean,
  disabled: boolean,
  size: number,
): ReactNode => {
  const checkboxProps = {
    height: size,
    width: size,
  }

  if (!disabled) {
    return checked ? (
      <PFSvg id={id} SvgUri={Checked} svgProps={checkboxProps} testID="pf_checkbox-checked" />
    ) : (
      <PFSvg id={id} SvgUri={Unchecked} svgProps={checkboxProps} testID="pf_checkbox-unchecked" />
    )
  }
  return checked ? (
    <PFSvg id={id} SvgUri={CheckedDisabled} svgProps={checkboxProps} />
  ) : (
    <PFSvg id={id} SvgUri={UncheckedDisabled} svgProps={checkboxProps} />
  )
}

const PFCheckbox: FC<PFCheckboxProps> = ({
  id,
  checked = false,
  disabled = false,
  size = 'Medium',
  onPress,
  children,
  testID,
}) => {
  const sizeValue = checkboxSizesMap[size]
  const variantValue = checked ? checkedTextVariantMap[size] : textVariantMap[size]

  const onPressCb = useCallback(() => onPress?.(!checked), [checked, onPress])
  const textColor = disabled ? 'disabled' : 'textPrimary'

  return (
    <View style={styles.containerStyle}>
      <TouchableOpacity
        disabled={disabled}
        style={{height: sizeValue}}
        onPress={onPressCb}
        testID={testID}
      >
        {getCheckbox(id, checked, disabled, sizeValue)}
      </TouchableOpacity>
      {children ? (
        <View style={styles.textContainerStyle}>
          <PFText color={textColor} variant={variantValue}>
            {children}
          </PFText>
        </View>
      ) : null}
    </View>
  )
}

export default PFCheckbox

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
  },
  textContainerStyle: {
    flexShrink: 1,
    paddingLeft: 8,
  },
})
