import {
  ClosedLoanAggregateStatus,
  LoanClosedReasonCode,
} from '@possible/cassandra/src/types/consumer'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {isLoanClosedReasonCodePaidOff} from 'src/lib/loans/loansUtilsV2'
import i18n from 'src/lib/localization/i18n'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleHowSDBRestrictionsWork, possibleWhyRejected} from 'src/navigation/WebLinks'
import * as DashboardLoanUtils from 'src/products/loans/Dashboard/DashboardLoan.utils'
import {DashboardNavigation} from 'src/products/loans/Dashboard/DashboardLoan.utils'
import {
  ClosedLoanDashboardAggregateStatusQueryHookResult,
  DashboardLoanAggregateStatusQueryLoanOfferInfo,
} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {
  DashboardLoanClosedLoanDisbursementFields,
  DashboardLoanClosedLoanPaymentFields,
  DashboardLoanClosedProps,
} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.types'
import {usePfDispatch} from 'src/store/utils'

/**
 * Constructs complete props for DashboardLoanClosed given GraphQL response data for DashboardLoanAggregateStatusQuery.
 */
export const getDashboardLoanClosedProps = (config: {
  navigation: DashboardNavigation
  closedLoanDashboardData: Pick<
    ClosedLoanDashboardAggregateStatusQueryHookResult,
    'closedLoanAggregateStatus' | 'userProfile'
  >
  dispatch: ReturnType<typeof usePfDispatch>
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[]
}): DashboardLoanClosedProps => {
  const {
    closedLoanDashboardData,
    navigation,
    dispatch,
    shouldRedirectLoanApplyAndAcceptToWeb,
    loanOffers,
  } = config
  const {closedLoanAggregateStatus, userProfile} = closedLoanDashboardData

  const payments: DashboardLoanClosedLoanPaymentFields[] =
    closedLoanAggregateStatus.closedPayments.payments.map(
      (thisPayment: DashboardLoanClosedLoanPaymentFields) => {
        return {
          id: thisPayment.id,
          statusCode: thisPayment.statusCode,
          amount: thisPayment.amount,
          originalDate: thisPayment.originalDate,
          rescheduledDate: thisPayment.rescheduledDate,
          ordinal: thisPayment.ordinal,
          methodCode: thisPayment.methodCode,
          lastTransaction: thisPayment.lastTransaction,
        }
      },
    )

  const disbursement: DashboardLoanClosedLoanDisbursementFields | undefined =
    closedLoanAggregateStatus.closedDisbursement || undefined

  const loanCount = closedLoanAggregateStatus.closedLoanCount
  const hasPreviousLoan = loanCount > 1

  const props: DashboardLoanClosedProps = {
    loanOffers,
    payments,
    disbursement,
    prequalAmount: closedLoanAggregateStatus.prequalAmount,
    reason: closedLoanAggregateStatus.reason,
    reapplyOn: closedLoanAggregateStatus.reapplyOn,
    userEmailAddress: userProfile?.email?.address || '',
    hasPreviousLoan,
    onApplyForNewLoan: async ({sourceForAnalytics} = {}): Promise<void> => {
      const {isAfterReapplyOnDate} = checkIfEligibleToApplyAgain({
        reason: closedLoanAggregateStatus.reason,
        reapplyOn: closedLoanAggregateStatus.reapplyOn,
        prequalAmount: closedLoanAggregateStatus.prequalAmount,
      })
      if (!isAfterReapplyOnDate) {
        // this shouldn't be called if its before the reapplyOn date but we check just in case
        DashboardLoanUtils.loanDashboardLogError(
          new Error("Can't re-apply to a new loan yet"),
          `DashboardLoanClosed onApplyForNewLoan() called but its not after the reapplyOn date yet, reason=${closedLoanAggregateStatus.reason} reapplyOn=${closedLoanAggregateStatus.reapplyOn}, prequalAmount=${closedLoanAggregateStatus.prequalAmount}`,
        )
        ShowException(
          i18n.t('DashboardLoan:FeelFreeToApplyAgainOn', {
            date: new Date(closedLoanAggregateStatus.reapplyOn).toDateString(),
          }),
        )
        return
      }
      await DashboardLoanUtils.onReapplyForNewLoan({
        navigation,
        dispatch,
        shouldRedirectLoanApplyAndAcceptToWeb,
        loanOffers,
        reapplyEventArgs: {
          reason: closedLoanAggregateStatus.reason,
          source: sourceForAnalytics,
          reapplyOnDate: closedLoanAggregateStatus.reapplyOn,
          currentDate: new Date().toISOString(),
        },
      })
    },
    // onShowDefaultExplanation() and onShowChargedOffExplanation() are used by LoanProgressTile
    // but they're never used on the closed dashboard since we only show LoanProgressTile when loan is closed paid off,
    // not default or charged off, so we can leave them as a no op
    onShowDefaultExplanation: (): void => {
      DashboardLoanUtils.loanDashboardLogError(
        new Error(
          'DashboardLoanClosed onShowDefaultExplanation() called when it should not be possible',
        ),
      )
    },
    onShowChargedOffExplanation: (): void => {
      DashboardLoanUtils.loanDashboardLogError(
        new Error(
          'DashboardLoanClosed onShowChargedOffExplanation() called when it should not be possible',
        ),
      )
    },
    onContactUs: () => DashboardLoanUtils.onContactUs({navigation}),
    onLoanRejectedLearnMore: () => onLoanRejectedLearnMore({navigation}),
    onViewStateDatabasePolicy: () => onViewStateDatabasePolicy({navigation}),
    onViewLoanHistory: (): void => {
      DashboardLoanUtils.onViewLoanHistory({navigation})
    },
  }
  return props
}

export const onLoanRejectedLearnMore = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.rejected_explanation_selected, AppEvents.Category.LoanDashboard)
  PushWebPageOnStack(params.navigation, {uri: possibleWhyRejected})
}
export const onViewStateDatabasePolicy = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(LoanDashboardEvents.state_db_explanation_selected, AppEvents.Category.LoanDashboard)
  PushWebPageOnStack(params.navigation, {uri: possibleHowSDBRestrictionsWork})
}

/**
 * Check if the user is eligible to apply for a loan again. Determines if the LoanEligibleToApplyTile
 * should be shown.
 */
export const checkIfEligibleToApplyAgain = (
  params: Pick<ClosedLoanAggregateStatus, 'reason' | 'reapplyOn' | 'prequalAmount'>,
): {
  isAfterReapplyOnDate: boolean
  shouldShowEligibleToApplyTile: boolean
} => {
  const {reason, reapplyOn, prequalAmount} = params
  const reapplyOnDate = new Date(reapplyOn)
  const currentDate = new Date()
  const isAfterReapplyOnDate = currentDate > reapplyOnDate
  const isPaidOff = isLoanClosedReasonCodePaidOff(reason)

  // when users are eliglble to apply again (i.e. its after the reapplyOn date) we show them the LoanEligibleToApplyTile to let them reapply,
  // UNLESS their previous loan was paid off and they do NOT have a prequal amount. in that case they can reapply using the LoanPaidOffTile instead
  const shouldShowEligibleToApplyTile =
    isAfterReapplyOnDate &&
    (!isPaidOff || (isPaidOff && !!prequalAmount)) &&
    reason !== LoanClosedReasonCode.ChargedoffSettled &&
    reason !== LoanClosedReasonCode.LoanExpired &&
    reason !== LoanClosedReasonCode.DecisioningExpired &&
    reason !== LoanClosedReasonCode.Cancelled

  return {
    isAfterReapplyOnDate,
    shouldShowEligibleToApplyTile,
  }
}

/**
 * Determine if a paid off loan should display the LoanPaidOffTile or not.
 */
export const shouldShowLoanPaidOffTileForPaidOffLoan = (
  params: {
    isAfterReapplyOnDate: boolean
  } & Pick<ClosedLoanAggregateStatus, 'prequalAmount'>,
): boolean => {
  const {isAfterReapplyOnDate, prequalAmount} = params
  // if its after the reapplyOn date and they had a prequal amount they will get
  // the LoanEligibleToApplyTile displaying their prequal amount instead of LoanPaidOffTile
  if (
    !isAfterReapplyOnDate ||
    (isAfterReapplyOnDate && (!prequalAmount || parseFloat(prequalAmount) === 0))
  ) {
    return true
  }
  return false
}
