import React, {useCallback} from 'react'

import {ContextualizedLogException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {
  AggregatorPlaidError,
  AggregatorPlaidValidationContainerProps,
  LinkErrorCode,
  LinkExitMetadataStatus,
  onExitType,
  onInstitutionSelectedType,
  onSuccessType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'
import AggregatorPlaidLinkContainer from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaidLinkContainer'
import {
  aggregatorSwitchFromPlaid,
  showSwitchAggregatorPopup,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/PlaidUnsupportedInstitutions'
import {
  institutionIsSupported,
  showUnsupportedInstitutionPopup,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedInstitutions/UnsupportedInstitutions'

// this code was moved over but I added the typing...
// and `institution_not_found` does not exist on
// LinkExitMetadataStatus.
// I've added it here for now but I'm not sure if the
// code path will ever be hit.
const logExitStatus = (
  exitStatus: LinkExitMetadataStatus | 'institution_not_found' | undefined,
): void => {
  switch (exitStatus) {
    case 'requires_credentials':
      Log.log('Left Plaid stage without creating account')
      return
    case 'institution_not_found':
      Log.log('User failed to find institution of choice')
      return
    default:
      return
  }
}

const AggregatorPlaidValidationContainer: React.FC<AggregatorPlaidValidationContainerProps> = (
  props,
) => {
  const {onInstitutionSelected, onExit, token, onSuccess, ...rest} = props
  const {accountId, onSwitch, onEarlyExit} = rest

  const onSupportedInstitutionSelected = useCallback<onInstitutionSelectedType>(
    (institutionId, institutionName) => {
      // this is a very weirdly named utility method
      // but essentially we are checking if plaid can
      // handle the selected institution and, if it can't,
      // we switch to some other aggregator (probably yodlee)
      // I'd like to see all of this simply inside one parent
      // aggregator container in the future.
      const aggregatorForSelectedInstitution = aggregatorSwitchFromPlaid(institutionId)
      if (aggregatorForSelectedInstitution) {
        onEarlyExit()
        showSwitchAggregatorPopup(
          institutionName,
          aggregatorForSelectedInstitution,
          accountId,
          onSwitch,
        )
        return
      }

      onInstitutionSelected(institutionId, institutionName)
    },
    [onInstitutionSelected, onEarlyExit, accountId, onSwitch],
  )

  const onUnsupportedInstitutionSelected = useCallback<onInstitutionSelectedType>(
    (_institutionId, institutionName) => {
      onEarlyExit()
      showUnsupportedInstitutionPopup(institutionName)
    },
    [onEarlyExit],
  )

  // it turns out that this callback may not be called until
  // AFTER the success callback, so we need to validate institutions
  // in success as well.
  const handleInstitutionSelected = useCallback<onInstitutionSelectedType>(
    async (institutionId, institutionName) => {
      const isSupported = await institutionIsSupported(institutionId.toString())
      if (isSupported) {
        onSupportedInstitutionSelected(institutionId, institutionName)
      }
    },
    [onSupportedInstitutionSelected],
  )

  const handleSuccess = useCallback<onSuccessType>(
    async (data) => {
      try {
        const {institution} = data
        if (!institution) {
          throw new AggregatorPlaidError('Plaid onSuccess called without institution')
        }

        const {id, name} = institution
        const isSupported = await institutionIsSupported(id.toString())
        if (isSupported) {
          await onSuccess(data)
        } else {
          onUnsupportedInstitutionSelected(id, name)
        }
      } catch (e) {
        ContextualizedLogException('Failed to handle Plaid onSuccess')(e)
        throw e
      }
    },
    [onSuccess, onUnsupportedInstitutionSelected],
  )

  const handleExit = useCallback<onExitType>(
    (data) => {
      try {
        Log.log(`Plaid API data2 : ${JSON.stringify(data, null, 2)}`)

        const errorCode = data.error?.errorCode

        // A link token can become invalidated if it expires, has already been used
        // for a link session, or is associated with too many invalid logins.
        if (
          errorCode === LinkErrorCode.ITEM_NO_ERROR ||
          errorCode === LinkErrorCode.item_no_error
        ) {
          // according to previous comments this code
          // will execute when a user has successfully
          // linked an institution but that link
          // has fallen out of sync with the backend
          onExit(data)
          onSuccess({...data, publicToken: token, accounts: []}).catch(
            ContextualizedLogException('Failed to handle Plaid onSuccess'),
          )
        } else {
          logExitStatus(data.status)
          onEarlyExit()
        }
      } catch (e) {
        ContextualizedLogException('Failed to handle Plaid exit')(e)
      }
    },
    [onEarlyExit, onExit, onSuccess, token],
  )

  return (
    <AggregatorPlaidLinkContainer
      {...rest}
      onInstitutionSelected={handleInstitutionSelected}
      onSuccess={handleSuccess}
      onExit={handleExit}
      token={token}
    />
  )
}

export default AggregatorPlaidValidationContainer
