import {Consumer} from '@possible/cassandra'
import React, {useCallback, useEffect} from 'react'

import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {ContextualizedLogException} from 'src/lib/errors'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {usePfDispatch} from 'src/store/utils'

import {useUniversalPlaidOAuthRelink} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.hooks'
import {
  AggregatorPlaidError,
  LinkAccount,
  onInstitutionSelectedType,
  onSuccessType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.types'
import {
  institutionIsSupported,
  showUnsupportedInstitutionPopup,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedInstitutions/UnsupportedInstitutions'

export type OnOAuthRelinkComplete = (institutionName: string, accounts: LinkAccount[]) => void

type AggregatorPlaidOAuthRelinkProps = {
  onRelinkProcessing: () => void
  onRelinkComplete: OnOAuthRelinkComplete
}

/**
 * This is not used by the normal flow, but instead
 * is where we should return after OAuth bank linking
 * returns to the app.
 */
const AggregatorPlaidOAuthRelink: React.FC<AggregatorPlaidOAuthRelinkProps> = (props) => {
  const {onRelinkProcessing, onRelinkComplete} = props

  const dispatch = usePfDispatch()

  const [exchangeToken] = Consumer.hooks.useExchangeBankLinkTokenPlaidMutation()

  const onUnsupportedInstitutionSelected = useCallback<onInstitutionSelectedType>(
    (_institutionId, institutionName) => {
      showUnsupportedInstitutionPopup(institutionName)
    },
    [],
  )

  const handleSuccess = useCallback<onSuccessType>(
    async (event) => {
      try {
        const {institution} = event
        if (!institution) {
          throw new AggregatorPlaidError('Plaid onSuccess called without institution')
        }

        const {id, name} = institution
        const isSupported = await institutionIsSupported(id.toString())
        if (!isSupported) {
          onUnsupportedInstitutionSelected(id, name)
          return
        }

        onRelinkProcessing()

        const result = await exchangeToken({variables: {input: {publicToken: event.publicToken}}})
        if (result.errors) {
          throw result.errors[0]
        }

        await dispatch(UserStateRefresh())

        onRelinkComplete(event.institution?.name ?? '', event.accounts)
      } catch (e) {
        ContextualizedLogException('Failed to exchange Plaid OAuth token')(e)
      }
    },
    [
      dispatch,
      exchangeToken,
      onRelinkComplete,
      onRelinkProcessing,
      onUnsupportedInstitutionSelected,
    ],
  )

  const {ready: relinkReady, open: openRelink} = useUniversalPlaidOAuthRelink(handleSuccess)

  useEffect(() => {
    if (relinkReady && getIsFeatureFlagEnabled('use-plaid-refactor-and-oauth-redirect')) {
      try {
        openRelink()
      } catch (e) {
        ContextualizedLogException('Failed to open Plaid OAuth Relink')(e)
      }
    }
  }, [openRelink, relinkReady])

  return null
}

export default AggregatorPlaidOAuthRelink
