import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardSetUserSelfReportedDataMutationVariables = Types.Exact<{
  input: Types.UserSelfReportedDataInput
}>

export type CardSetUserSelfReportedDataMutation = {
  __typename?: 'Mutation'
  cardSetUserSelfReportedData: {
    __typename: 'UserSelfReportedDataResponse'
    annualGrossIncome?: string | null
    monthlyHousingCost?: string | null
  }
}

export const CardSetUserSelfReportedDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardSetUserSelfReportedData'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserSelfReportedDataInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardSetUserSelfReportedData'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'annualGrossIncome'}},
                {kind: 'Field', name: {kind: 'Name', value: 'monthlyHousingCost'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardSetUserSelfReportedDataMutation,
  CardSetUserSelfReportedDataMutationVariables
>
