// We have to use `console` in this file because this file
// represents the service that logs to Datadog. We can't use
// `Log` from `loggingUtil` because that would create a circular
// dependency.
/* eslint-disable no-console */
import DeviceInfo from 'react-native-device-info'

import {PRODUCTION_BRANCH, STAGING_BRANCH} from 'src/CodePushDeployments'
import CodePushUtils from 'src/CodePushUtils'
import {EVENT_SERVICE_LOGGING} from 'src/config'
import Datadog from 'src/lib/Analytics/Datadog'
import EventStreamSingleton, {EventStream} from 'src/lib/EventStream/EventStream'
import {EventStreamLogEvent, EventStreamMetadata} from 'src/lib/EventStream/EventStream.types'
import {EventStreamServiceBase} from 'src/lib/Services/EventStreamServiceBase'
import {readDevMode, readUiDeploymentKey} from 'src/lib/devMode'
import {getEnvironment} from 'src/lib/utils/environmentUtil'
import {isDeviceNotWeb} from 'src/lib/utils/platform'

const log = (message: string): void => {
  if (EVENT_SERVICE_LOGGING) {
    console.log(`[DatadogService] ${message}`)
  }
}

/**
 * DatadogService is a wrapper around the Datadog SDK.
 * It receives `log` events from the EventStream and
 * forwards them to Datadog.
 */
class DatadogService extends EventStreamServiceBase {
  _currentUserId: string | undefined

  init = async (): Promise<void> => {
    log('init...')
    const environment = await getEnvironment()
    let version = process.env.UI_VERSION
    if (isDeviceNotWeb()) {
      const isDev = await readDevMode()
      const codeBranch = isDev
        ? ((await readUiDeploymentKey())?.name ?? STAGING_BRANCH)
        : PRODUCTION_BRANCH
      // datadog seems to auto-append our codepush version
      // so the final version string that datadog sees will
      // look like `1.0.0:Production-codepush.v100`
      version = `${DeviceInfo.getVersion()}:${codeBranch}`
    }
    log(`init with environment: ${environment}, version: ${version}`)
    Datadog.init(environment, version)
    log('init complete')
  }

  metadataUpdated = (metadata: EventStreamMetadata): void => {
    log(`metadata updated: ${JSON.stringify(metadata)}`)
    if (metadata.userId !== this._currentUserId) {
      this._currentUserId = metadata.userId
      Datadog.setUserId(metadata.userId)
    }
  }

  logListener = (event: EventStreamLogEvent): void => {
    log(event.data)
    Datadog[event.severity](event.data, {
      ...event.context,
      user_id: this._currentUserId,
    })
  }

  startListening(eventStream: EventStream = EventStreamSingleton): void {
    super.startListening(eventStream)
    eventStream.addListener('log', this.logListener)
  }

  stopListening(eventStream: EventStream = EventStreamSingleton): void {
    super.stopListening(eventStream)
    eventStream.removeListener('log', this.logListener)
  }
}

export {DatadogService}
