import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {SummaryCard} from 'src/designSystem/components/molecules/SummaryCard/SummaryCard'
import {SummaryLineItemProps} from 'src/designSystem/components/molecules/SummaryCard/SummaryLineItem'
import {prefixSsn} from 'src/lib/utils/ssnUtil'
import {NamedColors} from 'src/designSystem/colors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {formatDate} from 'src/lib/utils/date'
import {APIDateFormat, apiParseDateFormat} from 'src/lib/time_util'

export type LoanSubmissionTemplateProps = {
  isSubmitBtnLoading: boolean
  isSubmitBtnDisabled: boolean
  showCoastalFooterNote?: boolean
  loanAmount: string
  fullName: string
  email: string
  birthDate: string
  address: string
  ssnLastFour: string
  onSubmitLoanApplication: () => void | Promise<void>
  onPressContactUs: () => void
}

/**
 * Template for the LoanSubmission screen to apply and reapply for a loan. Displays a summary of their personal information with a button to confirm
 * and submit their loan application.
 */
const LoanSubmissionTemplate: React.FC<LoanSubmissionTemplateProps> = (
  props: LoanSubmissionTemplateProps,
) => {
  const {
    isSubmitBtnDisabled,
    isSubmitBtnLoading,
    showCoastalFooterNote,
    loanAmount,
    fullName,
    email,
    birthDate,
    address,
    ssnLastFour,
    onSubmitLoanApplication,
    onPressContactUs: handleOnPressContactUs,
  } = props

  const {t} = useTranslation(['LoanSubmission', 'Common'])

  const summaryLineItems: SummaryLineItemProps[] = [
    {
      label: t('Common:Name'),
      value: fullName,
    },
    {
      label: (
        <Trans
          t={t}
          i18nKey={'Common:AmountRequested'}
          components={{
            blue: <PFText variant={'label_sm'} color={NamedColors.PRODUCT_BLUE} />,
          }}
        />
      ),
      value: `$${loanAmount}`,
    },
    {
      label: t('Common:Email'),
      value: email,
    },
    {
      label: t('Common:BirthDate'),
      value: formatDate(birthDate, APIDateFormat, apiParseDateFormat),
    },
    {
      label: t('Common:Address'),
      value: address,
    },
    {
      label: t('SSN'),
      value: prefixSsn(ssnLastFour),
    },
  ]

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      title={t('ReviewAndSubmit')}
      description={t('PleaseReview')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          testID: 'LoanSubmissionTemplate-submit-btn',
          text: t('SubmitApplication'),
          onPress: () => onSubmitLoanApplication(),
          loading: isSubmitBtnLoading,
          disabled: isSubmitBtnDisabled,
        },
      }}
    >
      <SummaryCard lineItems={summaryLineItems} />
      <Box paddingTop="small" gap="small">
        <Box
          border={{width: 1, color: NamedColors.ASH}}
          radius={4}
          padding={'small'}
          direction={'row'}
          gap="tiny"
        >
          <Box marginRight={'tiny'}>
            <SvgIcon size={'medium'} colorVariant={'info'} isFilled name={'profile'} />
          </Box>
          <SvgLink
            onPress={handleOnPressContactUs}
            linkText={t('Common:ContactUs')}
            linkType={'inline'}
          />
          <PFText variant="p">{t('ToUpdateYourInfo')}</PFText>
        </Box>
        {showCoastalFooterNote ? (
          <PFText variant="p_sm" color={NamedColors.SILVER}>
            {t('CoastalSubmissionNote')}
          </PFText>
        ) : null}
        <PFText variant="p_sm" color={NamedColors.SILVER}>
          {t('BySubmittingThisApplication')}
        </PFText>
      </Box>
    </Page>
  )
}

export {LoanSubmissionTemplate}
