import React, {useCallback, useRef, useState, FC, useEffect, useMemo, ReactElement} from 'react'
import {
  Dimensions,
  StatusBar,
  StyleSheet,
  View,
  ViewStyle,
  StatusBarStyle,
  FlexStyle,
  TextStyle,
  KeyboardAvoidingView,
  DimensionValue,
} from 'react-native'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import {SafeAreaView, Edge, useSafeAreaInsets} from 'react-native-safe-area-context'
import {SvgProps} from 'react-native-svg'

import {
  pageMarginHorizontal,
  headerHeight,
  smallHeaderHeight,
  paginationHeaderHeight,
  paginationStepHeight,
  littleGap,
  largeGap,
  contentMaxWidth,
} from 'src/designSystem/layout'
import {genericPageBackground, subheaderText} from 'src/designSystem/semanticColors'
import {defaultExtraHeightOffset} from 'src/designSystem/guide'
import ButtonLockup, {
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {TextVariants} from 'src/designSystem/typography'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {isDeviceWeb, isDeviceIOS, rootTagNameWeb, isDeviceAndroid} from 'src/lib/utils/platform'
import {isStringValueEmpty, isNumericValueValid} from 'src/designSystem/lib/dataUtil'
import {Color, SizeVariants} from 'src/designSystem/types'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import Pagination from 'src/designSystem/components/atoms/Pagination/Pagination'
import {ScrollViewBaseType} from 'src/designSystem/components/molecules/ScrollViewBase/ScrollViewBase'
import {white} from 'src/designSystem/colors'

type JustifyType = FlexStyle['justifyContent']

const availableHeight = (): number => {
  if (isDeviceWeb()) {
    //computes the style of root element maxHeight if provided
    const rootTag: HTMLElement | null = document.getElementById(rootTagNameWeb)
    const rootTagMaxHeightStyle = rootTag
      ? window.getComputedStyle(rootTag).getPropertyValue('max-height').replace('px', '')
      : null
    const rootMaxHeightValue: number | null =
      rootTagMaxHeightStyle && !isStringValueEmpty(rootTagMaxHeightStyle)
        ? Number(rootTagMaxHeightStyle)
        : null

    return rootMaxHeightValue
      ? Math.min(window.innerHeight, rootMaxHeightValue)
      : window.innerHeight
  } else {
    return Math.round(Dimensions.get('window').height)
  }
}

const getSelectedEdges = (hasBanner: boolean): Edge[] => {
  const safeAreaEdgesList: Edge[] = ['top', 'right', 'left'] //bottom is handled by bottom view
  return hasBanner ? safeAreaEdgesList.filter((edge) => edge !== 'top') : safeAreaEdgesList
}

const getContentStyle = (
  noHorizontalPadding: boolean | undefined,
  expandVertically?: boolean,
  contentBackgroundColor?: Color,
): ViewStyle[] => {
  const contentStyles: ViewStyle[] = []
  contentStyles.push(noHorizontalPadding ? styles.contentViewNoPadding : styles.contentView)
  if (expandVertically) {
    contentStyles.push(styles.contentViewExpandVertically)
  }
  if (contentBackgroundColor) {
    contentStyles.push({backgroundColor: contentBackgroundColor})
  }
  return contentStyles
}

const getContentInnerStyle = (contentVerticalAlignment: JustifyType): ViewStyle => {
  return {
    flex: 1,
    justifyContent: contentVerticalAlignment ?? 'flex-start',
  }
}

const getTitleTextVariant = (variant: PageTypeVariants): TextVariants => {
  return variant === 'generic' ? 'h1' : 'h3'
}

const getBannerExtraGap = (variant: PageTypeVariants): SizeVariants | number => {
  return variant === 'interstitial' ? 'large' : 0
}

const getBannerExtraMargin = (variant: PageTypeVariants): SizeVariants | number => {
  return variant === 'interstitial' ? 'little' : 'large'
}

const getExtraHeightOffsetOnOpenInputKeyboard = (): number => {
  if (isDeviceIOS()) {
    //ios needs extra gap when we show the InputAccessoryView
    return defaultExtraHeightOffset + pfHeaderHeight
  } else {
    return defaultExtraHeightOffset
  }
}

type PageTypeVariants = 'generic' | 'interstitial'

export type PageCommonProps = React.PropsWithChildren & {
  testID?: string
  variant: PageTypeVariants
  title?: string | JSX.Element
  titleTextAlignment?: TextStyle['textAlign']
  description?: string | ReactElement
  descriptionTextVariant?: TextVariants
  buttonProps?: ButtonLockupProps
  banner?: FC<SvgProps>
  noHorizontalPadding?: boolean
  smallTopGap?: boolean
  keyboardShouldPersistTaps?: ScrollViewBaseType['keyboardShouldPersistTaps']
  paginationSteps?: number
  currentStep?: number
  expandVertically?: boolean
  barStyle?: StatusBarStyle
  contentVerticalAlignment?: JustifyType
  backgroundColor?: Color
  bannerBackgroundColor?: Color
  contentBackgroundColor?: Color
  safeAreaBackground?: Color
  additionalHeaderSpacer?: boolean
  scaleBannerToFit?: boolean
  disableScrollOnContentFit?: boolean
  enabledKeyboardAvoidingView?: boolean
  noHeaderSpacer?: boolean
}

type PageGenericProps = {
  variant: 'generic'
}

type PageInterstitialProps = {
  variant: 'interstitial'
  banner: FC<SvgProps>
}

type PageConditionalProps = PageGenericProps | PageInterstitialProps

export type PageProps = PageCommonProps & PageConditionalProps

const Page = (props: PageProps): JSX.Element => {
  const {
    testID,
    buttonProps,
    banner,
    children,
    title,
    variant,
    description,
    descriptionTextVariant,
    noHorizontalPadding,
    expandVertically,
    smallTopGap,
    keyboardShouldPersistTaps,
    paginationSteps,
    currentStep,
    barStyle,
    titleTextAlignment,
    contentVerticalAlignment,
    backgroundColor,
    bannerBackgroundColor,
    contentBackgroundColor,
    safeAreaBackground,
    additionalHeaderSpacer,
    scaleBannerToFit,
    disableScrollOnContentFit,
    enabledKeyboardAvoidingView = false,
    noHeaderSpacer,
  } = props
  const keyboardViewRef = useRef<KeyboardAwareScrollView>(null)
  const [keyboardOpen, setKeyboardOpen] = useState<boolean>(false)
  const [scrollViewContentHeight, setScrollViewContentHeight] = useState<number | null>(null)
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(true)
  const [bannerWidth, setBannerWidth] = useState<DimensionValue>('100%')

  const showPagination = useMemo(
    () => isNumericValueValid(paginationSteps) && isNumericValueValid(currentStep),
    [paginationSteps, currentStep],
  )

  const insets = useSafeAreaInsets()

  useEffect(() => {
    if (scrollViewContentHeight) {
      // There are certain Use cases where we want to disable scrolling if the content fits on the screen
      if (disableScrollOnContentFit) {
        if (availableHeight() > scrollViewContentHeight) {
          setScrollEnabled(false)
        } else {
          setScrollEnabled(true)
        }
      }
      if (scaleBannerToFit) {
        const acceptableHeightDifference = 100
        const fitInView =
          availableHeight() - (scrollViewContentHeight ?? 0) > acceptableHeightDifference
        if (!fitInView) {
          setBannerWidth('75%')
        }
      }
    }
  }, [disableScrollOnContentFit, scrollViewContentHeight, scaleBannerToFit])

  const _onContentSizeChange = (width, height) => {
    setScrollViewContentHeight(height)
  }

  const _keyboardDidShow = () => {
    if (!keyboardOpen) {
      setKeyboardOpen(true)
      if (isDeviceAndroid()) {
        keyboardViewRef.current?.scrollToPosition(0, availableHeight() * 0.1, true)
      }
    }
  }

  const _keyboardDidHide = () => {
    if (keyboardOpen) {
      setKeyboardOpen(false)
      if (isDeviceAndroid()) {
        keyboardViewRef.current?.scrollToPosition(0, -availableHeight() * 0.1, true)
      }
    }
  }

  const getBannerView = () => {
    if (banner) {
      return (
        <View
          style={[styles.bannerView, {backgroundColor: bannerBackgroundColor ?? backgroundColor}]}
        >
          <PFSvgContain width={bannerWidth} maxWidth={contentMaxWidth} svg={banner} />
        </View>
      )
    } else {
      return <View style={TopGapStyle(variant, showPagination, smallTopGap)} />
    }
  }

  const upperContentPadding = () => {
    //noHorizontalPadding removed the padding from the outer content wrapper but we should still apply it to the title and description
    return noHorizontalPadding ? pageMarginHorizontal : undefined
  }

  const getTitleView = (): JSX.Element | null => {
    if (!title) {
      return null
    }

    return (
      <View accessible={true} testID={'Page_Title'} style={{backgroundColor: backgroundColor}}>
        <Box
          marginBottom={'medium'}
          marginTop={getBannerExtraGap(variant)}
          /* It's unclear if this padding is still necessary. It was brought over from when we had
           * a `titleHeader` that was removed because it was never used. Visually all version of
           * the Page component are expecting this spacing still so for now we're keeping it to
           * play it safe. */
          paddingTop={'medium'}
          paddingHorizontal={upperContentPadding()}
        >
          {typeof title === 'string' ? (
            <PFText variant={getTitleTextVariant(variant)} textAlign={titleTextAlignment}>
              {title}
            </PFText>
          ) : (
            title
          )}
        </Box>
      </View>
    )
  }

  const getDescriptionView = () => {
    if (!description) {
      return
    }

    return (
      <View accessible={true} testID={'Page_Description'}>
        <Box
          gap={'none'}
          marginBottom={getBannerExtraMargin(variant)}
          paddingHorizontal={upperContentPadding()}
        >
          {typeof description === 'string' ? (
            <PFText
              textAlign={'left'}
              variant={descriptionTextVariant ?? 'p'}
              color={subheaderText}
            >
              {description}
            </PFText>
          ) : (
            description
          )}
        </Box>
      </View>
    )
  }

  const renderPagination = useCallback(() => {
    if (!showPagination) {
      return undefined
    }

    return (
      <Box marginTop={'large'} paddingHorizontal={pageMarginHorizontal}>
        <Pagination steps={paginationSteps ?? 0} currentStep={currentStep ?? 0} />
      </Box>
    )
  }, [paginationSteps, currentStep, showPagination])

  const getBottomView = useCallback(() => {
    const insetStyle = {
      paddingBottom: buttonProps?.type === 'doubleButton' ? insets.bottom : undefined,
    }

    const buttonStackViewStyles = [
      styles.buttonStackView,
      insetStyle,
      contentBackgroundColor && {backgroundColor: contentBackgroundColor},
    ]

    if (buttonProps) {
      return (
        <View style={buttonStackViewStyles}>
          <ButtonLockup {...buttonProps} />
        </View>
      )
    }
    return null
  }, [buttonProps, contentBackgroundColor, insets.bottom])

  // Kirsten wants additional padding on bigger screen sizes
  const additionalHeaderHeight = availableHeight() > 500 ? 0 : 8

  const renderKeyboardAwareScrollView = () => {
    return (
      <KeyboardAwareScrollView
        ref={keyboardViewRef}
        style={[styles.scrollView, {backgroundColor}]}
        contentContainerStyle={styles.contentContainerStyle}
        extraHeight={getExtraHeightOffsetOnOpenInputKeyboard()}
        onContentSizeChange={_onContentSizeChange}
        onKeyboardWillShow={_keyboardDidShow}
        onKeyboardWillHide={_keyboardDidHide}
        //onKeyboardWillShow/onKeyboardWillHide doesn't fire on android so using onKeyboardDidShow/onKeyboardDidHide
        //but we need to keep these for iOS otherwise there is a delay when the keyboard is hidden
        onKeyboardDidShow={_keyboardDidShow}
        onKeyboardDidHide={_keyboardDidHide}
        scrollEventThrottle={1}
        scrollEnabled={scrollEnabled}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps ?? 'handled'}
      >
        {getBannerView()}

        <View
          style={getContentStyle(noHorizontalPadding, expandVertically, contentBackgroundColor)}
        >
          {getTitleView()}
          {getDescriptionView()}
          <View style={getContentInnerStyle(contentVerticalAlignment)}>{children}</View>
        </View>
        {getBottomView()}
      </KeyboardAwareScrollView>
    )
  }

  const getHeaderSpacer = (): JSX.Element | undefined => {
    if (noHeaderSpacer) {
      return
    } else if (variant === 'generic') {
      return <HeaderSpacer backgroundColor={backgroundColor} />
    } else {
      return
    }
  }

  return (
    <View testID={testID} style={styles.containerView}>
      <StatusBar barStyle={barStyle ?? 'dark-content'} backgroundColor={backgroundColor} />
      {additionalHeaderSpacer || isDeviceWeb() ? (
        <HeaderSpacer backgroundColor={backgroundColor} height={additionalHeaderHeight} />
      ) : undefined}
      {getHeaderSpacer()}
      <SafeAreaView
        style={[styles.safeAreaView, safeAreaBackground && {backgroundColor: safeAreaBackground}]}
        edges={getSelectedEdges(!!banner)}
      >
        {renderPagination()}
        {enabledKeyboardAvoidingView && isDeviceIOS() ? (
          <KeyboardAvoidingView
            style={styles.keyboardAvoidingView}
            behavior={isDeviceIOS() ? 'padding' : undefined}
          >
            {renderKeyboardAwareScrollView()}
          </KeyboardAvoidingView>
        ) : (
          renderKeyboardAwareScrollView()
        )}
      </SafeAreaView>
    </View>
  )
}
export default Page

const styles = StyleSheet.create({
  bannerView: {
    alignItems: 'center',
    width: '100%',
  },
  buttonStackView: {
    backgroundColor: genericPageBackground,
    width: '100%',
  },
  containerView: {
    flex: 1,
    width: '100%',
  },
  contentContainerStyle: {
    backgroundColor: genericPageBackground,
    flexGrow: 1,
    width: '100%',
  },
  contentView: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: contentMaxWidth,
    paddingHorizontal: pageMarginHorizontal,
    width: '100%',
  },
  contentViewExpandVertically: {
    flex: 1,
  },
  contentViewNoPadding: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: contentMaxWidth,
    width: '100%',
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  safeAreaView: {
    backgroundColor: white,
    flex: 1,
  },
  scrollView: {
    backgroundColor: genericPageBackground,
    flex: 1,
    width: '100%',
  },
})

const paginationViewHeight: number = largeGap + littleGap + paginationStepHeight //marginTop + marginBottom + paginationStepHeight = 48

const TopGapStyle = (
  pageTypeVariant: PageTypeVariants,
  showPagination: boolean,
  useSmallGap?: boolean,
): ViewStyle => {
  const pfHeaderMargin = pageTypeVariant === 'interstitial' ? 0 : pfHeaderHeight
  let fullHeight = headerHeight
  if (showPagination) {
    fullHeight = paginationHeaderHeight - paginationViewHeight
  } else if (useSmallGap) {
    fullHeight = smallHeaderHeight
  }
  return {
    marginTop: fullHeight - pfHeaderMargin,
  }
}
