import React, {ReactElement} from 'react'
import {StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {Color} from 'src/designSystem/types'
import {
  PFStatusPillProps,
  PFStatusPill,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {getTestID} from 'src/lib/utils/tests.utils'

type TranscationItemProps = {
  // icon is shown on the left side
  icon: ReactElement
  // description is shown in the center
  description: {
    title: string
    subtitle: string
    subtitleEmphasized: string
    fontColor?: Color
  }
  // data is shown on the right
  data: {
    value: string
    statusPill?: PFStatusPillProps
    fontColor?: Color
  }
}

/**
 * Generic component for a transaction item. Displays:
 * - An icon in the left column
 * - A description in the center column
 * - Data (i.e. transaction amount) with an optional status pill in the right column
 */
const TransactionItem: React.FC<TranscationItemProps> = (props: TranscationItemProps) => {
  const {icon, description, data} = props
  return (
    <Box direction="row" padding="little">
      <Box direction="row" grow={true} boxStyle={styles.rowContainer}>
        {/* icon */}
        <Box
          paddingHorizontal={12}
          paddingTop="little"
          testID={getTestID(description.title, '-icon')}
        >
          {icon}
        </Box>
        {/* description  */}
        <Box grow={true} marginRight={'little'} boxStyle={styles.titleDescriptionContainer}>
          {/* <Box boxStyle={styles.titleDescriptionContainer}> */}
          <PFText variant="p" color={description.fontColor}>
            {description.title}
          </PFText>
          <PFText variant="p_sm" color={description.fontColor}>
            {description.subtitle}
          </PFText>
          <PFText variant="p_sm_semibold" color={description.fontColor}>
            {description.subtitleEmphasized}
          </PFText>
          {/* </Box> */}
        </Box>
        {/* data */}
        <Box align="end" marginLeft={0} grow={true} direction="column">
          <Box align="end">
            <PFText variant="p_semibold" color={data.fontColor}>
              {data.value}
            </PFText>
            {data.statusPill ? <PFStatusPill {...data.statusPill} /> : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TransactionItem

const styles = StyleSheet.create({
  rowContainer: {
    maxWidth: '100%',
  },
  titleDescriptionContainer: {
    maxWidth: '60%',
  },
})
