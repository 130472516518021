import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CardMinPaymentsQueryVariables = Types.Exact<{[key: string]: never}>

export type CardMinPaymentsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        autopayEnabled: boolean
        supportsMinPay: boolean
        statements: {
          __typename?: 'CardAccountStatementCollection'
          mostRecent?: {
            __typename?: 'CardAccountStatement'
            id: string
            balance?: string | null
            statementDate: string
            paymentDueDate?: string | null
          } | null
        }
        status:
          | {
              __typename: 'ActiveCardAccountStatus'
              code: Types.CardAccountStatusCode
              subStatus: Types.CardAccountSubStatus
              autopayModel: Types.AutopayModel
              restriction?:
                | {__typename?: 'CardAccountLockedRestriction'}
                | {
                    __typename?: 'CardAccountSuspendedRestriction'
                    code: Types.ActiveCardAccountRestrictionCode
                    reason?: Types.CardAccountSuspensionReasonCode | null
                    occurredAt: string
                  }
                | null
            }
          | {__typename: 'ApprovedCardAccountStatus'}
          | {__typename: 'CancelledCardAccountStatus'}
          | {
              __typename: 'DeactivatedCardAccountStatus'
              code: Types.CardAccountStatusCode
              subStatus: Types.CardAccountSubStatus
              autopayModel: Types.AutopayModel
            }
          | {__typename: 'ExpiredCardAccountStatus'}
          | {__typename: 'PendingCardAccountStatus'}
          | {__typename: 'RejectedCardAccountStatus'}
        payments: {
          __typename?: 'CardPaymentCollection'
          schedule?: Array<{
            __typename?: 'AutomaticPaymentSchedule'
            paymentDate: string
            id?: string | null
            currentExpectedAmount?: string | null
          }> | null
        }
        balance?: {
          __typename?: 'CardAccountBalance'
          minimumPaymentDue?: string | null
          minimumPaymentDueAfterPayments?: string | null
          statement?: string | null
          statementAfterPayments?: string | null
          pastDueAfterPayments?: string | null
        } | null
        cardAccountType?: {__typename?: 'CardAccountType'; monthlyMembershipFees: string} | null
      } | null
    }
  }
}

export const CardMinPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CardMinPayments'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'active'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'supportsMinPay'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'statements'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'mostRecent'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'statementDate'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'paymentDueDate'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'autopayModel'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'restriction'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'CardAccountSuspendedRestriction',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'code'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'reason'},
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {kind: 'Name', value: 'occurredAt'},
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'autopayModel'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'payments'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'schedule'},
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {kind: 'Name', value: 'numberOfPayments'},
                                        value: {kind: 'IntValue', value: '100'},
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'currentExpectedAmount'},
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'balance'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'minimumPaymentDueAfterPayments'},
                                  },
                                  {kind: 'Field', name: {kind: 'Name', value: 'statement'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'statementAfterPayments'},
                                  },
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'pastDueAfterPayments'},
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'cardAccountType'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'monthlyMembershipFees'},
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardMinPaymentsQuery, CardMinPaymentsQueryVariables>
