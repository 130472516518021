import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'

import {SizeVariants} from 'src/designSystem/types'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {basicTileBorderRadius, basicTileElevation} from 'src/designSystem/guide'
import {
  PFStatusPillProps,
  PFStatusPill,
} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {BoxAlignType} from 'src/designSystem/components/atoms/Box/styles'

type BasicTileProps = React.PropsWithChildren & {
  padding?: SizeVariants | number
  gap?: 'none' | SizeVariants
  align?: BoxAlignType
  radius?: number
  styles?: BoxProps
  pill?: PFStatusPillProps
  pillAlignment?: 'left' | 'center'
  testID?: string
}

/**
 * Container component for a tile with included padding and border radius.
 *
 * @example <BasicTile padding='medium'>...children</BasicTile>
 * @example <BasicTile padding='large' align='center' radius={8}>...children</BasicTile>
 */
const BasicTile: FC<BasicTileProps> = (props) => {
  const {
    padding,
    children,
    gap,
    align,
    radius = basicTileBorderRadius,
    styles = {},
    pill,
    pillAlignment = 'center',
    testID,
  } = props
  const boxProps: BoxProps = {
    elevation: basicTileElevation,
    radius,
    padding,
    ...(gap ? {gap} : {}),
    ...(align ? {align} : {}),
    ...styles,
  }

  const tile = (
    <Box testID={testID} {...boxProps}>
      {children}
    </Box>
  )

  if (pill) {
    return (
      <View testID={testID}>
        <View style={pillAlignment === 'left' ? cssStyles.pillLeft : cssStyles.pillCenter}>
          <PFStatusPill {...pill} />
        </View>
        {tile}
      </View>
    )
  } else {
    return tile
  }
}

const cssStyles = StyleSheet.create({
  pillCenter: {
    alignSelf: 'center',
    position: 'relative',
    top: 11,
    zIndex: 1,
  },
  pillLeft: {
    alignSelf: 'flex-start',
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
})

export default BasicTile
