import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  PossibleCardSelection,
  PossibleCardSelectionProps,
} from 'src/products/loans/ProductChoice/PossibleCardSelection'
import PossibleLoanSelection from 'src/products/loans/ProductChoice/PossibleLoanSelection'
import {CardLevel, LoanValues} from 'src/products/loans/ProductChoice/types'

const AllLoanValues: LoanValues = {
  minimumAmount: 25,
  maximumAmount: 500,
}

export type ProductChoiceTemplateProps = {
  handleOnLoanPressed: () => Promise<void>
  handleOnCardPressed: () => Promise<void>
  showLoans: boolean
  showCards: boolean
  cardLevels: CardLevel[] | undefined
  isBusy: boolean
} & Pick<
  PossibleCardSelectionProps,
  'handleOnPressExitAndCancelCardApplication' | 'isInCardBureauFlow' | 'isUserLoanGrad'
>

export const ProductChoiceTemplate = (props: ProductChoiceTemplateProps): JSX.Element => {
  const {
    handleOnLoanPressed,
    handleOnCardPressed,
    showLoans,
    showCards,
    cardLevels,
    isBusy,
    handleOnPressExitAndCancelCardApplication: onHandleOnPressExitAndCancelCardApplication,
    isInCardBureauFlow,
    isUserLoanGrad,
  } = props
  const {t} = useTranslation(['ProductSelection', 'Common'])

  let title = ''
  if (showLoans) {
    title = t('CardOrLoan')
  } else if (showCards) {
    title = t('GetThePossibleCard', {amount: cardLevels?.[0]?.creditAmount ?? ''})
  }

  return (
    <Page variant="generic" title={title} smallTopGap testID="Product-Choice-Page-Id">
      {showCards ? (
        <PossibleCardSelection
          cardLevels={cardLevels ?? []}
          buttonDisabled={isBusy}
          onTileClick={(): void => {
            void handleOnCardPressed()
          }}
          handleOnPressExitAndCancelCardApplication={onHandleOnPressExitAndCancelCardApplication}
          isInCardBureauFlow={isInCardBureauFlow}
          isUserLoanGrad={isUserLoanGrad}
        />
      ) : null}
      {showLoans ? (
        <PossibleLoanSelection {...AllLoanValues} onTileClick={handleOnLoanPressed} />
      ) : null}
    </Page>
  )
}
