import React, {FC, useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {ScrollView, StyleSheet} from 'react-native'
import {SvgProps} from 'react-native-svg'

import BlueBank from 'src/assets/icons/BlueBank.svg'
import BlueClock from 'src/assets/icons/BlueClock.svg'
import BlueScale from 'src/assets/icons/BlueScale.svg'
import BlueWallet from 'src/assets/icons/BlueWallet.svg'
import PlaidSVG from 'src/assets/icons/Plaid.svg'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import ButtonLockup from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {enormousGap, tinyGap} from 'src/designSystem/layout'
import {usePrevious} from 'src/lib/utils/hooks'
import {isDeviceWeb, isDeviceWebOnDesktop} from 'src/lib/utils/platform'
import {showUnsupportedInstitutionPopup} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedInstitutions/UnsupportedInstitutions'

export const shouldShowAddBankLinkPageWithBanner = (): boolean => {
  //for web on desktop we don't want to display the interstitial version with the banner image
  return !isDeviceWebOnDesktop()
}

export type AddBankLinkTemplateProps = {
  pageTitle: string
  onAddBank: () => void
  onCheckBankSupport: () => void
  isOkButtonLoading: boolean
  bankNameIfUnsupported?: string // if their bank is unsupported this should contain their bank name
}

/**
 * Display a graphic with information about how linking a bank account works and provide a button to
 * open a banking aggregator, allowing users to link a bank account.
 */
const AddBankLinkTemplate: FC<AddBankLinkTemplateProps> = (props: AddBankLinkTemplateProps) => {
  const {onAddBank, isOkButtonLoading, bankNameIfUnsupported} = props
  const {t} = useTranslation(['AddBankLink', 'Common'])

  const prevBankNameIfUnsupported = usePrevious(bankNameIfUnsupported)
  useEffect(() => {
    if (bankNameIfUnsupported && !prevBankNameIfUnsupported) {
      // the first time this is given an unsupported bank name we show
      // the unsupported bank popup
      showUnsupportedInstitutionPopup(bankNameIfUnsupported)
    }
  }, [bankNameIfUnsupported, prevBankNameIfUnsupported])

  const svgProps = {width: 100, height: 50}
  const v2ValueProps: {
    text: React.ReactElement
    icon: React.FC<SvgProps>
    testID: string
  }[] = [
    {
      text: (
        <Trans
          t={t}
          i18nKey="ValuePropFeatureItem1"
          components={{strong: <PFText variant="p_semibold" />}}
        />
      ),
      icon: BlueWallet,
      testID: 'ValuePropFeatureItem1',
    },
    {
      text: (
        <Trans
          t={t}
          i18nKey="ValuePropFeatureItem2"
          components={{strong: <PFText variant="p_semibold" />}}
        />
      ),
      icon: BlueScale,
      testID: 'ValuePropFeatureItem2',
    },
    {
      text: (
        <Trans
          t={t}
          i18nKey="ValuePropFeatureItem3"
          components={{strong: <PFText variant="p_semibold" />}}
        />
      ),
      icon: BlueClock,
      testID: 'ValuePropFeatureItem3',
    },
    {
      text: (
        <Trans
          t={t}
          i18nKey="ValuePropFeatureItem4"
          components={{strong: <PFText variant="p_semibold" />}}
        />
      ),
      icon: BlueBank,
      testID: 'ValuePropFeatureItem4',
    },
  ]
  return (
    <ScrollView
      style={styles.featuresListScrollViewContainer}
      contentContainerStyle={styles.featuresListScrollViewContentContainer}
      showsVerticalScrollIndicator={false}
    >
      <Box height={'100%'} boxStyle={styles.featuresListContentContainerView}>
        <Box
          height={180}
          background={NamedColors.SLATE}
          paddingHorizontal={'medium'}
          justify="center"
          width="100%"
        >
          <Box paddingTop={'enormous'} width={'100%'} align="center" justify="center">
            <Box paddingTop={isDeviceWeb() ? 0 : enormousGap}>
              <PFText variant="h3" textAlign="center">
                <Trans
                  t={t}
                  i18nKey="V2HeaderText"
                  components={{em: <PFText variant="h3" color={NamedColors.PRODUCT_BLUE} />}}
                />
              </PFText>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box padding={'enormous'} width={'100%'} align="center" justify="center">
        <PFText variant="p_semibold" textAlign="center">
          {t('V2ApprovalText')}
        </PFText>
      </Box>
      <Box paddingHorizontal={'medium'} testID="features list">
        {v2ValueProps.map((item) => (
          <Box
            direction="row"
            paddingVertical="small"
            key={`value-prop-${item.testID}`}
            testID={item.testID}
          >
            <Box paddingRight="small">
              <PFSvg SvgUri={item.icon} svgProps={{height: 24, width: 24}} />
            </Box>
            <PFText variant="p">{item.text}</PFText>
          </Box>
        ))}
      </Box>
      <Box align="center" justify="center" paddingHorizontal="large" paddingVertical="medium">
        <PFSvg SvgUri={PlaidSVG} svgProps={svgProps} manualWebResizeMethod={'width'} />
        <PFText variant="p_sm" textAlign="center">
          {t('VendorExplanation')}
        </PFText>
      </Box>
      <Box width={'100%'}>
        <ButtonLockup
          type="singleButton"
          primary={{
            text: t('ButtonText'),
            onPress: onAddBank,
            testID: 'BankLinkPropsButton',
            disabled: isOkButtonLoading,
            loading: isOkButtonLoading,
          }}
        />
      </Box>
    </ScrollView>
  )
}

export default AddBankLinkTemplate

const styles = StyleSheet.create({
  featuresListContentContainerView: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  featuresListScrollViewContainer: {
    flex: 1,
    paddingBottom: tinyGap,
  },
  featuresListScrollViewContentContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
