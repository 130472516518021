import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {CohortPhase} from 'src/cassandra'

type UseIsInCardBureauFlowReturnType = {
  isLoading: boolean
  isInCardBureauFlow: boolean
}
export const useIsInCardBureauFlow = (): UseIsInCardBureauFlowReturnType => {
  const {value: cardUserProperties, isLoading} = useUserProperty('cardApplicationGroup')
  return {
    isLoading,
    isInCardBureauFlow: cardUserProperties?.cohortPhase === CohortPhase.PgaBuw,
  }
}
