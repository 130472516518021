import {ErrorBoundary} from 'react-error-boundary'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import React, {FC} from 'react'

import TileListTemplate from 'src/products/loans/components/templates/TileListTemplate/TileListTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {DashboardLoan} from 'src/products/loans/Dashboard/DashboardLoan/DashboardLoan'
import ErrorTile from 'src/designSystem/components/organisms/ErrorTile/ErrorTile'
import * as DashboardLoanUtils from 'src/products/loans/Dashboard/DashboardLoan.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'

type Props = StackScreenProps<MainStackParamList, 'Dashboard'>

const DashboardLoanContainer: FC<Props> = (props: Props) => {
  const {navigation, route} = props
  const {t} = useTranslation('DashboardLoan')

  return (
    <ErrorBoundary
      fallback={
        <TileListTemplate
          list={[
            <ErrorTile
              key="error-tile"
              testID="DashboardLoan-OverallErrorTile"
              transparentBackground={true}
              title={t('ErrorTileEverythingFailed')}
              onContactUs={(): void => {
                DashboardLoanUtils.onContactUs({navigation})
              }}
            />,
          ]}
          paddingSide="medium"
          listViewMarginTop={0}
        />
      }
      onError={(err: Error): void => {
        TrackAppEvent(
          LoanDashboardEvents.error_loan_dashboard_viewed,
          AppEvents.Category.LoanDashboard,
          {
            source: 'DashboardLoan failed in DashboardLoanContainer',
          },
        )
        DashboardLoanUtils.loanDashboardLogError(
          err,
          'render failed and threw an error caught by error boundary',
        )
      }}
    >
      <DashboardLoan navigation={navigation} route={route} />
    </ErrorBoundary>
  )
}

export {DashboardLoanContainer}
