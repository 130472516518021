import {ApolloError} from '@apollo/client'
import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import Log from 'src/lib/loggingUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {BusyModal} from 'src/products/MCU/AccountManagementV2/Modals/BusyModal'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {AccountManagementEvents} from 'src/lib/Analytics/app_events'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import {PushPage} from 'src/navigation/NavHelper'
import {useAccountManagementAccountsQuery} from 'src/products/MCU/AccountManagementV2/queries/AccountManagementAccounts/useAccountManagementAccountsQuery'

import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {AccountManagementViewV2} from 'src/products/MCU/AccountManagementV2/AccountManagementViewV2'
import {useAccountManagementBankLinking} from 'src/products/MCU/AccountManagementV2/useAccountManagementBankLinking'

type Props = StackScreenProps<MainStackParamList, 'AccountManagementV2'>

const AccountManagementV2: FC<Props> = ({navigation, route}) => {
  const {t} = useTranslation('AccountManagement')
  const isFromURA = route.params?.isFromURA
  // ID of any new ACH or debit card that was just added without an aggregator (plaid)
  const justAddedPaymentInstrumentId = route.params?.newSelectedPaymentInstrumentId
  // IDs of any new LinkedAccounts that were just added with plaid
  const newLinkedAccountIds = route.params?.newLinkedAccountIds
  const handleOnChooseNewAccountComplete = isFromURA
    ? route.params?.onChooseNewAccountComplete
    : undefined

  const {
    selectedData: paymentMethodsQueryData,
    loading: isPaymentMethodsQueryLoading,
    refetch: refetchPaymentMethods,
  } = useAccountManagementAccountsQuery({
    // this will make sure loading is true while re-fetching so we can show a spinner
    notifyOnNetworkStatusChange: true,
    onError: (e: ApolloError) => {
      Log.error(e, 'AccountManagementV2 failed to fetch payment methods query')
      snackbarErrorMessage('Failed to get bank accounts')
    },
  })

  // clears the deeplink data after the user arrives here
  useFocusEffect(
    useCallback(() => {
      async function asyncFocusEffect(): Promise<void> {
        const accountManagement = getDeeplink('account_management')
        if (accountManagement) {
          await clearDeeplink(accountManagement)
        }
      }
      void asyncFocusEffect()
    }, []),
  )

  // refresh data any time we arrive back on this screen
  useFocusEffect(
    useCallback(
      function refreshDataWhenArriveOnScreen() {
        void refetchPaymentMethods()
      },
      [refetchPaymentMethods],
    ),
  )

  const {addOrRelinkAccountForPrimary, alerts} = useAccountManagementBankLinking()

  usePageViewedAnalytics({
    eventName: AccountManagementEvents.account_management_page_viewed,
    eventCategory: AppEvents.Category.AccountManagement,
  })

  const handleOnEditBankDetails = useCallback(
    (accountId: string, mask: string): void => {
      TrackAppEvent(
        AccountManagementEvents.collect_bank_account_numbers,
        AppEvents.Category.AccountManagement,
      )

      PushPage(navigation, 'BankVerifyRoutingAndAccount', {
        linkedAccountIdToAssociateWith: accountId,
        accountNumberMask: mask,
        onSuccessRouteDestination: 'AccountManagementV2',
      })
    },
    [navigation],
  )

  /**
   * Refresh accounts data after bank linking with plaid is complete.
   */
  const refreshDataAfterBankLinking = async (): Promise<void> => {
    try {
      await refetchPaymentMethods()
    } catch (e) {
      Log.error(e, 'AccountManagementV2 refreshDataAfterBankLinking() failed')
      snackbarErrorMessage(t('failedToGetBankAccounts'))
    }
  }

  /**
   * Starts the account re-linking UX with an aggregator.
   */
  const handleOnRelinkAccount = (accountId: string): void => {
    addOrRelinkAccountForPrimary({
      accountToRelink: {id: accountId},
      // when re-linking is complete we will reload their data
      onChoosePrimaryAccountComplete: async (): Promise<void> => {
        TrackAppEvent(
          AccountManagementEvents.account_management_plaid_complete,
          AppEvents.Category.AccountManagement,
        )
        try {
          await refreshDataAfterBankLinking()
        } catch (e) {
          Log.error(
            e,
            'AccountManagementV2 handleOnRelinkAccount() failed in onRelinkAccountComplete()) ',
          )
          snackbarErrorMessage(t('failedToGetBankAccounts'))
        }
      },
    })
  }
  /**
   * Starts the UX to add a new account with an aggregator and then
   * select a primary account
   */
  const handleOnAddNewAccountForPrimary = (): void => {
    addOrRelinkAccountForPrimary({
      onChoosePrimaryAccountComplete: async ({linkedAccounts}): Promise<void> => {
        try {
          void handleOnChooseNewAccountComplete?.()
          await refreshDataAfterBankLinking()
        } catch (e) {
          Log.error(
            e,
            'AccountManagementV2 handleOnAddNewAccountForPrimary() failed in onChooseNewPrimaryAccountComplete()',
          )
          snackbarErrorMessage(t('failedToGetBankAccounts'))
        }
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'AccountManagementV2',
              params: {
                newLinkedAccountIds: linkedAccounts?.map((account) => account.id),
              },
            },
          ],
        })
      },
    })
  }

  return (
    <>
      {isPaymentMethodsQueryLoading ? <BusyModal /> : null}
      <AccountManagementViewV2
        isLoading={isPaymentMethodsQueryLoading}
        navigation={navigation}
        alerts={alerts}
        defaultLoanPaymentMethodId={paymentMethodsQueryData?.defaultLoanPaymentMethodId}
        debitCards={paymentMethodsQueryData?.debitCardPaymentMethods}
        achPaymentMethods={paymentMethodsQueryData?.achPaymentMethods}
        linkedAccountIdsJustAdded={newLinkedAccountIds}
        justAddedPaymentInstrumentId={justAddedPaymentInstrumentId}
        bankLinkedAccountsNeedAttention={paymentMethodsQueryData?.bankLinkedAccountsNeedAttention}
        activeLoanId={paymentMethodsQueryData?.activeLoanId}
        // eslint-disable-next-line react/jsx-handler-names
        addNewAccount={handleOnAddNewAccountForPrimary}
        onEditBankDetails={handleOnEditBankDetails}
        onRelinkAccount={handleOnRelinkAccount}
        onChooseNewAccountComplete={handleOnChooseNewAccountComplete}
      />
    </>
  )
}

export {AccountManagementV2}
