import React, {ReactElement, ReactNode, useCallback, useEffect, useState} from 'react'
import {StyleSheet, View, Keyboard} from 'react-native'

import Button, {
  LARGE_BUTTON_MAX_WIDTH,
  ButtonProps,
} from 'src/designSystem/components/atoms/Button/Button'
import {gapSizeMap, smallGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLinkProps, ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'

export type ButtonLockupPropsPrimary = Partial<ButtonProps> & {
  text: string
  disabled?: boolean
}

export type ButtonLockupPropsSecondary = Omit<ButtonLinkProps, 'containerStyle' | 'color'> & {
  buttonText: string
  color?: 'textPrimary' | 'link' | NamedColors
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  hideIcon?: boolean
}

export type ButtonLockupPropsInline = Omit<ButtonLinkProps, 'containerStyle' | 'color'> & {
  buttonText: string
  text: string
  disabled?: boolean
  loading?: boolean
  hideIcon?: boolean
}

export type ButtonLockupTypes = 'singleButton' | 'doubleButton' | 'inlineLink' | 'interstitial'

export type ButtonLockupPropSingleButton = {
  type: 'singleButton'
  primary: ButtonLockupPropsPrimary
}

export type ButtonLockupPropDoubleButton = {
  type: 'doubleButton'
  primary: ButtonLockupPropsPrimary
  secondary: ButtonLockupPropsSecondary
}

export type ButtonLockupPropInlineLink = {
  type: 'inlineLink'
  primary: ButtonLockupPropsPrimary
  inline: ButtonLockupPropsInline
}

export type CheckBoxProps = {
  text: string | ReactElement
  onCheck?: (checkValue: boolean) => void
  checked?: boolean
  disabled?: boolean
  testID?: string
}

export type ButtonLockupPropInterstitial = {
  type: 'interstitial'
  primary: ButtonLockupPropsPrimary
  checkBox: CheckBoxProps
  extraCheckBox?: CheckBoxProps
}

export type ButtonLockupProps =
  | ButtonLockupPropSingleButton
  | ButtonLockupPropDoubleButton
  | ButtonLockupPropInlineLink
  | ButtonLockupPropInterstitial

const ALTERNATIVE_VIEW_HEIGHT = 50
const SECONDARY_TOP_MARGIN = smallGap

const primaryButton = (props: ButtonLockupProps, disabled = false): ReactNode => {
  const {text, ...rest} = props.primary
  return (
    <Button mode="primary" disabled={disabled} width={'100%'} size={'large'} {...rest}>
      {text}
    </Button>
  )
}

const ButtonOnTop: React.FC<ButtonLockupProps> = (props) => {
  const [keyboardOpen, setKeyboardOpen] = useState(false)
  useEffect(() => {
    const subscriptionDidShow = Keyboard.addListener('keyboardDidShow', _keyboardDidShow)
    const subscriptionDidHide = Keyboard.addListener('keyboardDidHide', _keyboardDidHide)

    // cleanup function
    return () => {
      subscriptionDidShow.remove()
      subscriptionDidHide.remove()
    }
  }, [])

  const _keyboardDidShow = (): void => {
    setKeyboardOpen(true)
  }

  const _keyboardDidHide = (): void => {
    setKeyboardOpen(false)
  }

  const alternativeActionViewHeight =
    keyboardOpen && props.type === 'singleButton' ? smallGap : ALTERNATIVE_VIEW_HEIGHT

  let auxiliary: JSX.Element | undefined = undefined

  if (props.type === 'doubleButton' || props.type === 'inlineLink') {
    const buttonLinkSize = props.type === 'doubleButton' ? 'medium' : props.inline?.size
    const {buttonText, icon, hideIcon, ...rest} =
      props.type === 'doubleButton' ? props.secondary : props.inline

    const buttonLink = (
      <ButtonLink
        size={buttonLinkSize ?? 'small'}
        icon={hideIcon ? undefined : (icon ?? 'arrowRight')}
        color={'link'}
        {...rest}
      >
        {buttonText}
      </ButtonLink>
    )

    switch (props.type) {
      case 'doubleButton':
        auxiliary = <View style={styles.auxiliaryView}>{buttonLink}</View>
        break
      case 'inlineLink':
        auxiliary = (
          <Box direction="row" gap="tiny" wrap="wrap" justify="center" marginTop="little">
            <PFText textAlign={'center'} textProps={{numberOfLines: 1}} variant="p_sm">
              {props.inline.text}
            </PFText>
            {buttonLink}
          </Box>
        )
    }
  }

  return (
    <>
      {props.primary ? (
        <View style={styles.topPrimaryButtonView}>
          {primaryButton(props, props.primary.disabled)}
        </View>
      ) : null}
      <View style={{...styles.buttonOnTop, height: alternativeActionViewHeight}}>{auxiliary}</View>
    </>
  )
}

const ALTERNATIVE_CHECKBOX_HEIGHT = 82
const ALTERNATIVE_CHECKBOX_HEIGHT_DOUBLE = 121
const ButtonWithCheckbox: React.FC<ButtonLockupPropInterstitial> = (props) => {
  const {checkBox, extraCheckBox, type} = props
  const [internalCheck, setInternalCheck] = useState<boolean>(false)
  const [internalCheckExtraCheckBox, setInternalCheckExtraCheckBox] = useState<boolean>(false)

  const onPress = useCallback(
    (newCheckedValue: boolean) => {
      checkBox.onCheck?.(newCheckedValue)
      setInternalCheck(newCheckedValue)
    },
    [checkBox],
  )
  const onPressExtraCheckBox = useCallback(
    (newCheckedValue: boolean) => {
      extraCheckBox?.onCheck?.(newCheckedValue)
      setInternalCheckExtraCheckBox(newCheckedValue)
    },
    [extraCheckBox],
  )
  if (type !== 'interstitial') {
    return null
  }

  const renderCheckbox = (
    selectedCheckBox: CheckBoxProps,
    isChecked: boolean,
    onChange: (value: boolean) => void,
    testID?: string,
  ): ReactNode => {
    const disabled: boolean =
      selectedCheckBox.disabled === undefined ? false : selectedCheckBox.disabled

    return (
      <View style={{marginTop: smallGap}}>
        <PFCheckbox
          size={'Small'}
          checked={isChecked}
          onPress={onChange}
          disabled={disabled}
          testID={testID}
        >
          {selectedCheckBox.text}
        </PFCheckbox>
      </View>
    )
  }

  const checked = checkBox.checked === undefined ? internalCheck : checkBox.checked
  const checkedExtraCheckBox =
    extraCheckBox?.checked === undefined ? internalCheckExtraCheckBox : extraCheckBox?.checked

  const disablePrimaryButton = extraCheckBox ? !checked || !checkedExtraCheckBox : !checked
  return (
    <View style={styles.contentView}>
      <View style={extraCheckBox ? styles.checkboxViewDouble : styles.checkboxView}>
        {renderCheckbox(checkBox, checked, onPress, checkBox.testID)}
        {extraCheckBox
          ? renderCheckbox(
              extraCheckBox,
              checkedExtraCheckBox,
              onPressExtraCheckBox,
              checkBox.testID,
            )
          : undefined}
      </View>
      <View style={styles.bottomPrimaryButtonView}>
        {primaryButton(props, disablePrimaryButton)}
      </View>
    </View>
  )
}

const ButtonLockup: React.FC<ButtonLockupProps> = (props) => {
  return (
    <View style={styles.containerView}>
      {props.type === 'interstitial' ? (
        <ButtonWithCheckbox {...props} />
      ) : (
        <ButtonOnTop {...props} />
      )}
    </View>
  )
}

export default ButtonLockup

const styles = StyleSheet.create({
  auxiliaryView: {
    marginTop: SECONDARY_TOP_MARGIN,
  },
  bottomPrimaryButtonView: {
    alignItems: 'center',
    marginBottom: smallGap,
  },
  buttonOnTop: {
    alignItems: 'center',
  },
  checkboxView: {
    height: ALTERNATIVE_CHECKBOX_HEIGHT,
    paddingTop: smallGap,
  },
  checkboxViewDouble: {
    height: ALTERNATIVE_CHECKBOX_HEIGHT_DOUBLE,
    paddingTop: smallGap,
  },
  containerView: {
    paddingHorizontal: gapSizeMap['large'],
  },
  contentView: {
    alignSelf: 'center',
    maxWidth: LARGE_BUTTON_MAX_WIDTH,
    width: '100%',
  },
  topPrimaryButtonView: {
    alignItems: 'center',
    marginTop: smallGap,
  },
})
