import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {Text} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanUnmetStateRequirementsTileProps = {
  onApplyAgain: ButtonAction
  onViewStateDatabasePolicy: ButtonAction
  isBusy?: boolean
  testID?: string
}

/**
 * A tile to inform the user that their loan couldn't be completed due to
 * unmet state requirements.
 * @example <LoanUnmetStateRequirementsTile onApplyAgain={() => {}} onViewStateDatabasePolicy={() => {}} />
 */
const LoanUnmetStateRequirementsTile: React.FC<LoanUnmetStateRequirementsTileProps> = ({
  onApplyAgain: handleApplyAgain,
  onViewStateDatabasePolicy,
  isBusy,
  testID,
}) => {
  const {t} = useTranslation('DashboardLoanUnmetStateRequirementsTile')
  return (
    <BasicTile padding={0} testID={testID}>
      <Box paddingHorizontal="medium" paddingVertical="small">
        <PFText variant="h3" textAlign="left">
          {t('WeCouldNotCompleteLoan')}
        </PFText>
        <Box marginTop="small" padding={0}>
          <Trans t={t} i18nKey="UnmetRequirementsExplanation" parent={Text}>
            <PFText variant="p">
              Possible adheres to state lending laws which can be found in our
            </PFText>
            <PFText
              variant="p"
              color="link"
              onPress={onViewStateDatabasePolicy}
              testID="LoanUnmetStateRequirements-StateDBPolicyLink"
            >
              State Database Policy
            </PFText>
            <PFText variant="p">
              . We can&apos;t complete your loan based on unmet state database requirements.
            </PFText>
            <PFText variant="p">
              Please feel free to apply again once the requirements in this policy are met.
            </PFText>
          </Trans>
        </Box>

        <Box width="100%" align="center" marginTop="medium">
          <Button
            mode="primary"
            size="medium"
            width="100%"
            onPress={handleApplyAgain}
            testID="LoanUnmetStateRequirements-ReapplyBtn"
            loading={isBusy}
            disabled={isBusy}
          >
            {t('ReapplyForLoan')}
          </Button>
        </Box>
      </Box>
    </BasicTile>
  )
}

export default LoanUnmetStateRequirementsTile
