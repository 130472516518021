import React from 'react'

import AppPossible from 'src/nav/AppPossible'
import DeeplinkContainer from 'src/containers/DeeplinkContainer'
import ErrorBoundary from 'src/ErrorBoundary'

/**
 * Root component for the web app.
 */
const AppWrapper: React.FC = () => (
  <ErrorBoundary>
    <DeeplinkContainer>
      <AppPossible />
    </DeeplinkContainer>
  </ErrorBoundary>
)

export {AppWrapper}
