import {Consumer} from '@possible/cassandra'
import {OnboardingCurrentModule} from '@possible/cassandra/src/types/types.mobile.generated'
import {ParamListBase, PathConfigMap, RouteProp, useFocusEffect} from '@react-navigation/native'
import {
  createStackNavigator,
  StackNavigationOptions,
  StackNavigationProp,
} from '@react-navigation/stack'
import {isArray} from 'lodash'
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import {AppState, AppStateStatus} from 'react-native'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {headerButtonOptionFactory} from 'src/flows/modules/buttons'
import {
  CurrentModuleTypes,
  ModuleControlProps,
  ModuleDefinitionMultiDirectedNode,
  ModuleDefinitionMultiNode,
  ModuleDefinitionNode,
  ModuleDefinitions,
  ModuleInitorsMapType,
  ModuleList,
  ModuleMultiNodeDirector,
  ModuleNavigationMethods,
  ModuleScreen,
  StepCompleteParams,
} from 'src/flows/modules/types'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {onboardingSentToWeb} from 'src/lib/onboarding/slice'
import {MainStackParamList, MainStackScreenProps} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'

type EndModulesMapType<ParamList extends ParamListBase> = Partial<
  Record<keyof ParamList, keyof MainStackParamList>
>
type BreakModuleStepMapType<ParamList extends ParamListBase> = Array<keyof ParamList>
type ModuleStepOrderMapType<ParamList extends ParamListBase> = Partial<{
  [key in keyof ParamList]: (keyof ParamList)[]
}>

// these lines are just a way for us to extract the return type of createStackNavigator
// it turns out it's pretty difficult to get the return type of a generic function
// in typescript.
class Wrapper<T extends ParamListBase> {
  wrapped() {
    return createStackNavigator<T>()
  }
}
type CSNInternalType<ParamList extends ParamListBase> = ReturnType<Wrapper<ParamList>['wrapped']>
type TypedStack<ParamList extends ParamListBase> = CSNInternalType<ParamList>

type ModuleStackFactoryScope<ParamList extends ParamListBase> = {
  moduleDefinitions: ModuleDefinitions<ParamList, keyof ParamList>
  stack: TypedStack<ParamList>
  screens: PathConfigMap<ParamList>
  endModules: EndModulesMapType<ParamList>
  breakModuleSteps: BreakModuleStepMapType<ParamList>
  moduleStepOrder: ModuleStepOrderMapType<ParamList>
  moduleInitors: ModuleInitorsMapType<ParamList>
  screenComponents: React.ReactElement[]
}

const pushPreviousModuleToRoutes = <ParamList extends ParamListBase>(
  routes: {name: keyof ParamList; params?: ParamList[keyof ParamList]}[],
  pModule: keyof ParamList,
  scope: ModuleStackFactoryScope<ParamList>,
) => {
  const {moduleDefinitions, moduleInitors} = scope

  for (const moduleName in moduleDefinitions) {
    if (pModule === moduleName) {
      const initor = moduleInitors[moduleName]
      const step = initor?.()
      if (step) {
        if (typeof step === 'string') {
          routes.push({name: step})
        } else {
          routes.push({name: step.name, params: step.params as ParamList[keyof ParamList]})
        }
      } else {
        routes.push({name: moduleName})
      }
      break
    }
  }
}

/**
 * The result of this function will initialize the navigation stack history based on whether or not
 * the steps in the flow are determined to be completed already or not.
 * @param scope The module factory scope
 * @returns A function to initialize the flow history and navigate to the latest un-finished step in the flow.
 */
const beginFlow = <ParamList extends ParamListBase>(scope: ModuleStackFactoryScope<ParamList>) => {
  return async (
    navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>,
    getCurrentModule: () => Promise<CurrentModuleTypes>,
    force = false,
  ) => {
    const {breakModuleSteps} = scope
    try {
      const current = await getCurrentModule()

      Log.info('[MPO] beginFlow current module', current?.currentModule)

      const routeName = scope.endModules[current.currentModule]
      if (routeName) {
        navigation.reset({index: 0, routes: [{name: routeName}]})
        return
      }

      const pendingRoutes: {name: keyof ParamList; params?: ParamList[keyof ParamList]}[] = []
      let routes: {name: keyof ParamList; params?: ParamList[keyof ParamList]}[] = []

      const {previousModules} = current.previousModules
      for (const pModule of previousModules) {
        pushPreviousModuleToRoutes(pendingRoutes, pModule, scope)
      }

      for (const r of pendingRoutes) {
        if (breakModuleSteps.includes(r.name)) {
          routes = []
        }
        routes.push(r)
      }

      const navRoutes = navigation.getState().routes[0].state?.routes
      const currentRoutes: {name: keyof ParamList}[] = []
      if (navRoutes) {
        for (const r of navRoutes) {
          currentRoutes.push({name: r.name})
        }
      }

      // if the current routing history matches the new routing history
      // we don't need to call navigation.reset
      let inOrder = 0
      if (currentRoutes) {
        for (let i = 0; i < routes.length; ++i) {
          const lookingForRoute = routes[i]
          for (let j = i; j < currentRoutes.length; j++) {
            const thisRoute = currentRoutes[j]

            if (lookingForRoute.name === thisRoute.name && j >= i) {
              inOrder++
              break
            }
          }
        }
      }

      if (force || inOrder < routes.length) {
        navigation.reset({
          index: routes.length - 1,
          // @ts-expect-error
          routes: routes,
        })
      }
    } catch (e) {
      Log.error(e, '[MPO] Could not (re)create the stack')
    }
  }
}

const getTargetModule = async (
  nextModule: ModuleList | undefined,
  navigation: StackNavigationProp<MainStackParamList, any>,
  navigationMethods: ModuleNavigationMethods,
  skipUpdatingMPOStateInDB?: boolean,
) => {
  const {getCurrentModule, moveToNextModule, begin} = navigationMethods

  const moduleInfo = await getCurrentModule()
  if (nextModule) {
    if (moduleInfo.nextModulesAllowed.nextModulesAllowed.length > 1) {
      const nma = moduleInfo.nextModulesAllowed.nextModulesAllowed
      // @ts-expect-error
      if (!nma.includes(nextModule)) {
        // we are out of sync, let's rebuild our stack
        Log.warn('[MPO] Out of sync. Rebuilding stack')
        await begin(navigation.getParent(), getCurrentModule)
        return undefined
      }
    }

    if (!skipUpdatingMPOStateInDB) {
      await moveToNextModule(nextModule)
    }
    const newModuleInfo = await getCurrentModule()
    return newModuleInfo.currentModule
  } else {
    const nma = moduleInfo.nextModulesAllowed.nextModulesAllowed[0]
    if (!skipUpdatingMPOStateInDB) {
      await moveToNextModule(nma)
    }
    const newModuleInfo = await getCurrentModule()
    return newModuleInfo.currentModule
  }
}

const navigateToNextModule = <ParamList extends ParamListBase>(
  navigation: any,
  scope: ModuleStackFactoryScope<ParamList>,
  navigationMethods: ModuleNavigationMethods,
) => {
  const {endModules, moduleInitors} = scope
  const {begin, getCurrentModule} = navigationMethods

  const navToTarget = (targetModule: ModuleList) => {
    if (endModules[targetModule]) {
      navigation.reset({index: 0, routes: [{name: endModules[targetModule]}]})
    } else {
      const initor = moduleInitors[targetModule]
      let step = initor?.()
      if (!step) {
        step = targetModule as Extract<keyof ParamList, string>
      }

      // on android the device back button allows users to step through the stack
      // history. so let's reset that to one screen if they aren't allowed to go back
      if (scope.breakModuleSteps.includes(targetModule)) {
        navigation.reset({index: 0, routes: [typeof step === 'object' ? step : {name: step}]})
      } else {
        navigation.navigate(step)
      }
    }
  }

  /**
   * skipUpdatingMPOStateInDB is for cases where the MPO state will be updated by the BE, not the FE
   * In this case it is not necessary to call `moveToNextModule` to update the MPO state again
   */
  return async (nextModule?: ModuleList, skipUpdatingMPOStateInDB?: boolean) => {
    try {
      const targetModule = await getTargetModule(
        nextModule,
        navigation,
        navigationMethods,
        skipUpdatingMPOStateInDB,
      )
      if (!targetModule) {
        throw new Error('Unable to find target module')
      }

      Log.info('[MPO] navigateToNextModule targetModule', targetModule)

      navToTarget(targetModule)
    } catch (e) {
      if (Consumer.errors.containsError(e, Consumer.errors.ErrorCodes.OnboardingModuleIncomplete)) {
        ShowException(Consumer.errors.ErrorCodes.OnboardingModuleIncomplete)
        return
      }

      Log.warn(e, '[MPO] Error moving to the next module. Resetting the stack.')

      begin(navigation, getCurrentModule, true).catch((beginError) => {
        Log.error(
          beginError,
          '[MPO] Error moving to the next module or resetting the stack. End of the line.',
        )
        if (isArray(beginError)) {
          throw (beginError as Error[])[0]
        }

        throw beginError
      })
    }
  }
}

const calculateOptions =
  <ParamList extends ParamListBase>(mod: ModuleScreen<ParamList, keyof ParamList>) =>
  (optionProps: {
    route: RouteProp<ParamList, NonNullable<Extract<keyof ParamList, string>>>
    navigation: StackNavigationProp<MainStackParamList, any>
  }): StackNavigationOptions => {
    const {navigation} = optionProps

    const headerLeftButton = mod.headerLeftButton ?? (navigation.canGoBack() ? 'Back' : 'Logout')

    const options =
      typeof mod.options === 'function' ? mod.options(optionProps) : (mod.options ?? {})
    options.title = options.title ?? ''
    options.headerLeft =
      options.headerLeft ?? headerButtonOptionFactory(headerLeftButton, navigation)
    options.headerRight =
      options.headerRight ?? headerButtonOptionFactory(mod.headerRightButton ?? 'None', navigation)
    options.gestureEnabled = headerLeftButton === 'Back'
    return options
  }

const ModuleLoader: React.FC = () => {
  return <Loading size="large" type="loader0" />
}

const useFocusNavigation = <ParamList extends ParamListBase>(
  moduleName: keyof ParamList,
  stepName: keyof ParamList | undefined,
  navigation: StackNavigationProp<ParamList, keyof ParamList>,
  navigationMethods: ModuleNavigationMethods,
  scope: ModuleStackFactoryScope<ParamList>,
) => {
  const {getCurrentModule, begin} = navigationMethods
  const {moduleStepOrder} = scope

  const [moduleInfo, setModuleInfo] = useState<OnboardingCurrentModule | undefined>(undefined)

  const focusEffectCallback = useCallback(() => {
    const handleIt = async () => {
      try {
        // refresh our nextModulesAllowed
        const result = await getCurrentModule()
        if (stepName) {
          if (!moduleStepOrder[moduleName]) {
            moduleStepOrder[moduleName] = []
          }

          if (!moduleStepOrder[moduleName]?.includes(stepName)) {
            moduleStepOrder[moduleName]?.push(stepName)
          }
        } else {
          if (result.currentModule !== moduleName) {
            begin(navigation.getParent(), getCurrentModule)
          }
        }
        setModuleInfo(result)
      } catch (e) {
        ShowException(e)
      }
    }

    handleIt()
  }, [begin, getCurrentModule, moduleName, moduleStepOrder, navigation, stepName])

  useFocusEffect(focusEffectCallback)

  return {
    previousModules: moduleInfo?.previousModules.previousModules ?? [],
    nextModulesAllowed: moduleInfo?.nextModulesAllowed.nextModulesAllowed ?? [],
  }
}

const useBackNavigation = <ParamList extends ParamListBase>(
  moduleName: keyof ParamList,
  stepName: keyof ParamList | undefined,
  previousModules: ModuleList[],
  navigationMethods: ModuleNavigationMethods,
  navigation: StackNavigationProp<ParamList, keyof ParamList>,
  scope: ModuleStackFactoryScope<ParamList>,
) => {
  const {moveToPreviousModule, getCurrentModule, begin} = navigationMethods
  const {moduleStepOrder} = scope

  const handleBack = useCallback(async () => {
    try {
      const result = await moveToPreviousModule()
      if (!result) {
        throw new Error('Failure to move to previous module')
      }
    } catch (e) {
      Log.warn(e, "[MPO] Couldn't go to previous module. Resetting the stack.")
      begin(navigation as any, getCurrentModule, true)
    }
  }, [begin, getCurrentModule, moveToPreviousModule, navigation])

  const focusEffect = useCallback(() => {
    const listener = async (event) => {
      if (event.data.action.type === 'GO_BACK') {
        if (stepName) {
          const index = moduleStepOrder[moduleName]?.indexOf(stepName) ?? -1
          if (index !== -1) {
            moduleStepOrder[moduleName]?.splice(index, 1)
            if (moduleStepOrder[moduleName]?.length === 0) {
              Log.log('[MPO] No more module steps, moving to previous module')

              await handleBack()
            }
          }
        } else {
          if (previousModules.includes(moduleName as ModuleList)) {
            await handleBack()
          }
        }
      }
    }

    navigation.addListener('beforeRemove', listener)
    return () => navigation.removeListener('beforeRemove', listener)
  }, [navigation, stepName, moduleStepOrder, moduleName, handleBack, previousModules])

  useFocusEffect(focusEffect)
}

function createScreenComponent<ParamList extends ParamListBase>(
  scope: ModuleStackFactoryScope<ParamList>,
  navigationMethods: ModuleNavigationMethods,
  mod: ModuleScreen<ParamList, keyof ParamList>,
  director?: ModuleMultiNodeDirector<ParamList, keyof ParamList>,
) {
  const {stack} = scope

  const ModuleStepWrapper: React.FC<
    React.PropsWithChildren & {
      navigation: StackNavigationProp<ParamList, keyof ParamList>
      moduleName: Extract<keyof ParamList, string>
    }
  > = (props) => {
    const {children, navigation} = props

    const {previousModules, nextModulesAllowed} = useFocusNavigation(
      mod._moduleName!,
      mod._name,
      navigation,
      navigationMethods,
      scope,
    )
    useBackNavigation(
      mod._moduleName!,
      mod._name,
      previousModules,
      navigationMethods,
      navigation,
      scope,
    )

    const moduleControl: ModuleControlProps = useMemo(() => {
      const onModuleComplete = navigateToNextModule(props.navigation, scope, navigationMethods)

      const onStepComplete = async (params: StepCompleteParams) => {
        const next = director?.((mod._name as Extract<keyof ParamList, string>)!)
        if (next) {
          const screen = typeof next === 'string' ? next : next.name
          const screenParams = typeof next === 'string' ? {...params} : {...next.params, ...params}
          if (scope.breakModuleSteps.includes(screen)) {
            props.navigation.reset({index: 0, routes: [{name: screen, params: screenParams}]})
          } else {
            // @ts-expect-error
            props.navigation.navigate(screen, screenParams)
          }
        } else {
          // there may in the future be some need to pass which module to
          // navigate to here. but for now there are few branching modules.
          await onModuleComplete()
        }
      }

      return {
        onStepComplete,
        onModuleComplete,
        moduleName: mod._moduleName as ModuleList, // why do I need to do this?
        setLeftHeaderButton: (buttonType) =>
          props.navigation.setOptions({
            headerLeft: headerButtonOptionFactory(buttonType, props.navigation as any),
            gestureEnabled: buttonType === 'Back',
          }),
        setRightHeaderButton: (buttonType) =>
          props.navigation.setOptions({
            headerRight: headerButtonOptionFactory(buttonType, props.navigation as any),
          }),
      } as ModuleControlProps
    }, [props.navigation])

    return (
      <>
        {React.Children.map(children, (child) => {
          if (child) {
            return React.cloneElement(child as ReactElement, {
              ...moduleControl,
              nextModulesAllowed,
            })
          }

          return null
        }) ?? null}
      </>
    )
  }

  return (
    //@ts-expect-error @chavi please fix this typescript error. I can't tell what the code is suppose to be doing
    <stack.Screen key={mod._name as string} name={mod._name!} options={calculateOptions(mod)}>
      {(p: any) => (
        <ModuleStepWrapper navigation={p.navigation} moduleName={mod._name!}>
          <mod.Component {...p} />
        </ModuleStepWrapper>
      )}
    </stack.Screen>
  )
}

const ModuleStackComponentFactory = <
  ParamList extends ParamListBase,
  FlowName extends keyof MainStackParamList,
>(
  stack: TypedStack<ParamList>,
  screenComponents: React.ReactElement[],
  getCurrentModule: () => Promise<CurrentModuleTypes>,
  begin: (
    navigation: StackNavigationProp<MainStackParamList, any>,
    getCurrentModule: () => Promise<CurrentModuleTypes>,
    force?: boolean,
  ) => Promise<void>,
): React.FC<MainStackScreenProps<FlowName>> => {
  const MainStackComponent: React.FC<MainStackScreenProps<FlowName>> = (props) => {
    const {navigation} = props

    const dispatch = usePfDispatch()

    useEffect(() => {
      begin(navigation, getCurrentModule, true)
    }, [navigation])

    // if we come back from the background, we need to rebuild the routing
    // history and re-navigate to the 'current' module. This is especially
    // necessary on android where you will be sent to the web app at certain
    // points.
    // OR
    // if we have sent the user to web we should completely restart from the
    // DashboardLoader in the event they have already accepted a loan.
    const handleAppStateChange = useCallback(
      (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active') {
          begin(navigation, getCurrentModule)
          dispatch(onboardingSentToWeb(false))
        }
      },
      [dispatch, navigation],
    )

    useEffect(() => {
      const subscription = AppState.addEventListener('change', handleAppStateChange)
      return () => subscription.remove()
    }, [handleAppStateChange])

    return (
      <stack.Navigator
        screenOptions={{
          gestureEnabled: true,
          headerTransparent: true,
          headerShadowVisible: false,
          headerBackgroundContainerStyle: {
            backgroundColor: 'white',
          },
        }}
      >
        <stack.Screen name="ModulesLoading" component={ModuleLoader} options={{title: ''}} />
        {screenComponents}
      </stack.Navigator>
    )
  }

  return MainStackComponent
}

const createScreensForLinearModule = <ParamList extends ParamListBase>(
  mod: ModuleDefinitionMultiNode<ParamList, keyof ParamList>,
  moduleName: Extract<keyof ParamList, string>,
  scope: ModuleStackFactoryScope<ParamList>,
  navigationMethods: ModuleNavigationMethods,
) => {
  const {screenComponents, screens} = scope

  for (let i = 0; i < mod.steps.length; ++i) {
    const step = mod.steps[i]
    const nstep = i + 1 < mod.steps.length ? mod.steps[i + 1] : undefined

    step._moduleName = moduleName
    step._name = step.name

    // @ts-expect-error
    screens[step.name] = step.screen

    appendPotentialBreakModuleStep(scope, step)

    const director = nstep ? () => nstep.name : undefined

    screenComponents.push(createScreenComponent(scope, navigationMethods, step, director))
  }
}

const createScreensForDirectedModule = <ParamList extends ParamListBase>(
  mod: ModuleDefinitionMultiDirectedNode<ParamList, keyof ParamList>,
  moduleName: Extract<keyof ParamList, string>,
  scope: ModuleStackFactoryScope<ParamList>,
  navigationMethods: ModuleNavigationMethods,
) => {
  const {screenComponents, screens, moduleInitors} = scope

  moduleInitors[moduleName] = mod.init

  for (const stepPair of Object.entries(mod.steps)) {
    const [stepName, step] = stepPair

    if (!step) {
      continue
    }

    step._moduleName = moduleName
    step._name = stepName as Extract<keyof ParamList, string> // why?

    appendPotentialBreakModuleStep(scope, step)

    // @ts-expect-error
    screens[stepName] = step.screen

    screenComponents.push(createScreenComponent(scope, navigationMethods, step, mod.director))
  }
}

const appendPotentialBreakModuleStep = <ParamList extends ParamListBase>(
  scope: ModuleStackFactoryScope<ParamList>,
  mod: ModuleScreen<ParamList>,
) => {
  if (
    mod._name &&
    mod.headerLeftButton !== undefined &&
    !['Back', 'NoneNoBreak'].includes(mod.headerLeftButton)
  ) {
    scope.breakModuleSteps.push(mod._name)
  }
}

/**
 * Use this to create a flow that maps to a module query/mutation pairing.
 * @param getCurrentModule A method to retrieve the CurrentModule from the backend.
 * @param moveToNextModule A method to move to the next module on the backend.
 * @param moveToPreviousModule A method to move back by one module.
 * @param moduleDefinitions The module definitions which will be used to construct this nav stack.
 * @returns
 */
export const ModuleStackFactory = <ParamList extends ParamListBase>(
  getCurrentModule: ModuleNavigationMethods['getCurrentModule'],
  moveToNextModule: ModuleNavigationMethods['moveToNextModule'],
  moveToPreviousModule: ModuleNavigationMethods['moveToPreviousModule'],
  moduleDefinitions: ModuleDefinitions<ParamList, keyof ParamList>,
) => {
  const scope: ModuleStackFactoryScope<ParamList> = {
    moduleDefinitions,
    stack: createStackNavigator<ParamList>(),
    screens: {},
    endModules: {},
    breakModuleSteps: [],
    moduleStepOrder: {},
    moduleInitors: {},
    screenComponents: [],
  }

  const begin = beginFlow(scope)
  const navigationMethods: ModuleNavigationMethods = {
    getCurrentModule,
    moveToNextModule,
    moveToPreviousModule,
    begin: begin,
  }

  const {stack, screens, endModules, screenComponents} = scope

  for (const modPair of Object.entries(moduleDefinitions)) {
    const [moduleName, mod] = modPair as [
      Extract<keyof ParamList, string>,
      ModuleDefinitionNode<ParamList, keyof ParamList>,
    ]

    if (!mod.type) {
      mod.type = 'Single'
    }

    switch (mod.type) {
      case 'Single':
        mod._name = moduleName
        mod._moduleName = moduleName

        // @ts-expect-error
        screens[moduleName] = mod.screen

        appendPotentialBreakModuleStep(scope, mod)

        scope.screenComponents.push(createScreenComponent(scope, navigationMethods, mod))
        break
      case 'MultiDirected':
        createScreensForDirectedModule(mod, moduleName, scope, navigationMethods)
        break
      case 'MultiLinear':
        createScreensForLinearModule(mod, moduleName, scope, navigationMethods)
        break
      case 'End':
        endModules[moduleName] = mod.navigateTo
        break
    }
  }

  const component = ModuleStackComponentFactory(stack, screenComponents, getCurrentModule, begin)

  return {
    component,
    stackNavigator: stack,
    linkingOptions: {
      prefixes: [],
      config: {
        screens,
      },
    },
  }
}
