import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'
import {PromoTile, PromoTileProps} from 'src/designSystem/components/organisms/PromoTile/PromoTile'
import {StackedButtonsAction} from 'src/designSystem/components/molecules/StackedButtons/StackedButtons'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

export type LoanEligibleToApplyProps = {
  prequalifiedAmount?: number
  onApplyForNewLoan: ButtonAction
  isLoading?: boolean
  testID?: string
}

/**
 * A tile to inform the user that they're prequalified for a loan. Includes an
 * action button to apply for a new loan.
 * @example <LoanEligibleToApplyTile prequalifiedAmount={120} onApplyForNewLoan={() => {}}
 */
export const LoanEligibleToApplyTile: React.FC<LoanEligibleToApplyProps> = ({
  prequalifiedAmount,
  onApplyForNewLoan,
  isLoading: isBusy,
  testID,
}) => {
  const {t} = useTranslation('DashboardLoanEligibleToApplyTile')
  const primaryAction: StackedButtonsAction = {
    text: t('ApplyForNewLoanBtn'),
    onPress: onApplyForNewLoan,
    testID: 'LoanEligibleToApplyTile-ApplyForNewLoanBtn',
    loading: isBusy,
    disabled: isBusy,
  }
  const titleText = prequalifiedAmount
    ? t('PrequalfiedAmountTitle', {amount: prequalifiedAmount})
    : t('ApplyForLoanTitle')
  const props: PromoTileProps = {
    testID,
    titleText,
    contentText: (
      <Box marginTop={8}>
        <PFText variant="p">{t('FastAffordableFunds')}</PFText>
        <PFText variant="p">{t('PayInInstallments')}</PFText>
        <PFText variant="p">{t('BuildCreditHistory')}</PFText>
      </Box>
    ),
    image: <SvgIcon name={'largeArrowUpRight'} colorVariant={'success'} size={'large'} />,
    imageWidth: 100,
    primaryAction,
  }
  return <PromoTile {...props} />
}
