import React, {Component, ReactElement, ReactNode} from 'react'
import {Dimensions, StyleSheet, View, ViewStyle, ImageSourcePropType} from 'react-native'
import MobileDetect from 'mobile-detect'
import {KeyboardAwareScrollViewProps} from 'react-native-keyboard-aware-scroll-view'
import {SafeAreaView} from 'react-native-safe-area-context'

import {pageMarginHorizontal, smallGap} from 'src/designSystem/layout'
import ScrollViewBase, {
  ScrollViewBaseType,
} from 'src/designSystem/components/molecules/ScrollViewBase/ScrollViewBase'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PageImage from 'src/designSystem/components/molecules/PageImage/PageImage'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import ButtonLockup, {
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {isDeviceWeb} from 'src/lib/utils/platform'
import Box from 'src/designSystem/components/atoms/Box/Box'
const md = new MobileDetect(window.navigator.userAgent)
const initialScreenSize = window.innerHeight

const availableHeight = (): number => {
  return isDeviceWeb() ? window.innerHeight : Math.round(Dimensions.get('window').height)
}

type ButtonLinkProps = {
  text: string
  onPress: () => void
}

type Props = React.PropsWithChildren & {
  testID?: string
  title?: string
  subPage?: boolean
  description?: ReactNode
  justifyContent?: ViewStyle
  collapseImage?: boolean
  image?: ImageSourcePropType
  logo?: ReactElement
  graphicPosition?: 'top' | 'bottom'
  graphicStyle?: ViewStyle
  learnMore?: ButtonLinkProps
  footerDisclaimer?: ReactElement
  actionBlock?: ButtonLockupProps
  keyboardAware?: KeyboardAwareScrollViewProps
  keyboardShouldPersistTaps?: ScrollViewBaseType['keyboardShouldPersistTaps']
}

type State = {
  screenHeight: number
  keyboardIsOpen: boolean
  isLandscape: boolean
  buttonViewHeight?: number
  scrollViewContentHeight?: number
}

/**
 * GenericNonModalTemplate is a template for creating a non-modal page.
 * @deprecated Use the `Page` organism instead
 */
class GenericNonModalTemplate extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      screenHeight: availableHeight(),
      keyboardIsOpen: false,
      isLandscape: false,
    }
  }

  _resizeListener = (): void => {
    const keyboardIsOpen = window.innerHeight < initialScreenSize
    const isLandscape = screen.height < screen.width
    this.setState({keyboardIsOpen, isLandscape})
  }

  componentDidMount(): void {
    if (isDeviceWeb() && md.mobile()) {
      //this section is to make the bottom buttons non sticky when the keyboard is open.
      //tested on Android
      window.addEventListener('resize', this._resizeListener, false)
    }
  }

  componentWillUnmount(): void {
    if (isDeviceWeb() && md.mobile()) {
      window.removeEventListener('resize', this._resizeListener)
    }
  }

  renderTitle(): JSX.Element | null {
    const {title, subPage} = this.props
    if (!title) return null

    const variant = subPage ? 'h3' : 'h1'
    return (
      <View style={{marginVertical: smallGap, marginBottom: smallGap}}>
        <PFText variant={variant} textAlign="center">
          {title}
        </PFText>
      </View>
    )
  }

  renderDescription(): JSX.Element | null {
    const {description} = this.props
    if (!description) return null

    return (
      <View style={styles.description}>
        <PFText variant="p" textAlign="center">
          {description}
        </PFText>
      </View>
    )
  }

  renderChildren(): JSX.Element {
    const {children, justifyContent} = this.props
    return <View style={[styles.childrenViewStyle, justifyContent]}>{children}</View>
  }

  renderGraphic(): JSX.Element | null {
    const {image} = this.props
    return image ? <PageImage collapse={this.props.collapseImage} image={image} /> : null
  }

  renderLogo(): JSX.Element | undefined {
    const {logo} = this.props
    if (logo) {
      return <View style={styles.logoView}>{logo}</View>
    }
  }

  renderGraphicTop(): JSX.Element | null {
    const {image, graphicPosition = 'top'} = this.props
    if (!image || graphicPosition !== 'top') {
      return null
    }
    const graphic = this.renderGraphic()
    return <View style={styles.graphicTop}>{graphic}</View>
  }

  renderGraphicBottom(): JSX.Element | null {
    const {image, graphicPosition, graphicStyle} = this.props
    if (!image || graphicPosition !== 'bottom') {
      return null
    }

    const graphic = this.renderGraphic()
    return <View style={graphicStyle}>{graphic}</View>
  }

  renderLearnMore(): JSX.Element | null {
    const learnMore = this.props.learnMore
    if (!learnMore) {
      return null
    }

    const handleOnPress = learnMore.onPress

    return (
      <Box align={'center'}>
        <SvgLink
          onPress={handleOnPress}
          linkText={learnMore.text}
          linkType={'inline'}
          linkIcon={'internal'}
        />
      </Box>
    )
  }

  renderFooterDisclaimer(): JSX.Element | null {
    const {footerDisclaimer, actionBlock} = this.props
    const style = actionBlock ? styles.disclaimer : styles.disclaimerNoStackedButtons
    return footerDisclaimer ? <View style={style}>{footerDisclaimer}</View> : null
  }

  renderButtonLockup(): JSX.Element | null {
    const {actionBlock} = this.props

    if (!actionBlock) {
      return null
    }

    return <ButtonLockup {...actionBlock} />
  }

  render(): JSX.Element {
    return (
      <View style={styles.container} testID={this.props.testID}>
        <View style={{height: (isDeviceWeb() ? 2.5 : 1) * pfHeaderHeight}} />
        <SafeAreaView
          style={styles.outerContainer}
          onLayout={(): void => {
            this.setState({screenHeight: availableHeight()})
          }}
        >
          <ScrollViewBase
            style={styles.view}
            keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps}
            key={'scrollview'}
          >
            {this.renderLogo()}
            {this.renderTitle()}
            {this.renderGraphicTop()}
            {this.renderDescription()}
            {this.renderLearnMore()}

            {this.renderChildren()}
            {this.renderGraphicBottom()}
            <View style={styles.disclaimerContainer}>{this.renderFooterDisclaimer()}</View>
            {this.renderButtonLockup()}
          </ScrollViewBase>
        </SafeAreaView>
      </View>
    )
  }
}

export default GenericNonModalTemplate

/* Caveat
  On Android paddingBottom is not honored. It's just ignored.
  marginBottom works, but breaks when the full page color is changed, i.e. state selection.
*/
const styles = StyleSheet.create({
  childrenViewStyle: {
    flex: 1,
    justifyContent: isDeviceWeb() ? 'flex-start' : 'center',
  },
  container: {
    alignSelf: 'center',
    backgroundColor: genericPageBackground,
    flex: 1,
    maxWidth: 450,
    width: '100%',
  },
  description: {
    marginBottom: smallGap,
  },
  disclaimer: {
    padding: 0,
    width: '100%',
  },
  disclaimerContainer: {
    alignItems: 'center',
    width: '100%',
  },
  disclaimerNoStackedButtons: {
    marginBottom: 16,
    padding: 0,
    width: '100%',
  },
  graphicTop: {
    margin: smallGap,
  },
  logoView: {
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  outerContainer: {
    backgroundColor: genericPageBackground,
    flex: 1,
  },
  view: {
    justifyContent: 'space-between',
    paddingHorizontal: pageMarginHorizontal,
  },
})
