import React, {FC, useState} from 'react'

import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {
  isEditBankAccountButtonEnabled,
  isSetAccountAsPrimaryOptionEnabled,
  isUpdateBankDetailsOptionEnabled,
  isSetAccountAsAutoPayOptionEnabled,
  isRelinkAccountOptionEnabled,
} from 'src/products/MCU/AccountManagementV2/BankAccountTile/BankAccountTile.utils'
import {BankAccountTileView} from 'src/products/MCU/AccountManagementV2/BankAccountTile/BankAccountTileView'
import {OwnershipStatusInfoModal} from 'src/products/MCU/AccountManagementV2/Modals/OwnershipStatusInfoModal'
import {
  WhatToDoModal,
  WhatToDoModalResult,
} from 'src/products/MCU/AccountManagementV2/Modals/WhatToDoModal'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

export type BankAccountTileProps = {
  account: BankAccountInfo
  onEditBankDetails?: (accountId: string, mask: string) => void
  onRelinkAccount?: (accountId: string) => void
  onAddNewAccount?: () => void
  activeLoanId?: string
  onNavigateToSetAccountAsPrimary?: (account: BankAccountInfo) => void
  onNavigateToSetLoanAutoPayAccount?: (args: {paymentMethodId: string}) => void
}

export const BankAccountTile: FC<BankAccountTileProps> = ({
  account,
  onEditBankDetails: handleOnEditBankDetails,
  onRelinkAccount: handleOnRelinkAccount,
  onAddNewAccount: handleOnAddNewAccount,
  activeLoanId,
  onNavigateToSetAccountAsPrimary,
  onNavigateToSetLoanAutoPayAccount,
}) => {
  const [showOwnershipInfoModal, setShowOwnershipInfoModal] = useState(false)
  const [showWhatToDoOnEditPress, setShowWhatToDoOnEditPress] = useState(false)
  const [showWhatToDoOnAlertPress, setShowWhatToDoOnAlertPress] = useState(false)

  const handleOnWhatToDoCTA = (result: WhatToDoModalResult): void => {
    if (result === 'set_as_primary_account') {
      onNavigateToSetAccountAsPrimary?.(account)
    } else if (result === 'update_bank_details') {
      handleOnEditBankDetails?.(account.id, account.mask)
    } else if (result === 'relink_account') {
      handleOnRelinkAccount?.(account.id)
    } else if (result === 'nevermind') {
      setShowWhatToDoOnEditPress(false)
      setShowWhatToDoOnAlertPress(false)
    } else if (result === 'set_as_autopay_account') {
      setShowWhatToDoOnEditPress(false)
      if (account.paymentMethodId && onNavigateToSetLoanAutoPayAccount) {
        onNavigateToSetLoanAutoPayAccount({paymentMethodId: account.paymentMethodId})
      } else {
        logAddPaymentMethodErrorAndShowException(
          new Error(
            `BankAccountTile could not navigate to set loan autopay account. account.id=${account.id} account.type=${account.type}, onNavigateToSetLoanAutoPayAccount exists=${!!onNavigateToSetLoanAutoPayAccount}`,
          ),
        )
      }
    }
  }

  const handleOnEditPress = (): void => {
    setShowWhatToDoOnEditPress(true)
  }

  const handleOnAlertPress = (): void => {
    setShowWhatToDoOnAlertPress(true)
  }

  const handleOnOwnershipStatusAlertPress = (): void => {
    setShowOwnershipInfoModal(true)
  }
  return (
    <>
      <BankAccountTileView
        account={account}
        onEditPress={handleOnEditPress}
        onAlertPress={handleOnAlertPress}
        onOwnershipStatusAlertPress={handleOnOwnershipStatusAlertPress}
        isEditAccountButtonVisible={isEditBankAccountButtonEnabled({
          account,
          hasActiveLoan: !!activeLoanId,
        })}
      />
      <OwnershipStatusInfoModal
        visible={showOwnershipInfoModal}
        onOkay={(): void => setShowOwnershipInfoModal(false)}
        ownershipSummary={account?.ownershipSummary}
        onAddNewAccount={handleOnAddNewAccount}
      />
      {showWhatToDoOnEditPress ? (
        <WhatToDoModal
          visible={showWhatToDoOnEditPress}
          showSetAsPrimaryAccount={isSetAccountAsPrimaryOptionEnabled({account})}
          showUpdateBankDetails={isUpdateBankDetailsOptionEnabled({account})}
          showSetAsAutoPayAccount={isSetAccountAsAutoPayOptionEnabled({
            account,
            hasActiveLoan: !!activeLoanId,
          })}
          onCTA={handleOnWhatToDoCTA}
        />
      ) : null}
      {showWhatToDoOnAlertPress ? (
        <WhatToDoModal
          visible={showWhatToDoOnAlertPress}
          showRelinkAccount={isRelinkAccountOptionEnabled({
            account,
          })}
          showUpdateBankDetails={isUpdateBankDetailsOptionEnabled({account})}
          onCTA={handleOnWhatToDoCTA}
        />
      ) : null}
    </>
  )
}
