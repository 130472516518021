import {useEffect} from 'react'

import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {userIdSelector} from 'src/lib/user/selector'
import {getShouldEnrollInCards} from 'src/lib/card/selectors'
import {clearShouldEnrollInCards} from 'src/lib/card/actions'
import {useInitializeCards} from 'src/products/loans/Landing/useInitializeCards'
import Log from 'src/lib/loggingUtil'
import {useInitializeCardOnboardingFlow} from 'src/products/loans/Landing/useInitializeCardOnboardingFlow'

/**
 * If this user came from the cards deep link, enroll them in cards
 */
export const useEnrollInCardsIfNecessary = (): void => {
  const userId = usePfSelector(userIdSelector)
  const shouldEnrollInCards = usePfSelector(getShouldEnrollInCards)
  const dispatch = usePfDispatch()
  const {initializeCards, isLoading: isLoadingUseInitializeCards} = useInitializeCards()

  const initializeCardOnboardingFlow = useInitializeCardOnboardingFlow()
  useEffect(() => {
    const run = async (): Promise<void> => {
      if (!isLoadingUseInitializeCards && userId && shouldEnrollInCards) {
        dispatch(clearShouldEnrollInCards())
        Log.info('Adding user to card group')
        await initializeCards()
        await initializeCardOnboardingFlow()
      }
    }

    void run()
  }, [
    userId,
    shouldEnrollInCards,
    dispatch,
    initializeCards,
    initializeCardOnboardingFlow,
    isLoadingUseInitializeCards,
  ])
}
