import React, {FC, useEffect, useMemo, useState} from 'react'
import {Animated, StyleSheet} from 'react-native'

import {Slider} from '@sharcoux/slider'

import {useTranslation} from 'react-i18next'

import {
  sliderMinTrackColor,
  sliderMaxTrackColor,
  sliderThumbColor,
  sliderThumbBorderColor,
} from 'src/designSystem/semanticColors'
import {
  thumbBorderRadius,
  thumbBorderWidth,
  thumbHeight,
  thumbWidth,
  trackBorderRadius,
  trackHeight,
} from 'src/designSystem/guide'
import {largeGap} from 'src/designSystem/layout'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {useMeasurement} from 'src/lib/utils/useMeasurement'
import {NamedColors} from 'src/designSystem/colors'

type SelectAmountSliderProps = {
  nextAmount: number
  onSlidingComplete: () => void
  onValueChange: (loanAmount: number) => void
  step: number | undefined
  minimumAmount: number
  maximumAmount: number
  prequalificationAmount?: number
}

const half = 0.5

const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max)
}

const PrequalCallout: React.FC<{show: boolean; at: number; parentWidth: number}> = (props) => {
  const {show, at, parentWidth} = props

  const {t} = useTranslation('LoanSelection')

  const {handleLayout, width} = useMeasurement()

  const [fadeAnimation] = useState(new Animated.Value(0))

  useEffect(() => {
    if (show) {
      Animated.timing(fadeAnimation, {
        toValue: 1.0,
        duration: 200,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(fadeAnimation, {
        toValue: 0.0,
        duration: 200,
        useNativeDriver: true,
      }).start()
    }
  }, [fadeAnimation, show])

  const {left, caretLeft} = useMemo(() => {
    const calculatedLeft = at - width * half
    const minLeft = 0.0 - largeGap
    const maxLeft = parentWidth - width + largeGap
    const left = clamp(calculatedLeft, minLeft, maxLeft)
    let caretLeft = 0.0
    if (calculatedLeft < minLeft) {
      caretLeft = calculatedLeft - minLeft
    } else if (calculatedLeft > maxLeft) {
      caretLeft = calculatedLeft - maxLeft
    }
    return {left, caretLeft}
  }, [at, width, parentWidth])

  return (
    <Box boxStyle={styles.preQualCalloutWrapper}>
      <Animated.View
        style={{...styles.PrequalCalloutAnimatedView, opacity: fadeAnimation, left}}
        onLayout={handleLayout}
      >
        <Box elevation={2} radius="small" padding="small" background="success">
          <PFText variant="p_semibold">{t('PreQualCallout')}</PFText>
        </Box>
        <Box
          border={{color: 'success', width: 12}}
          // for some reason these border props have to go here and not in styles below
          boxStyle={{...styles.preQualCalloutBoxBorder, left: caretLeft}}
        />
      </Animated.View>
    </Box>
  )
}

const SelectAmountSlider: FC<SelectAmountSliderProps> = (props) => {
  const {
    nextAmount,
    onSlidingComplete,
    onValueChange,
    step,
    minimumAmount,
    maximumAmount,
    prequalificationAmount,
  } = props

  const {handleLayout, ...measurement} = useMeasurement()

  const prequalCalloutX = useMemo(() => {
    const actualWidth = measurement.width
    if (!maximumAmount || !minimumAmount || !prequalificationAmount) {
      return Math.floor(actualWidth * half)
    }

    return Math.floor((prequalificationAmount / maximumAmount) * actualWidth - thumbHeight * half)
  }, [maximumAmount, measurement.width, minimumAmount, prequalificationAmount])

  return (
    <Box onLayout={handleLayout}>
      <PrequalCallout
        show={!!prequalificationAmount && nextAmount > prequalificationAmount}
        at={prequalCalloutX}
        parentWidth={measurement.width}
      />
      <Slider
        value={nextAmount}
        onSlidingComplete={(): void => onSlidingComplete()}
        style={styles.slider}
        minimumTrackTintColor={sliderMinTrackColor}
        maximumTrackTintColor={sliderMaxTrackColor}
        thumbTintColor={sliderThumbColor}
        onValueChange={(value): void => onValueChange(value)}
        step={step}
        minimumValue={minimumAmount}
        maximumValue={maximumAmount}
        trackStyle={styles.trackStyle}
        thumbStyle={styles.thumbStyle}
        testID={'LoanAmountSelection_Slider'}
      />
    </Box>
  )
}

export {SelectAmountSlider}

const styles = StyleSheet.create({
  PrequalCalloutAnimatedView: {
    alignItems: 'center',
    bottom: 0,
    position: 'absolute',
  },
  preQualCalloutBoxBorder: {
    borderBottomColor: NamedColors.TRANSPARENT,
    borderBottomWidth: 0,
    borderLeftColor: NamedColors.TRANSPARENT,
    borderRightColor: NamedColors.TRANSPARENT,
  },
  preQualCalloutWrapper: {bottom: 40, left: 0, position: 'absolute'},
  slider: {
    height: thumbHeight,
    zIndex: 1, //this is to have the touch area cover the items below
  },
  thumbStyle: {
    borderColor: sliderThumbBorderColor,
    borderRadius: thumbBorderRadius,
    borderWidth: thumbBorderWidth,
    height: thumbHeight,
    width: thumbWidth,
  },
  trackStyle: {
    borderRadius: trackBorderRadius,
    height: trackHeight,
  },
})
