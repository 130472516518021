import React from 'react'
import {StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap, smallGap} from 'src/designSystem/layout'

type Props = {
  rows: {
    label?: string
    subLabel?: string
    content?: string | React.ReactElement
    key: React.Key
  }[]
}

export const SchumerBox: React.FC<Props> = (props) => {
  const {rows} = props
  return (
    <View style={styles.table}>
      {rows.map((row, index) => (
        <View
          key={row.key}
          style={[
            styles.row,
            row.label != null && styles.labelRow,
            index === rows.length - 1 && styles.rowLast,
          ]}
        >
          <View style={[styles.cell, styles.label, row.subLabel != null && styles.subLabelCell]}>
            {row.label ? <PFText variant="p_sm_semibold">{row.label}</PFText> : null}
            {row.subLabel ? (
              <PFText variant="p_sm" color={NamedColors.SILVER}>
                {row.subLabel}
              </PFText>
            ) : null}
          </View>
          <View style={[styles.cell, styles.content, row.subLabel != null && styles.subLabelCell]}>
            <PFText variant="p_sm">{row.content}</PFText>
          </View>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: NamedColors.ASH,
    borderWidth: 1.5,
    borderRadius: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelRow: {
    borderTopWidth: 1.5,
    borderTopColor: NamedColors.ASH,
  },

  rowLast: {
    borderBottomWidth: 0,
  },
  cell: {
    padding: smallGap,
  },
  subLabelCell: {
    paddingTop: 0,
  },
  content: {
    alignSelf: 'center',
    flex: 1, // required to wrap text
  },
  label: {
    width: 121, // Fixed width for label column
    borderRightWidth: 1.5,
    borderRightColor: NamedColors.ASH,
    gap: littleGap,
  },
})
