import React from 'react'
import {View, StyleSheet} from 'react-native'

import {smallGap, littleGap} from 'src/designSystem/layout'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type Props = {
  items: {value: string; key: string}[]
}

export const PFUnorderedList: React.FC<Props> = (props) => {
  const {items} = props

  return (
    <View style={[listStyles.list]}>
      {items.map((item) => (
        <PFText key={item.key} variant="p" textProps={{style: listStyles.listItem}}>
          {`\u2022  ${item.value}`}
        </PFText>
      ))}
    </View>
  )
}

const listStyles = StyleSheet.create({
  list: {
    marginLeft: smallGap,
    marginTop: littleGap,
  },
  listItem: {
    marginTop: littleGap,
  },
})
