import {ApplicationActivationWorkflowDescriptorType} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow.types'
import {ApplicationActivationWorkflowStackParams} from 'src/workflows/types'

/**
 * This descriptor maps prereqs to their corresponding screens for the activation workflow.
 */
export const ApplicationActivationWorkflowDescriptor: ApplicationActivationWorkflowDescriptorType =
  {
    ACCEPT_ARBITRATION_AGREEMENT_TX: {
      screen: 'AcceptStateAgreementsTX',
    },
    ACCEPT_ARBITRATION_AGREEMENT_FL: {
      screen: 'AcceptStateAgreementsFL',
    },
    ACCEPT_AUTOPAY_AGREEMENT_EXTENDED: {
      screen: 'AcceptAutoPayAgreementExtendedInstallmentPlan',
    },
    ACCEPT_AUTOPAY_AGREEMENT_STANDARD: {
      screen: 'AcceptAutoPayAgreement',
      shouldExcludeFromNavigationHistory: true,
    },
    ACCEPT_CREDIT_SERVICES_AGREEMENT_TX: {
      screen: 'AcceptStateAgreementsTX',
    },
    ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX: {
      screen: 'AcceptStateAgreementsTX',
    },
    ACCEPT_HOW_AUTOPAY_WORKS: {
      screen: 'HowAutoPayWorks',
    },
    ACCEPT_LOAN_AGREEMENT: {
      screen: 'AcceptLoanAgreement',
    },
    ACCEPT_PAYMENTS: {
      screen: 'PaymentReview',
    },
    ACCEPT_STATE_DISCLOSURE_LA: {
      screen: 'StateDisclosureLA',
    },
    ACCEPT_STATE_DISCLOSURE_OH: {
      screen: 'StateDisclosureOH',
    },
    ACCEPT_STATUTORY_NOTICE_FL: {
      screen: 'AcceptStateAgreementsFL',
    },
    ACCEPT_TILA_DISCLOSURE: {
      screen: 'TilaDisclosure',
    },
    COLLECT_DEBIT_CARD_NUMBERS: {
      screen: 'CollectDebitCardNumbers',
    },
    COMPLETE_LOAN_REASON_SURVEY: {
      screen: 'ReasonSurvey',
      shouldExcludeFromNavigationHistory: true,
    },
    CONFIRM_DEBIT_CARD: {
      screen: 'ConfirmDebitCard',
    },
    CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS: {
      screen: 'ConfirmPrimaryBankAccountDetails',
      shouldExcludeFromNavigationHistory: true,
    },
    RELINK_PREFERRED_BANK_ACCOUNT: {
      screen: 'RelinkPreferredBankAccount',
    },
    SELECT_DISBURSEMENT_METHOD: {
      screen: 'DisbursementMethodSelection',
    },
    FINAL_ACCEPT_STANDARD: {
      screen: 'FinalAcceptance',
    },
  }

/**
 * Set of all route names in the activation workflow nav stack.
 */
export const ApplicationActivationWorkflowRouteNames: (keyof ApplicationActivationWorkflowStackParams)[] =
  [
    // create a Set to remove duplicates
    ...new Set<keyof ApplicationActivationWorkflowStackParams>(
      Object.values(ApplicationActivationWorkflowDescriptor).map((descriptor) => descriptor.screen),
    ),
  ]
