import {useCallback, useContext} from 'react'

import {ApplicationChannel} from 'src/cassandra'
import Log from 'src/lib/loggingUtil'
import {isDeviceNotWeb} from 'src/lib/utils/platform'
import {NavContext} from 'src/nav/NavContext'

type UseGetCardsChannelReturnType = () => ApplicationChannel

export const useGetCardsChannel = (): UseGetCardsChannelReturnType => {
  const [state] = useContext(NavContext)

  return useCallback(() => {
    try {
      // state.initialParams will be an instanceof URLSearchParams in both web and mobile,
      // but the mobile version of URLSearchParams throws errors when you try to call it
      // avoid calling it!
      if (isDeviceNotWeb()) {
        return ApplicationChannel.None
      }

      const utmSource = state.initialParams?.get('utm_source')
      return utmSource ? ApplicationChannel.PaidAds : ApplicationChannel.None
    } catch {
      Log.error('Calling URLSearchParams in mobile')
    }

    return ApplicationChannel.None
  }, [isDeviceNotWeb, state.initialParams])
}
