import assert from 'assert'
import moment from 'moment-timezone'

import {Consumer} from '@possible/cassandra'
import {
  GetLoanFundingStatusQuery,
  LoanAcceptDocument,
  LoanGetPaymentsDocument,
  LoanPaymentMethod,
  NextAvailablePaymentDateDocument,
  NextAvailablePaymentDateQuery,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyMutation, ApplyQuery} from '@possible/cassandra/src/utils/operations'
import * as protomonoTypes from '@possible/generated/src/generated/protomonoTypes'
import APIClientLoan from 'src/api/lib/APIClientLoan'

import {APIV2ReturnType, APIv2Response} from '@possible/generated/APIClient'
import {LoanActionsUpdateLoanTermsDocument} from 'src/api/actions/loans/LoanActions.gqls'
import {userIdSelector} from 'src/api/selectors/selectors'
import {
  DISBURSEMENT_METHOD_SELECTED,
  LOAN_ERROR,
  LOAN_TRANSFERS_UPDATE,
  LOAN_TYPE_UPDATE,
  LOAN_UPDATE,
  LoansStateChange,
  NextAvailableSettlementDateChange,
} from 'src/lib/loans/actions'
import {statusList, transferMethodsType} from 'src/lib/loans/consts'
import {ILoanRedux} from 'src/lib/loans/reducers/types'
import {loanTypeForLoanTypeId} from 'src/lib/loans/selector'
import {
  getLoanStatus,
  get_latest_loan,
  isLoanActive,
  isPendingInstallmentLoan,
} from 'src/lib/loans/utils'
import Log from 'src/lib/loggingUtil'
import {getPfStore} from 'src/store'
import {PfDispatch} from 'src/store/types'

/**
 * Retrieves a loan's transfers and dispatches an action to update redux
 * @throws error if graphql call isn't successful
 * @returns the loan transfers
 */
export const getLoanTransfers = async (loanId: string, dispatch): Promise<void> => {
  const loanTransferResp = await ApplyQuery(LoanGetPaymentsDocument, {loanId})
  if (!loanTransferResp) {
    throw new Error(`Unable to make call for loan transers, loanId=${loanId}`)
  }

  const payments = loanTransferResp.data.loanGetPayments.payments.payments?.map((payment) => {
    return {
      ...payment,
      amount: Number(payment.amount),
      ...(payment.interest ? {interest: Number(payment.interest)} : {}),
      ...(payment.fees ? {fees: Number(payment.fees)} : {}),
      ...(payment.principal ? {principal: Number(payment.principal)} : {}),
    }
  })
  const loanTransfers = {payments, loanId}

  dispatch({type: LOAN_TRANSFERS_UPDATE, data: loanTransfers})
}

type LoanActionsGetLoanResult = APIv2Response<protomonoTypes.loans.IGetLoanResponse> & {
  loan: ILoanRedux
}
export async function getLoan(
  loanId: string,
  updateReduxStore = true,
): Promise<LoanActionsGetLoanResult> {
  const response = (await APIClientLoan.GetLoan(loanId)) as LoanActionsGetLoanResult
  getPfStore().dispatch({
    type: LOAN_ERROR,
    loanHasError: response.hasError(),
    loanErrorMessage: response.getErrorStr(),
  })

  if (response.hasError()) {
    return response
  }
  const status = getLoanStatus(response.loan)
  if (response.loan.status === null) {
    response.loan.status = undefined
  }

  if (status === statusList.APPROVED) {
    const methodResp = await getLoanPaymentMethods(loanId)
    if (!methodResp || methodResp.getErrorStr()) {
      Log.log(methodResp?.getErrorStr() ?? 'getLoanPaymentMethods undefined response')
    } else {
      response.loan.methods = methodResp.methods
    }
  }
  if (isLoanActive(status)) {
    try {
      const res = (await Consumer.methods.getLoanFundingStatus(loanId)) as GetLoanFundingStatusQuery
      if (res.getLoanFundingStatus) {
        // type expects these to not be null so set to undefined if nullish
        response.loan.fundingStatus = {
          status: res.getLoanFundingStatus.status ?? undefined,
          processorStatus: res.getLoanFundingStatus.processorStatus ?? undefined,
          displayStatus: res.getLoanFundingStatus.displayStatus ?? undefined,
          displayProcessorStatus: res.getLoanFundingStatus.displayProcessorStatus ?? undefined,
        }
      } else {
        response.loan.fundingStatus = undefined
      }
    } catch (e) {
      Log.error(e, 'getLoanFundingStatus error:')
    }
  }
  if (updateReduxStore) {
    getPfStore().dispatch({type: LOAN_UPDATE, loan: response.loan})
  }
  return response
}

export async function getLoanPaymentMethods(
  loanId?: string,
): APIV2ReturnType<protomonoTypes.loans.IGetLoanPaymentMethodsResponse> {
  if (loanId) {
    return APIClientLoan.GetLoanPaymentMethods(
      loanId,
    ) as APIV2ReturnType<protomonoTypes.loans.IGetLoanPaymentMethodsResponse>
  } else {
    throw new Error(`Unable to fetch loan payment methods, please try again in a few minutes.`)
  }
}

export async function getNextAvailableSettlementDate(
  loanId: string,
  timeNow: string,
  forDisbursement: boolean,
) {
  const state = getPfStore().getState()
  const userId = userIdSelector(state)

  try {
    const responseData: NextAvailablePaymentDateQuery = (
      await ApplyQuery(NextAvailablePaymentDateDocument, {
        loanId,
        timeNow,
        forDisbursement,
        userId,
      })
    ).data

    if (!responseData) {
      throw new Error('Failed to retrieve next available settlement date')
    }

    getPfStore().dispatch(
      NextAvailableSettlementDateChange({
        next_available_settlement_date: responseData['getNextAvailablePaymentDate'],
      }),
    )

    return responseData['getNextAvailablePaymentDate']
  } catch (e) {
    Log.error(e, 'getNextAvailableSettlementDate error:')
  }
}

export async function getNextAvailableDisbursementDate(loanId, givenTimeNow) {
  return getNextAvailableSettlementDate(loanId, givenTimeNow, true)
}

export const getLoanTermsAndUpdateStore = async (stateCode, dispatch) => {
  const response = await APIClientLoan.GetLoanTerms(stateCode)
  if (response && !response.getErrorStr()) {
    await dispatch(LoansStateChange({loan_terms: response.loanType}))
  }
  return response
}

export function LoanGetTerms(stateCode) {
  return async (dispatch) => {
    return getLoanTermsAndUpdateStore(stateCode, dispatch)
  }
}

export function LoanApplicationSubmitted() {
  return async (dispatch: PfDispatch): Promise<void> => {
    await dispatch(UpdateLastLoan())

    dispatch(LoansStateChange({reapplying: false, user_selected_loan_amount: false}))
  }
}

const setDisbursementAvailableDate = async (loan: ILoanRedux): Promise<void> => {
  if (loan?.id && getLoanStatus(loan) === statusList.ACTIVE) {
    const response = await getNextAvailableSettlementDate(
      loan.id,
      moment.utc(loan.activeAtDatetime).format(),
      true,
    )
    // This is available once disbursement is complete
    if (response?.adjustedSettlementDatetime) {
      loan.disbursementAvailableDate = moment(response.adjustedSettlementDatetime)
        .local()
        .format('dddd, MMMM Do')
    }
  }
}

const saveLoanType = async (loan: ILoanRedux, state, dispatch) => {
  //Get the loan type record if we don't already have it
  if (loan.typeId && !loanTypeForLoanTypeId(state, loan.typeId)) {
    const loanTypeResp = await APIClientLoan.GetLoanTypeById(loan.typeId)
    dispatch({type: LOAN_TYPE_UPDATE, value: loanTypeResp?.loanType})
  }
}

const saveLoanTransfers = async (loan: ILoanRedux, dispatch) => {
  if (!loan.id) {
    Log.warn('saveLoanTransfers: Missing loan id')
    return
  }

  try {
    await getLoanTransfers(loan.id, dispatch)
  } catch (e) {
    Log.error(e, 'saveLoanTransfers error:')
  }
}

// If this loan is a replacement for an earlier loan and status=pending,
// get the transfer payments from the original loan
const saveReplacementLoanTransfers = async (loan: ILoanRedux, dispatch) => {
  if (!loan.originalLoanId) {
    return
  }

  if (isPendingInstallmentLoan(loan)) {
    try {
      await getLoanTransfers(loan.originalLoanId, dispatch)
    } catch (e) {
      Log.error(e, 'saveReplacementLoanTransfers error:')
    }
  }
}

export function UpdateLastLoan() {
  return async (dispatch, getState) => {
    const state = getState()
    /* we will have to get a better solution to stop at the api call level
       but this call is used in the loan state polling so stopping it earlier
     */
    if (state.api.sessionExpired) {
      return {}
    }

    const userId = userIdSelector(state)
    const loansResp = await APIClientLoan.GetLoansByUserId(userId)

    getPfStore().dispatch({
      type: LOAN_ERROR,
      loanHasError: loansResp?.hasError(),
      loanErrorMessage: loansResp?.getErrorStr(),
    })

    if (loansResp?.hasError()) {
      return loansResp
    }

    let loan = get_latest_loan(loansResp?.loans)
    if (loan?.id) {
      const loanWithMethods = await getLoan(loan?.id, false)
      loan = loanWithMethods.loan

      if (loan) {
        await setDisbursementAvailableDate(loan)
      }
    }
    dispatch({type: LOAN_UPDATE, loan})

    if (loan) {
      await saveLoanType(loan, state, dispatch)
      await saveLoanTransfers(loan, dispatch)
      await saveReplacementLoanTransfers(loan, dispatch)
    }

    return loansResp
  }
}

export async function submitLoan(
  loanId: string,
  paymentMethod: string,
  disbursementMethod: transferMethodsType,
  accountNumber: string,
  routingNumber: string,
) {
  const getLoanPaymentMethod = (method: string) => {
    switch (method.toLowerCase()) {
      case 'interchange':
        return LoanPaymentMethod.DebitCard
      case 'ach':
        return LoanPaymentMethod.Ach
      case 'check':
        return LoanPaymentMethod.Check
      default:
        return LoanPaymentMethod.None
    }
  }

  try {
    const response = await ApplyMutation(LoanAcceptDocument, {
      acceptInput: {
        loanId: loanId,
        paymentMethod: getLoanPaymentMethod(paymentMethod),
        disbursementMethod: getLoanPaymentMethod(disbursementMethod),
        accountNumber,
        routingNumber,
      },
    })

    Log.info('submitLoan results', response)
    assert(response)
    const {loan} = await APIClientLoan.GetLoan(loanId)

    getPfStore().dispatch({type: LOAN_UPDATE, loan})

    return response['loanAccept']
  } catch (e) {
    Log.error(e, 'submitLoan error:')
    throw e
  }
}

export function UpdateDisbursementMethod(method: transferMethodsType) {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: PfDispatch): Promise<void> => {
    dispatch({type: DISBURSEMENT_METHOD_SELECTED, value: method})
  }
}

export function UpdateLoanTerms(): (dispatch: PfDispatch) => Promise<void> {
  return async (dispatch: PfDispatch): Promise<void> => {
    const queryRes = await ApplyQuery(LoanActionsUpdateLoanTermsDocument)
    const stateFromOnboardingInfo = queryRes.data.me.onboarding?.loan?.stateSelected
    const stateFromUserProfile = queryRes.data.me.profile?.home?.address?.state
    const latestLoanAggregateStatus = queryRes.data.me.loans.latestActionableLoan?.aggregateStatus
    // if user has a latestActionableLoan we get loan count from that, otherwise it's their first loan
    const loanCount =
      latestLoanAggregateStatus?.__typename === 'ClosedLoanAggregateStatus' ||
      latestLoanAggregateStatus?.__typename === 'ActiveLoanAggregateStatus'
        ? latestLoanAggregateStatus.loanCount
        : 0
    // if this is their first loan we use the state they selected during onboarding. otherwise we use the
    // state from their user profile since it will be set up once they've had a loan
    const stateForLoanTerms =
      loanCount === 0 ? (stateFromOnboardingInfo ?? stateFromUserProfile) : stateFromUserProfile

    if (stateForLoanTerms) {
      await getLoanTermsAndUpdateStore(stateForLoanTerms, dispatch)
    }
  }
}
