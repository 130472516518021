import {StackNavigationProp} from '@react-navigation/stack'
import moment from 'moment'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {CardMinPaymentsQuery} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {getTestID} from 'src/lib/utils/tests.utils'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {CardDashboardPaymentFooter} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentFooter'
import {isNSF} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {CardAccountDashboardStatus} from 'src/products/card/types'

export type MinPayTablePropsFromUseCards = Pick<
  useCardsType,
  | 'nextPaymentDueDateFiltered'
  | 'autopayEnabled'
  | 'spentAmount'
  | 'eligibleForInstallments'
  | 'ledgerSpentAfterPayments'
  | 'cardAccount'
>

type MinPayTableProps = MinPayTablePropsFromUseCards & {
  setShowDetailsOverlay: React.Dispatch<React.SetStateAction<boolean>>
  cardMinPayResponse: CardMinPaymentsQuery['me']['cardAccounts']['active']
  autopayStatus: string
  navigation: StackNavigationProp<MainStackNavigationProp>
  accountStatus: CardAccountDashboardStatus | undefined
}

const MinPayTable: React.FC<MinPayTableProps> = ({
  setShowDetailsOverlay,
  cardMinPayResponse,
  autopayStatus,
  autopayEnabled,
  nextPaymentDueDateFiltered,
  accountStatus,
  ledgerSpentAfterPayments,
  cardAccount,
  navigation,
  spentAmount,
  eligibleForInstallments,
}) => {
  const {t} = useTranslation(['UpcomingPaymentsCard', 'Common'])

  const isUserNSF = isNSF(cardMinPayResponse?.status)

  const renderRow = (label: string, value: string | number, color?: NamedColors): JSX.Element => (
    <Box
      direction="row"
      testID={getTestID(label, '-Id')}
      justify="between"
      paddingVertical="tiny"
      key={label}
    >
      <PFText variant="p">{t(label)}: </PFText>
      <PFText variant="p_semibold" color={color}>
        {value}
      </PFText>
    </Box>
  )

  // eslint-disable-next-line complexity
  const footerText = (): JSX.Element | undefined => {
    if (
      accountStatus === CardAccountDashboardStatus.Delinquent ||
      accountStatus === CardAccountDashboardStatus.DelinquentNSF
    ) {
      return (
        <>
          <Box marginTop="little" direction="row">
            <Box marginRight="little">
              <PFStatusPill
                text={`$${cardMinPayResponse?.balance?.pastDueAfterPayments}`}
                color="error"
                fontColor={NamedColors.WHITE}
              />
            </Box>
            <PFText variant="p_semibold">{t('PastDue')}</PFText>
          </Box>
          <Box marginTop="little">
            <PFText variant="p" textAlign="left">
              <Trans
                t={t}
                i18nKey="MinPayDelinquentFooter"
                values={{
                  minimumDue: cardMinPayResponse?.balance?.pastDueAfterPayments,
                  statementDueDate: moment(
                    cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
                  ).format('MMM Do'),
                }}
              />
            </PFText>
          </Box>
        </>
      )
    }

    if (isUserNSF) {
      return (
        <>
          <Box marginTop="little" direction="row">
            <Box marginRight="little">
              <PFStatusPill
                text={`$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`}
                color="error"
                fontColor={NamedColors.WHITE}
              />
            </Box>
            <PFText variant="p_semibold">{t('AutoPayFailedTitle')}</PFText>
          </Box>

          <Box marginTop="little">
            <PFText variant="p" textAlign="left">
              <Trans
                t={t}
                i18nKey="AutoPayFailed"
                values={{
                  minimumDue: cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments,
                  statementDueDate: moment(
                    cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
                  ).format('MMM Do'),
                }}
              />
            </PFText>
          </Box>
        </>
      )
    }

    if (
      accountStatus === CardAccountDashboardStatus.Deactivated ||
      accountStatus === CardAccountDashboardStatus.DeactivatedDelinquent
    ) {
      return (
        <>
          <PFText variant="p" textAlign="center">
            {accountStatus === CardAccountDashboardStatus.Deactivated
              ? t('MinPayDeactivatedFooter')
              : t('MinPayDeactivatedDelinquentFooter')}
          </PFText>

          {accountStatus === CardAccountDashboardStatus.DeactivatedDelinquent ? (
            <Box
              paddingHorizontal={'large'}
              justify={'center'}
              align={'center'}
              paddingTop={'little'}
              gap={'tiny'}
            >
              <PFText variant={'p_semibold'} textAlign={'center'}>
                {t('DeactivatedDelinquentTitle')}
              </PFText>

              <SvgLink
                onPress={(): void => navigation.navigate('CardHowDelinquencyAffectMe')}
                linkIcon={'internal'}
                linkText={t('TapForDetails')}
                linkType={'single'}
                textVariant={'p_semibold'}
              />
            </Box>
          ) : null}
        </>
      )
    }
  }
  const rows = [
    {
      id: 'autopayModel',
      label: t('AutoPay'),
      value: autopayStatus,
    },
    {
      id: 'paymentDueDate',
      label: t('PaymentDueDate'),
      value: formatDate(
        cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
        humanReadableDateDayMonth2,
      ),
    },
    {
      id: 'autoPayDueDate',
      label: t('AutoPayDate'),
      value: formatDate(nextPaymentDueDateFiltered, humanReadableDateDayMonth2),
    },
    {
      id: 'minimumDue',
      label: t('MinimumDue'),
      value: `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`,
      color:
        (accountStatus === CardAccountDashboardStatus.Delinquent || isUserNSF) &&
        autopayStatus === 'Minimum Due'
          ? NamedColors.PRODUCT_VERMILLION
          : autopayStatus === 'Minimum Due'
            ? NamedColors.PRODUCT_BLUE
            : undefined,
    },
    {
      id: 'statementBalance',
      label: t('StatementBalance'),
      value: `$${cardMinPayResponse?.balance?.statementAfterPayments}`,
      color: autopayStatus === 'Statement Balance' ? NamedColors.PRODUCT_BLUE : undefined,
    },
  ]

  const manualPayRowOrder: string[] = [
    'autopayModel',
    'paymentDueDate',
    'minimumDue',
    'statementBalance',
  ]
  const autoPayStatementBalanceRowOrder: string[] = [
    'autopayModel',
    'autoPayDueDate',
    'minimumDue',
    'statementBalance',
  ]
  const autoPayMinPayRowOrder: string[] = [
    'autopayModel',
    'autoPayDueDate',
    'statementBalance',
    'minimumDue',
  ]

  const renderTable = (): (JSX.Element | undefined)[] => {
    let order: string[] = []

    if (!autopayEnabled) {
      order = manualPayRowOrder
    } else {
      if (autopayStatus === 'Statement Balance') {
        order = autoPayStatementBalanceRowOrder
      } else {
        order = autoPayMinPayRowOrder
      }
    }

    return order.map((id) => {
      const row = rows.find((row) => row.id === id)
      if (row) {
        return renderRow(row.label, row.value, row.color)
      }
    })
  }

  return (
    <Box {...boxProps} testID="MinPay-Tile-Id" align={undefined}>
      {renderTable()}
      <SvgLink
        onPress={(): void => {
          TrackAppEvent(CardEvents.card_key_info_details_clicked, AppEvents.Category.Card)
          setShowDetailsOverlay(true)
        }}
        linkText={t('ShowDetails')}
        linkType="inline"
        linkIcon="internal"
        testID="Show-Details-Link-Id"
      />
      <Separator />
      {footerText()}
      <CardDashboardPaymentFooter
        accountStatus={accountStatus}
        ledgerSpentAfterPayments={ledgerSpentAfterPayments}
        cardAccount={cardAccount}
        navigation={navigation}
        spentAmount={spentAmount}
        eligibleForInstallments={eligibleForInstallments}
      />
    </Box>
  )
}

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  gap: 'little',
  align: 'center',
  width: '100%',
}

export {MinPayTable}
