import React, {FC, useState, useEffect, useMemo} from 'react'
import {Image, ImageResizeMode, ImageStyle} from 'react-native'
import {SvgProps, NumberProp} from 'react-native-svg'

import {Color} from 'src/designSystem/types'
import Log from 'src/lib/loggingUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'

export type SvgPropTypes = SvgProps & {
  height: NumberProp
  width: NumberProp
  fill?: Color
  resizeMode?: ImageResizeMode
}

//manualWebResizeMethod is an alternative way to scale the Image for web when resizeMode='contain' is not adequate
//resizeMode='contain' will resize the image and automatically vertically and horizontally center it
type Props = {
  id?: string
  SvgUri: React.FC<SvgProps>
  svgProps: SvgPropTypes
  manualWebResizeMethod?: 'height' | 'width'
  testID?: string
}

const PFSvg: FC<Props> = ({id, SvgUri, svgProps, manualWebResizeMethod, testID}) => {
  const {fill, resizeMode, width, height} = svgProps
  //these are only used for web
  const [imgWidth, setImgWidth] = useState(width)
  const [imgHeight, setImgHeight] = useState(height)

  useEffect(() => {
    //This function will use either width or height (as specificed as the manualWebResizeMethod) and the ratio calculated from the dimensions
    //of the Image to calculate the other dimension for scaling purposes in WEB ONLY when you don't want the scaled image centered vertically and horizontally
    const calculateDimensions = (imgRatio: number): void => {
      if (manualWebResizeMethod === 'height' && Number.isInteger(height)) {
        const calculatedWidth = imgRatio * Number(height)
        setImgWidth(calculatedWidth)
        setImgHeight(height)
      } else if (manualWebResizeMethod === 'width' && Number.isInteger(width)) {
        const calculatedHeight = Number(width) / imgRatio
        setImgHeight(calculatedHeight)
        setImgWidth(width)
      }
    }

    if (manualWebResizeMethod && isDeviceWeb()) {
      Image.getSize(
        `${SvgUri}`,
        (widthOfImage, heightOfImage) => {
          if (widthOfImage > 0 && heightOfImage > 0) {
            const imgRatio = widthOfImage / heightOfImage
            calculateDimensions(imgRatio)
          }
        },
        onError,
      )
    }
  }, [SvgUri, width, height, manualWebResizeMethod])

  const onError = (): void => {
    Log.log('Failed to get image size')
  }

  const SvgStyle = (svgHeight, svgWidth, svgFill): ImageStyle => ({
    height: svgHeight,
    width: svgWidth,
    tintColor: svgFill,
  })

  const memoizedStyle = useMemo(
    () => SvgStyle(imgHeight, imgWidth, fill),
    [imgHeight, imgWidth, fill],
  )

  /* note: tintColor only works for icons with a single outline color.
   * if we add more icons with backgrounds or two tones we will need
   * to re-evaluate this approach
   */

  return !isDeviceWeb() ? (
    <SvgUri key={id} testID={testID} {...svgProps} />
  ) : (
    <Image
      key={id}
      source={{uri: `${SvgUri}`}}
      style={memoizedStyle}
      resizeMode={resizeMode}
      testID={testID}
    />
  )
}
export default PFSvg
